import React, {createContext, useContext, useEffect, useState} from "react";
import EnglishLang from "../assets/language/en.json";
import SpanishLang from "../assets/language/es.json";
import DeLang from "../assets/language/de.json";
import ItalianLang from "../assets/language/it.json";
import FrenchLang from "../assets/language/fr.json";
import RussianLang from "../assets/language/ru.json";
import TurkeyLang from "../assets/language/tr.json";

const LanguageContext = createContext();

export function LanguageProvider({children}) {
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  const [langJson, setLangJson] = useState(EnglishLang);

  useEffect(() => {
    const appLang = localStorage.getItem("FFFLanguage");
    if (appLang) {
      switch (appLang) {
        case "es":
          setLangJson(SpanishLang);
          setSelectedLanguage("es");
          break;
        case "de":
          setLangJson(DeLang);
          setSelectedLanguage("de");
          break;
        case "it":
          setLangJson(ItalianLang);
          setSelectedLanguage("it");
          break;
        case "fr":
          setLangJson(FrenchLang);
          setSelectedLanguage("fr");
          break;
        case "ru":
          setLangJson(RussianLang);
          setSelectedLanguage("ru");
          break;
        case "tr":
          setLangJson(TurkeyLang);
          setSelectedLanguage("tr");
          break;
        default:
          break;
      }
    }
  }, []);

  const changeLanguage = newLanguage => {
    setSelectedLanguage(newLanguage);
    localStorage.setItem("FFFLanguage", newLanguage);
    switch (newLanguage) {
      case "en":
        setLangJson(EnglishLang);
        setSelectedLanguage("en");
        break;
      case "es":
        setLangJson(SpanishLang);
        setSelectedLanguage("es");
        break;
      case "de":
        setLangJson(DeLang);
        setSelectedLanguage("de");
        break;
      case "it":
        setLangJson(ItalianLang);
        setSelectedLanguage("it");
        break;
      case "fr":
        setLangJson(FrenchLang);
        setSelectedLanguage("fr");
        break;
      case "ru":
        setLangJson(RussianLang);
        setSelectedLanguage("ru");
        break;
      case "tr":
        setLangJson(TurkeyLang);
        setSelectedLanguage("tr");
        break;
      default:
        break;
    }
  };

  return <LanguageContext.Provider value={{selectedLanguage, langJson, changeLanguage}}>{children}</LanguageContext.Provider>;
}

export function useLanguage() {
  return useContext(LanguageContext);
}
