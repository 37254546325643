import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL, IMAGE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";
import ReactPlayer from "react-player";
import YouTube from "react-youtube";

const eventImg = require("./../assets/img/event1.png");
const eventImg2 = require("./../assets/img/event2-min.png");
const gallery = require("./../assets/img/location-img.png");

const VideoDetails = () => {
  const {user} = useAuth();
  const {selectedLanguage, langJson} = useLanguage();
  const {url} = useParams();

  const [eventDetails, setEventDetails] = useState();
  const [loading, setLoading] = useState();

  const opts = {
    height: "600",
    width: "100%",
    playerVars: {
      autoplay: 1,
      mute: 1,
    },
  };

  return (
    <div>
      <Header />
      <style>{`body { background: #F5F7FA; }`}</style>

      <div className="mainDiv">
        <div className="featureDiv productListMain">
          <div className="mainDiv">
            <div className="eventDiv">
              {/* <ReactPlayer url={decodeURIComponent(url)} controls playing muted width={"100%"} height={600} /> */}
              <YouTube videoId={url} opts={opts} />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default VideoDetails;
