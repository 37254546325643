import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";

const carouselImg = require("./../assets/img/shop-creation-img.svg").default;

const ShopCreation = props => {
  const {user} = useAuth();
  const navigate = useNavigate();
  const {selectedLanguage, langJson} = useLanguage();
  const [allDetails, setAllDetails] = useState();

  const fetchConfigDetails = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}frontend/configs`, {
        method: "GET",
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setAllDetails(responseData.data);
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (user?.role !== "admin") {
      fetchConfigDetails();
    }
  }, []);

  useEffect(() => {
    $(".loginLink").click(() => {
      $(".loginPopup").addClass("active");
    });
    $(".crossBtn").click(() => {
      $(".loginPopup").removeClass("active");
    });
  }, []);

  return (
    <div>
      <Header />
      <div className="shopCreationDiv">
        <div className="mainDiv">
          <div className="topDiv">
            <h2>{langJson?.millionsofpeoplearoundtheworldcantwaittovisityourshop}.</h2>
            <p>
              {langJson?.letsgetstartedto} Fair For Fair {langJson?.shopaccount}
            </p>
            <img src={carouselImg} alt="" />
            <h3>
              {langJson?.openan} Fair For Fashion {langJson?.shopandmaintainyourownshop}!
            </h3>
            <Link to="/vendor-register">{langJson?.register}</Link>
          </div>
          <div className="innrSec">
            <div className="leftInner">
              <h2>{langJson?.whats} FFF?</h2>
              <p>{allDetails?.find(item => item.name === "register-about")?.value}</p>
            </div>
            <div className="rightInner">
              <h2>{langJson?.howmuchdoesitcosttosell}?</h2>
              <p>{allDetails?.find(item => item.name === "register-cost")?.value}</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ShopCreation;
