import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL, IMAGE_URL} from "../utils/app_url";
import {useAuth} from "../context/AuthContext";
import {useLanguage} from "../context/LanguageContext";

const productImg = require("./../assets/img/product1-min.png");
const productImg2 = require("./../assets/img/product2-min.png");
const wishlistImg = require("./../assets/img/wishlist-icon2.svg").default;
const cartImg = require("./../assets/img/cart-icon.svg").default;
const icon1 = require("./../assets/img/icon1.svg").default;
const icon2 = require("./../assets/img/icon2.svg").default;
const icon3 = require("./../assets/img/icon3.svg").default;
const icon4 = require("./../assets/img/icon4.svg").default;
const rating = require("./../assets/img/rating-img.svg").default;
const plusIcon = require("./../assets/img/plus-icon.svg").default;
const minusIcon = require("./../assets/img/minus-icon.svg").default;

const ProductDetails = () => {
  const {user, setCartCount} = useAuth();
  const {selectedLanguage, langJson} = useLanguage();
  const {CatalogId} = useParams(); // Get the file name from route params

  const [activeItem, setActiveItem] = useState(0);
  const sliderRef = React.createRef();
  const thumbnailRef = React.createRef();
  const [products, setProducts] = useState([]);
  const [openModal, setOpenModal] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const [errorText, setErrorText] = useState();
  const [loading, setLoading] = useState(null);
  const [wishBtn, setWishBtn] = useState(false);
  const [wishMsg, setWishMsg] = useState(false);
  const [relatedProducts, setRelatedProducts] = useState([]);

  const thumbnails = [
    require("./../assets/img/details1.png"),
    require("./../assets/img/details1.png"),
    require("./../assets/img/details1.png"),
    require("./../assets/img/details1.png"),
    require("./../assets/img/details1.png"),
    // Add more thumbnail URLs as needed
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: thumbnailRef.current,
    afterChange: index => setActiveItem(index),
  };

  const thumbnailSettings = {
    dots: false,
    infinite: true,
    vertical: true, // Set vertical layout
    verticalSwiping: true, // Allow vertical swiping
    speed: 500,
    slidesToShow: thumbnails.length > 4 ? 4 : thumbnails.length,
    slidesToScroll: 1,
    focusOnSelect: true,
    asNavFor: sliderRef.current,
  };

  const handleThumbnailClick = index => {
    setActiveItem(index);
    sliderRef.current.slickGoTo(index);
  };

  // const QuantitySelector = ({index, size, inStock}) => {
  const [quantity, setQuantity] = useState(0);

  const handleIncrement = (i, size, inStock) => {
    setQuantity(quantity + 1);
    let newObject = {};
    let array = [...selectedItems];
    if (array[i].qty > Number(inStock) - 1) {
      setErrorText(langJson?.itemquantitystockexceeded);
    } else {
      let index = array.findIndex(item => item.size === size);
      if (index == -1) {
        setSelectedItems(current => [...current, newObject]);
      } else {
        array[index].qty = array[i].qty + 1;
        setSelectedItems(array);
      }
      setErrorText(null);
    }
  };

  const handleDecrement = (i, size, inStock) => {
    setQuantity(quantity - 1);
    let newObject = {};
    let array = [...selectedItems];
    let index = array.findIndex(item => item.size === size);
    if (index == -1) {
      setSelectedItems(current => [...current, newObject]);
    } else {
      if (array[i].qty > 0) {
        array[index].qty = array[i].qty - 1;
        setSelectedItems(array);
      }
    }
  };

  //   return (
  //     <div className="quantity-selector">
  //       <button onClick={handleDecrement}>
  //         <img src={minusIcon} alt="" />
  //       </button>
  //       <span>{quantity}</span>
  //       <button onClick={handleIncrement}>
  //         <img src={plusIcon} alt="" />
  //       </button>
  //     </div>
  //   );
  // };

  const handleAddCart = async () => {
    if (!user) {
      $(".loginLink").click(() => {
        $(".loginPopup").addClass("active");
      });
      setOpenModal(!openModal);
    } else {
      setLoading(true);
      const finalArray = selectedItems.filter(item => item.qty > 0);
      const updatedVisitorOrder = finalArray.map(item => ({
        size: item.size,
        order: item.qty,
      }));
      if (updatedVisitorOrder.length > 0) {
        try {
          const response = await fetch(`${API_BASE_URL}cart/add`, {
            method: "POST",
            body: JSON.stringify({
              visitor_order: updatedVisitorOrder,
              product_id: products[0].id,
              user_id: products[0].user_id,
              lang: selectedLanguage,
            }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.authorisation.token}`,
            },
          });
          const responseData = await response.json();
          if (responseData.res === true) {
            setCartCount(responseData.data.cart_count);
            setErrorText(responseData.msg);
            setLoading(false);
          } else {
            setErrorText(responseData.msg);
            setLoading(false);
          }
        } catch (error) {
          console.error("Error during Sign UP:", error);
          setLoading(false);
        }
      } else {
        setErrorText(langJson?.pleaseselectatleastoneitem);
        setLoading(false);
      }
    }
  };

  const fetchData = async () => {
    try {
      setSelectedItems([]);
      const response = await fetch(`${API_BASE_URL}frontend/products/${CatalogId}`, {
        method: "POST",
        body: JSON.stringify({
          lang: selectedLanguage,
          user_id: user?.id,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setProducts([data.data.product]);
      setRelatedProducts(data.data.related_products);

      data?.data?.product?.stocks?.map((item, index) => {
        setSelectedItems(current => [...current, {size: item.size, qty: 0}]);
      });
      //     let newObject = {};
      // let array = [...selectedItems];
      // newObject.size = size;
      // newObject.order = 2;
      //     let index = array.findIndex(item => item.size === size);
      // if (index == -1) {
      //   setSelectedItems(current => [...current, newObject]);
      // } else {
      //   array[index].order = 2;
      //   setSelectedItems(array);
      // }
      $(document).ready(function () {
        $(".dropDiv").each(function () {
          $("h3", this).click(() => {
            $(this).find(".subSidebar").toggle();
          });
        });
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleAddWishlist = async () => {
    if (!user) {
      $(".loginLink").click(() => {
        $(".loginPopup").addClass("active");
      });
      setOpenModal(!openModal);
    } else {
      setWishMsg(null);
      setWishBtn(true);
      try {
        const response = await fetch(`${API_BASE_URL}frontend/wishlists`, {
          method: "POST",
          body: JSON.stringify({
            product_id: products[0].id,
            lang: selectedLanguage,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.authorisation.token}`,
          },
        });
        const responseData = await response.json();
        if (responseData.res === true) {
          setWishMsg(responseData.msg);
          setWishBtn(false);
        } else {
          setWishMsg(responseData.msg);
          setWishBtn(false);
        }
      } catch (error) {
        console.error("Error during Sign UP:", error);
        setWishBtn(false);
      }
    }
  };

  useEffect(() => {
    setErrorText(null);
    fetchData();
  }, [CatalogId, user]);

  return (
    <div>
      <Header openModal={openModal} />
      <style>{`body { background: #F5F7FA; }`}</style>

      <div className="mainDiv">
        {products.length > 0 ? (
          <div className="topDetails">
            <div className="leftColumn">
              <div className="product-detail">
                <div className="mainImg">
                  <Slider {...settings} ref={sliderRef}>
                    {products[0]?.images?.map((thumb, index) => (
                      <>
                        <div className="item" key={index}>
                          <img src={IMAGE_URL + thumb?.image} alt={`Thumbnail ${index}`} />
                        </div>
                      </>
                    ))}
                  </Slider>
                </div>
                <div className="thumbImg">
                  <Slider {...thumbnailSettings} ref={thumbnailRef}>
                    {products[0]?.images?.map((thumb, index) => (
                      <div key={index} className={`thumbnail ${activeItem === index ? "active" : ""}`} onClick={() => handleThumbnailClick(index)}>
                        <img src={IMAGE_URL + thumb?.image} alt={`Thumbnail ${index}`} />
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>

            <div className="rightColumn">
              <h2>
                <Link to={`/store/${products[0].store_id}`}>
                  <h2>{products[0].title}</h2>
                </Link>
              </h2>
              <h3>{products[0].pro_cat_info?.title}</h3>
              {/* <img src={rating} alt="" /> */}
              {parseInt(products[0]?.is_public) === 1 ? (
                <>
                  <h4>
                    {products[0].currency}
                    {products[0].sale_price}{" "}
                    {products[0].price ? (
                      <span>
                        {products[0].currency}
                        {products[0].price}
                      </span>
                    ) : null}
                  </h4>
                  <p>
                    {langJson?.wsprice} {products[0].currency}
                    {products[0].unit_price} {langJson?.perunit}
                  </p>
                </>
              ) : null}

              <div className="detailsCart">
                <ul className="cartHdn">
                  <li>{langJson?.size}</li>
                  <li>{langJson?.instock}</li>
                  <li>{langJson?.quantity}</li>
                </ul>

                {products[0].stocks
                  ? products[0].stocks.map((item, index) => (
                      <ul key={index} className="cartDetails">
                        <li>{item?.size}</li>
                        <li>{item?.stock}</li>
                        <li>
                          <div className="quantity-selector">
                            <button onClick={() => handleDecrement(index, item?.size, item?.stock)} disabled={item?.stock == 0 ? true : false}>
                              <img src={minusIcon} alt="" />
                            </button>
                            {/* <span>{quantity}</span> */}
                            <span>{selectedItems[index]?.qty}</span>
                            <button onClick={() => handleIncrement(index, item?.size, item?.stock)} disabled={item?.stock == 0 ? true : false}>
                              <img src={plusIcon} alt="" />
                            </button>
                          </div>
                          {/* <QuantitySelector index={index} size={item?.split("-")[0]} inStock={item?.split("-")[1]} /> */}
                        </li>
                      </ul>
                    ))
                  : null}
              </div>
              {errorText ? <span className="lgnStatus">{errorText}</span> : null}

              <div className="detailBtns">
                <button className="wishBtn" onClick={() => handleAddWishlist()} disabled={wishBtn}>
                  {langJson?.addtowishlist}
                </button>
                {/* <Link to="/cart"> */}
                {parseInt(products[0]?.is_public) === 1 ? (
                  <button className="cartBtn" disabled={loading ? true : false} onClick={() => handleAddCart()}>
                    {loading ? langJson?.pleasewait : langJson?.addtocart}
                  </button>
                ) : null}
                {/* </Link> */}
              </div>

              {wishMsg ? <span className="lgnStatus">{wishMsg}</span> : null}
              <div className="additionalDesc">
                <ul>
                  <li className="dropDiv">
                    <h3>{langJson?.description}</h3>
                    <ul className="subSidebar">
                      <li>
                        <p>{products[0].description}</p>
                      </li>
                    </ul>
                  </li>
                  {/* <li className="dropDiv">
                    <h3>{langJson?.additionalinformation}</h3>
                    <ul className="subSidebar">
                      <li>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi interdum diam eu erat commodo elementum. Fusce at eros vel lectus
                          malesuada facilisis.
                        </p>
                      </li>
                    </ul>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        ) : (
          // Render a loading state while waiting for data
          <div>
            {/* <div className="loader-container">
              <div className="spinner"></div>
            </div> */}
          </div>
        )}

        {relatedProducts?.length > 0 ? (
          <div className="featureDiv relatedDiv">
            <h2>{langJson?.relatedproducts}</h2>

            <div className="productList">
              <ul>
                {relatedProducts?.map((item, index) => (
                  <li key={index}>
                    <div className="imgHolder">
                      <Link to={`/product/${item.slug}`}>
                        <img src={IMAGE_URL + item?.image} alt="" className="prdtImg" />
                        {/* <div className="discountItem">-18%</div> */}
                      </Link>
                      <div className="wishlistItem">
                        <img src={wishlistImg} alt="" onClick={() => handleAddWishlist(item?.id)} />
                      </div>
                    </div>
                    <Link to={`/product/${item.slug}`}>
                      <div className="prdtCaption">
                        <p>{item?.color && <span style={{background: item?.color}}></span>}</p>
                        <h2>{item?.title}</h2>
                        {parseInt(item?.is_public) === 1 ? (
                          <h3>
                            {item.currency}
                            {item.price} <img src={cartImg} alt="" />
                          </h3>
                        ) : (
                          <h3>
                            <img src={cartImg} alt="" />
                          </h3>
                        )}
                        {/* <h3>
                          {item?.currency}
                          {item?.price} <img src={cartImg} alt="" />
                        </h3> */}
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ) : null}

        <div className="provideDiv">
          <ul>
            <li>
              <img src={icon1} alt="" />
              <h3>{langJson?.easyreturn}</h3>
            </li>
            <li>
              <img src={icon2} alt="" />
              <h3>{langJson?.fastshipping}</h3>
            </li>
            <li>
              <img src={icon3} alt="" />
              <h3>{langJson?.securepayments}</h3>
            </li>
            <li>
              <img src={icon4} alt="" />
              <h3>{langJson?.friendlysupport}</h3>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetails;
