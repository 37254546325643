import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";

const icon1 = require("./../assets/img/tick.svg").default;
const icon2 = require("./../assets/img/cross.svg").default;

const PlansPricing = () => {
  const {user} = useAuth();
  const {selectedLanguage, langJson} = useLanguage();
  const navigate = useNavigate();

  const [planList, setPlanList] = useState();
  const [activePlan, setActivePlan] = useState();

  const fetchPlanList = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}frontend/plans`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setPlanList(responseData.data);
      } else {
      }
    } catch (error) {}
  };

  const fetchPlanDetails = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}stores/get-plan`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setActivePlan(responseData.data);
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchPlanList();
    fetchPlanDetails();
  }, []);

  // useEffect(() => {
  //   // Add event listener to handle clicks outside the actionDiv
  //   const handleClickOutside = event => {
  //     console.log(actionDivRef.current);
  //     if (setSelectedId && !actionDivRef.current) {
  //       // Clicked outside the actionDiv, so reset selectedId
  //       setSelectedId(null);
  //       console.log("first");
  //     }
  //   };

  //   // Attach the event listener
  //   document.addEventListener("click", handleClickOutside);

  //   // Clean up the event listener on component unmount
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, [actionDivRef]);

  return (
    <div>
      <div className="dashHeader">
        <Header />
      </div>
      <div className="adminDash">
        <div className="planMain">
          <div className="plansDiv">
            <h2>{langJson?.plansandpricing}</h2>
            <p>{langJson?.chooseaplan}</p>
          </div>

          <div className="chooseDiv">
            {planList?.map((item, index) => (
              <div className={activePlan?.id === item?.id ? "planList active" : "planList"} key={index}>
                <h2>{item?.name}</h2>
                <h3>
                  <sup>EUR </sup>
                  {item?.price}
                </h3>
                <button onClick={() => navigate(`/plan-subscription/${item?.id}`)}>{langJson?.getsubscription}</button>
                <span dangerouslySetInnerHTML={{__html: item?.description}}></span>
                {/* <ul>
                  <li>
                    <img src={icon1} alt="" />
                    Own fair stand
                  </li>
                  <li>
                    <img src={icon1} alt="" />
                    Fair stand Live Chat
                  </li>
                  <li>
                    <img src={icon1} alt="" />
                    Fair stand Live Video Chat
                  </li>
                  <li>
                    <img src={icon1} alt="" />
                    Limited fair stand catalog
                  </li>
                  <li>
                    <img src={icon1} alt="" />
                    Limited Slideshow
                  </li>
                  <li>
                    <img src={icon1} alt="" />
                    Limited Video Upload
                  </li>
                  <li className="crossPlan">
                    <img src={icon2} alt="" />
                    *Mobile App
                  </li>
                  <li className="crossPlan">
                    <img src={icon2} alt="" />
                    *B2B Shop
                  </li>{" "}
                </ul> */}
              </div>
            ))}

            {/* <div className="planList active">
              <h2>{langJson?.normal}</h2>
              <h3>
                <sup>EUR </sup>9.99
              </h3>
              <button>{langJson?.getsubscription}</button>
              <ul>
                <li>
                  <img src={icon1} alt="" />
                  Own fair stand
                </li>
                <li>
                  <img src={icon1} alt="" />
                  Fair stand Live Chat
                </li>
                <li>
                  <img src={icon1} alt="" />
                  Fair stand Live Video Chat
                </li>
                <li>
                  <img src={icon1} alt="" />
                  Limited fair stand catalog
                </li>
                <li>
                  <img src={icon1} alt="" />
                  Limited Slideshow
                </li>
                <li>
                  <img src={icon1} alt="" />
                  Limited Video Upload
                </li>
                <li className="crossPlan">
                  <img src={icon2} alt="" />
                  *Mobile App
                </li>
                <li className="crossPlan">
                  <img src={icon2} alt="" />
                  *B2B Shop
                </li>
              </ul>
            </div>

            <div className="planList">
              <h2>{langJson?.professional}</h2>
              <h3>
                <sup>EUR </sup>19.99
              </h3>
              <button>{langJson?.getsubscription}</button>
              <ul>
                <li>
                  <img src={icon1} alt="" />
                  Own fair stand
                </li>
                <li>
                  <img src={icon1} alt="" />
                  Fair stand Live Chat
                </li>
                <li>
                  <img src={icon1} alt="" />
                  Fair stand Live Video Chat
                </li>
                <li>
                  <img src={icon1} alt="" />
                  Limited fair stand catalog
                </li>
                <li>
                  <img src={icon1} alt="" />
                  Limited Slideshow
                </li>
                <li>
                  <img src={icon1} alt="" />
                  Limited Video Upload
                </li>
                <li className="crossPlan">
                  <img src={icon2} alt="" />
                  *Mobile App
                </li>
                <li className="crossPlan">
                  <img src={icon2} alt="" />
                  *B2B Shop
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
      <div className="dashFooter">
        <h3>
          {langJson?.copyright} © Fair for Fair {new Date().getFullYear()}
        </h3>
      </div>
      <div className="footInner">
        <Footer />
      </div>
    </div>
  );
};

export default PlansPricing;
