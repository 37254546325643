import React, {useEffect, useState} from "react";
import {Link, useNavigate, NavLink, useLocation, useParams} from "react-router-dom";
import $ from "jquery";
import {API_BASE_URL} from "../utils/app_url";
import {useAuth} from "../context/AuthContext";
import {useLanguage} from "../context/LanguageContext";
import {NotificationManager} from "react-notifications";

const cross = require("./../assets/img/cross-icon.svg").default;
const passwordIcon = require("./../assets/img/password-icon.svg").default;

const ResetPassword = () => {
  const {user, setUser, cartCount, setCartCount, searchTerm, setSearchTerm} = useAuth();
  const {selectedLanguage, changeLanguage, langJson} = useLanguage();
  const navigate = useNavigate();
  const {token} = useParams();
  const location = useLocation();
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loginStatus, setLoginStatus] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = event => {
    const {name, value} = event.target;

    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(prev => !prev);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(prev => !prev);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (!formData.newPassword) {
      setLoginStatus(langJson?.newpasswordisrequired);
    } else if (!/(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(formData.newPassword)) {
      setLoginStatus(`${langJson?.yourpasswordisnotvalid} (Ex- Abc@1234)`);
    } else if (!formData.confirmPassword) {
      setLoginStatus(langJson?.confirmpasswordisrequired);
    } else if (formData.newPassword !== formData.confirmPassword) {
      setLoginStatus(langJson?.passwordconfirmpasswordshouldbesame);
    } else {
      setLoginStatus(null);
      setLoading(true);
      const fd = new FormData();
      fd.append("token", token);
      fd.append("password", formData.newPassword);
      try {
        const response = await fetch(`${API_BASE_URL}users/reset-password`, {
          method: "POST",
          body: fd,
        });
        const responseData = await response.json();
        if (responseData.res === true) {
          setLoginStatus(responseData.msg);
          setLoading(false);
          setFormData({
            newPassword: "",
            confirmPassword: "",
          });
        } else {
          setLoginStatus(responseData.msg);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error during Sign UP:", error);
        setLoading(false);
      }
    }
  };

  return (
    <div className="loginPopup active">
      <div className="loginInner">
        <h2>{langJson?.resetpassword}</h2>

        <form onSubmit={handleSubmit}>
          <ul>
            <li>
              <input
                type={showNewPassword ? "text" : "password"}
                name="newPassword"
                value={formData.newPassword}
                placeholder={langJson?.newpassword}
                onChange={handleChange}
              />
              <div className="visibleClick" onClick={toggleNewPasswordVisibility}>
                <img src={passwordIcon} alt="Toggle Password Visibility" />
              </div>
            </li>
            <li>
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                value={formData.confirmPassword}
                placeholder={langJson?.confirmpassword}
                onChange={handleChange}
              />
              <div className="visibleClick" onClick={toggleConfirmPasswordVisibility}>
                <img src={passwordIcon} alt="Toggle Password Visibility" />
              </div>
            </li>

            <li>
              <button type="submit" disabled={loading}>
                {/* {loading ? langJson?.pleasewait : langJson?.signin} */}
                {langJson?.continue}
              </button>
              <span className="lgnStatus">{loginStatus}</span>
            </li>
          </ul>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
