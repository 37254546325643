import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL, IMAGE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";

const productImg = require("./../assets/img/product1-min.png");
const productImg2 = require("./../assets/img/product2-min.png");
const wishlistImg = require("./../assets/img/wishlist-icon2.svg").default;
const cartImg = require("./../assets/img/cart-icon.svg").default;
const filterImg = require("./../assets/img/filter-icon.svg").default;
const icon1 = require("./../assets/img/icon1.svg").default;
const icon2 = require("./../assets/img/icon2.svg").default;
const icon3 = require("./../assets/img/icon3.svg").default;
const icon4 = require("./../assets/img/icon4.svg").default;
const prevIcon = require("./../assets/img/chev-left.svg").default;
const nextIcon = require("./../assets/img/chev-right.svg").default;
const priceImg = require("./../assets/img/priceImg.svg").default;
const crossImg = require("./../assets/img/cross-icon.svg").default;

const Products = () => {
  const {user} = useAuth();
  const {selectedLanguage, langJson} = useLanguage();
  // const {CategoryName, SubcategoryName} = useParams();
  const {slugName} = useParams();

  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState("default"); // default category
  const [sortKey, setSortKey] = useState("default"); // default sort key
  const [allDetails, setAllDetails] = useState();
  const [wishBtn, setWishBtn] = useState(false);
  const [wishMsg, setWishMsg] = useState(false);
  const [openModal, setOpenModal] = useState();

  const handleSort = selectedSortKey => {
    setSortKey(selectedSortKey);
  };

  const fetchData = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}frontend/products/`, {
        method: "POST",
        body: JSON.stringify({
          category: slugName,
          sort_key: sortKey,
          lang: selectedLanguage,
          user_id: user?.id,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setProducts(data.data.products);
      setAllDetails(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleAddWishlist = async productId => {
    if (!user) {
      $(".loginLink").click(() => {
        $(".loginPopup").addClass("active");
      });
      setOpenModal(!openModal);
    } else {
      setWishMsg(null);
      setWishBtn(true);
      try {
        const response = await fetch(`${API_BASE_URL}frontend/wishlists`, {
          method: "POST",
          body: JSON.stringify({
            product_id: productId,
            lang: selectedLanguage,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.authorisation?.token}`,
          },
        });
        const responseData = await response.json();
        if (responseData.res === true) {
          setWishMsg(responseData.msg);
          setWishBtn(false);
        } else {
          setWishMsg(responseData.msg);
          setWishBtn(false);
        }
        setTimeout(() => {
          setWishMsg(null);
        }, 5000);
      } catch (error) {
        console.error("Error during Sign UP:", error);
        setWishBtn(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
    $(".dropDiv").each(function () {
      $("h3", this).click(() => {
        $(".subSidebar").hide();
        $(this).find(".subSidebar").toggle();
      });
    });

    $(".filterBtn").click(() => {
      $(".sideBar").show();
    });

    $(".crossBtn").click(() => {
      $(".sideBar").hide();
    });
  }, [category, sortKey, slugName]);

  return (
    <div>
      <Header openModal={openModal} />
      <style>{`body { background: #F5F7FA; }`}</style>

      <div className="mainDiv">
        <div className="featureDiv productListMain">
          <div className="mainDiv">
            <div className="hdnSec">
              <h2>{allDetails?.category}</h2>

              <ul className="sortDiv">
                <li>{langJson?.sort}:</li>
                <li className={sortKey === "relevance" ? "active" : ""} onClick={() => handleSort("relevance")}>
                  {langJson?.relevance}
                </li>
                <li className={sortKey === "popularity" ? "active" : ""} onClick={() => handleSort("popularity")}>
                  {langJson?.popularity}
                </li>
                <li className={sortKey === "low_to_high" ? "active" : ""} onClick={() => handleSort("low_to_high")}>
                  {langJson?.pricelowtohigh}
                </li>
                <li className={sortKey === "high_to_low" ? "active" : ""} onClick={() => handleSort("high_to_low")}>
                  {langJson?.pricehightolow}
                </li>
                <li className={sortKey === "newest_first" ? "active" : ""} onClick={() => handleSort("newest_first")}>
                  {langJson?.newestfirst}
                </li>
                {/* <li>
                  <img src={filterImg} alt="" className="filterBtn" />
                </li> */}
              </ul>
            </div>
            {wishMsg ? <span className="lgnStatus">{wishMsg}</span> : null}
            <div className="productList">
              <ul>
                {products &&
                  products.map((product, index) => (
                    <li key={index}>
                      <div className="imgHolder">
                        <Link
                          to={{
                            pathname: `/product/${product.slug}`,
                          }}>
                          <img src={IMAGE_URL + product.image} alt={index} className="prdtImg" />
                        </Link>
                        {/* <div className="discountItem"></div> */}
                        <div className="wishlistItem">
                          <img src={wishlistImg} alt="" onClick={() => handleAddWishlist(product?.id)} />
                        </div>
                      </div>
                      <Link
                        to={{
                          pathname: `/product/${product.slug}`,
                        }}>
                        <div className="prdtCaption">
                          <p>{product.color && <span style={{background: product.color}}></span>}</p>
                          <h2>{product.title}</h2>
                          {parseInt(product?.is_public) === 1 ? (
                            <h3>
                              <span>
                                {product.currency}
                                {product.price}
                              </span>
                              {product.currency}
                              {product.sale_price} <img src={cartImg} alt="" />
                            </h3>
                          ) : (
                            <h3>
                              <img src={cartImg} alt="" />
                            </h3>
                          )}
                          {/* <h3>
                            {product.currency}
                            {product.price} <img src={cartImg} alt="" />
                          </h3> */}
                        </div>
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>

        {/* <div className="paginationDiv">
          <ul>
            <li>
              <Link to="">
                <img src={prevIcon} alt="" />
              </Link>
            </li>
            <li>
              <Link to="">1</Link>
            </li>
            <li>
              <Link to="">2</Link>
            </li>
            <li>
              <Link to="">3</Link>
            </li>
            <li>
              <Link to="">4</Link>
            </li>
            <li>
              <Link to="">5</Link>
            </li>
            <li>
              <Link to="">
                <img src={nextIcon} alt="" />
              </Link>
            </li>
          </ul>
        </div> */}

        <div className="provideDiv">
          <ul>
            <li>
              <img src={icon1} alt="" />
              <h3>{langJson?.easyreturn}</h3>
            </li>
            <li>
              <img src={icon2} alt="" />
              <h3>{langJson?.fastshipping}</h3>
            </li>
            <li>
              <img src={icon3} alt="" />
              <h3>{langJson?.securepayments}</h3>
            </li>
            <li>
              <img src={icon4} alt="" />
              <h3>{langJson?.friendlysupport}</h3>
            </li>
          </ul>
        </div>
      </div>

      <div className="sideBar">
        <div className="sideBarInner">
          <h2>{langJson?.filter}</h2>
          <img src={crossImg} alt="" className="crossBtn" />
          <ul>
            <li className="dropDiv">
              <h3>{langJson?.size}</h3>
              <ul className="subSidebar">
                <li>
                  <span>
                    <input type="radio" /> 28
                  </span>
                  <span>
                    <input type="radio" /> 30
                  </span>
                  <span>
                    <input type="radio" /> 32
                  </span>
                  <span>
                    <input type="radio" /> 34
                  </span>
                </li>
              </ul>
            </li>

            <li className="dropDiv">
              <h3>{langJson?.gender}</h3>
              <ul className="subSidebar">
                <li>
                  <span>
                    <input type="radio" /> {langJson?.male}
                  </span>
                  <span>
                    <input type="radio" /> {langJson?.female}
                  </span>
                </li>
              </ul>
            </li>

            <li className="dropDiv">
              <h3>{langJson?.materialfabric}</h3>
              <ul className="subSidebar">
                <li>
                  <span>
                    <input type="radio" /> {langJson?.cotton}
                  </span>
                  <span>
                    <input type="radio" /> {langJson?.polyster}
                  </span>
                </li>
              </ul>
            </li>

            <li className="dropDiv">
              <h3>{langJson?.discount}</h3>
              <ul className="subSidebar">
                <li>
                  <span>
                    <input type="radio" /> 20%
                  </span>
                  <span>
                    <input type="radio" /> 30%
                  </span>
                </li>
              </ul>
            </li>

            <li className="dropDiv">
              <h3>{langJson?.color}</h3>
              <ul className="subSidebar">
                <li>
                  <span>
                    <div className="Prdtcolor" style={{background: "#92582C"}}></div>
                    <div className="Prdtcolor" style={{background: "#C22B2B"}}></div>
                  </span>
                </li>
              </ul>
            </li>

            <li className="dropDiv">
              <h3>{langJson?.price}</h3>
              <ul className="subSidebar">
                <li>
                  <img src={priceImg} alt="" />
                </li>
              </ul>
            </li>

            <li className="btnsDiv">
              <button className="deleteBtn">{langJson?.delete}</button>
              <button className="applyBtn">{langJson?.applynow}</button>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Products;
