import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";
import Sidebar from "../component/Sidebar";

const icon1 = require("./../assets/img/dashboard-icon.svg").default;
const icon2 = require("./../assets/img/info-icon.svg").default;
const icon3 = require("./../assets/img/app-icon.svg").default;
const icon4 = require("./../assets/img/payment-icon.svg").default;
const icon5 = require("./../assets/img/store-icon.svg").default;
const icon6 = require("./../assets/img/list-icon.svg").default;
const icon7 = require("./../assets/img/order-icon.svg").default;
const icon8 = require("./../assets/img/plan-icon.svg").default;
const icon9 = require("./../assets/img/logout-icon.svg").default;
const deleteIcon = require("./../assets/img/delete-icon.svg").default;

const CompanyInfo = () => {
  const navigate = useNavigate();
  const {user, setUser} = useAuth();
  const {langJson} = useLanguage();

  const [formData, setFormData] = useState({
    email: "",
    company: "",
    firstName: "",
    salutation: "",
    phone: "",
    address: "",
    country: "",
    city: "",
    state: "",
    federalState: "",
    zipCode: "",
    taxID: "",
    paymentTerms: "",
    shippingTerms: "",
    tax: "",
    appUser: "",
    appPassword: "",
    client_id: "",
    secret_key: "",
  });
  const [signupStatus, setSignupStatus] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = event => {
    const {name, value} = event.target;

    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchUserDetails = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}users/details`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setFormData({
          email: responseData.data.email,
          company: responseData.data.company_name,
          firstName: responseData.data.name,
          salutation: responseData.data.salutation,
          phone: responseData.data.phone,
          address: responseData.data.address,
          country: responseData.data.country,
          state: responseData.data.state,
          city: responseData.data.city,
          zipCode: responseData.data.zip_code,
          taxID: responseData.data.tax_id,
          paymentTerms: responseData.data.payment_terms,
          shippingTerms: responseData.data.shipping_terms,
          tax: responseData.data.tax,
          appUser: responseData.data.app_user,
          appPassword: responseData.data.app_password,
          client_id: responseData.data.client_id,
          secret_key: responseData.data.secret_key,
        });
      } else {
      }
    } catch (error) {}
  };

  const handleSubmit = async () => {
    if (!formData.company) {
      setSignupStatus(langJson?.companyisrequired);
      // } else if (!formData.salutation) {
      //   setSignupStatus(langJson?.salutationisrequired);
    } else if (!formData.firstName) {
      setSignupStatus(langJson?.pleaseenterfirstname);
    } else if (!formData.address) {
      setSignupStatus(langJson?.addressisrequired);
    } else if (!formData.zipCode) {
      setSignupStatus(langJson?.zipcodeisrequired);
    } else if (!formData.city) {
      setSignupStatus(langJson?.cityisrequired);
    } else if (!formData.state) {
      setSignupStatus(langJson?.stateisrequired);
    } else if (!formData.country) {
      setSignupStatus(langJson?.countryisrequired);
    } else if (!formData.phone) {
      setSignupStatus(langJson?.phoneisrequired);
    } else if (!formData.email) {
      setSignupStatus(langJson?.emailisrequired);
    } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(formData.email)) {
      setSignupStatus(langJson?.emailisnotvalid);
      // } else if (!formData.paymentTerms) {
      //   setSignupStatus(langJson?.paymenttermsisrequired);
      // } else if (!formData.shippingTerms) {
      //   setSignupStatus(langJson?.shippingtermsisrequired);
      // } else if (!formData.taxID) {
      //   setSignupStatus(langJson?.taxidisrequired);
      // } else if (!formData.tax) {
      //   setSignupStatus(langJson?.taxisrequired);
    } else {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_URL}users/details`, {
          method: "PUT",
          body: JSON.stringify({
            email: formData.email,
            name: formData.firstName,
            company_name: formData.company,
            phone: formData.phone,
            address: formData.address,
            country: formData.country,
            state: formData.state,
            city: formData.city,
            zip_code: formData.zipCode,
            tax_id: formData.taxID,
            app_user: formData.appUser,
            app_password: formData.appPassword,
            client_id: formData.client_id,
            secret_key: formData.secret_key,
            // salutation: formData.salutation,
            // payment_terms: formData.paymentTerms,
            // shipping_terms: formData.shippingTerms,
            // tax: formData.tax,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.authorisation.token}`,
          },
        });
        const responseData = await response.json();
        if (responseData.res === true) {
          setSignupStatus(responseData.msg);
          setLoading(false);
        } else {
          // Authentication failed, display an error message
          setSignupStatus(responseData.msg);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error during Sign UP:", error);
        setLoading(false);
      }
    }
  };

  const onLogout = () => {
    localStorage.removeItem("FFFUserData");
    navigate("/");
    setUser(null);
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  return (
    <div>
      <div className="dashHeader">
        <Header />
      </div>
      <div className="adminDash">
        <Sidebar />

        <div className="rightSide">
          <h2>{langJson?.companyinformation}</h2>
          <ul className="addAddress">
            <li>
              <label>{langJson?.companyname}:</label>
              <input type="text" name="company" value={formData.company} onChange={handleChange} />
            </li>
            {/* <li>
              <label>{langJson?.salutation}:</label>
              <input type="text" name="salutation" value={formData.salutation} onChange={handleChange} />
            </li> */}
            <li>
              <label>{langJson?.fullname}:</label>
              <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} />
            </li>
            <li>
              <label>{langJson?.address}:</label>
              <input type="text" name="address" value={formData.address} onChange={handleChange} />
            </li>
            <li>
              <label>{langJson?.zipcode}:</label>
              <input type="text" name="zipCode" value={formData.zipCode} onChange={handleChange} />
            </li>
            <li>
              <label>{langJson?.city}:</label>
              <input type="text" name="city" value={formData.city} onChange={handleChange} />
            </li>
            <li>
              <label>{langJson?.state}:</label>
              <input type="text" name="state" value={formData.state} onChange={handleChange} />
            </li>
            <li>
              <label>{langJson?.country}:</label>
              <input type="text" name="country" value={formData.country} onChange={handleChange} />
            </li>
            <li>
              <label>{langJson?.phonefaxmobilenumber}:</label>
              <input type="text" name="phone" value={formData.phone} onChange={handleChange} />
            </li>
            <li>
              <label>{langJson?.emailaddress}:</label>
              <input type="text" name="email" value={formData.email} readOnly onChange={handleChange} />
            </li>
            {/* <li className="fullWidth">
              <label>{langJson?.paymentterms}:</label>
              <textarea name="paymentTerms" value={formData.paymentTerms} onChange={handleChange} />
            </li>
            <li className="fullWidth">
              <label>{langJson?.shippingterms}:</label>
              <textarea name="shippingTerms" value={formData.shippingTerms} onChange={handleChange} />
            </li> */}

            <li className="sizeInput">
              <label>{langJson?.taxid}:</label>
              <input type="text" name="taxID" value={formData.taxID} onChange={handleChange} />
            </li>
            {/* <li>
              <label>{langJson?.tax}(%):</label>
              <input type="text" name="tax" value={formData.tax} onChange={handleChange} />
            </li> */}
            <li className="sizeInput">
              <label>{langJson?.appuser}:</label>
              <input type="text" name="appUser" value={formData.appUser} onChange={handleChange} />
            </li>
            <li className="sizeInput">
              <label>{langJson?.apppassword}:</label>
              <input type="text" name="appPassword" value={formData.appPassword} onChange={handleChange} />
            </li>
            <li className="sizeInput">
              <label>{langJson?.paypal} {langJson?.clientid}:</label>
              <input type="text" name="client_id" value={formData.client_id} onChange={handleChange} />
            </li>
            <li className="sizeInput">
              <label>{langJson?.paypal} {langJson?.secretkey}:</label>
              <input type="text" name="secret_key" value={formData.secret_key} onChange={handleChange} />
            </li>
            <li className="fullWidth saveBtn">
              <button type="submit" disabled={loading} onClick={() => handleSubmit()}>
                {loading ? langJson?.pleasewait : langJson?.savechanges}
              </button>
            </li>
            <span className="lgnStatus">{signupStatus}</span>
          </ul>
        </div>
      </div>
      <div className="dashFooter">
        <h3>
          {langJson?.copyright} © Fair for Fair {new Date().getFullYear()}
        </h3>
      </div>
      <div className="footInner">
        <Footer />
      </div>
    </div>
  );
};

export default CompanyInfo;
