import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import $ from "jquery";
import {API_BASE_URL, IMAGE_URL} from "../../utils/app_url";
import {useLanguage} from "../../context/LanguageContext";
import {useAuth} from "../../context/AuthContext";
import {NotificationManager} from "react-notifications";

const FairTradePrivacyPolicy = () => {
  const {user} = useAuth();
  const {selectedLanguage, langJson} = useLanguage();
  const {storeId} = useParams();

  const [allDetails, setAllDetails] = useState();
  const [openModal, setOpenModal] = useState();
  const [privacyData,setPrivacyData] = useState();

  const fetchStoreDetails = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}frontend/store/${storeId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.authorisation?.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setAllDetails(responseData.data);
      } else {
        NotificationManager.warning(responseData?.msg);
      }
    } catch (error) {}
  };

  const fetchDetails = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}frontend/store/privacy-policy`, {
        method: "POST",
        body: JSON.stringify({
          store_id: storeId,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.authorisation?.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setPrivacyData(responseData.data);
      } else {
        NotificationManager.warning(responseData?.msg);
      }
    } catch (error) {}
  };

  const handleAddStore = async () => {
    if (!user) {
      $(".loginLink").click(() => {
        $(".loginPopup").addClass("active");
      });
      setOpenModal(!openModal);
    } else {
      try {
        const response = await fetch(`${API_BASE_URL}frontend/liked-stores`, {
          method: "POST",
          body: JSON.stringify({
            store_id: storeId,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.authorisation.token}`,
          },
        });
        const responseData = await response.json();
        if (responseData.res === true) {
          // setResponseStatus(responseData?.msg);
          NotificationManager.success(responseData?.msg);
        } else {
          // setResponseStatus(responseData?.msg);
          NotificationManager.warning(responseData?.msg);
        }
      } catch (error) {}
    }
  };

  const handleRequestPrice = async () => {
    if (!user) {
      $(".loginLink").click(() => {
        $(".loginPopup").addClass("active");
      });
      setOpenModal(!openModal);
    } else {
      try {
        const response = await fetch(`${API_BASE_URL}frontend/request-price`, {
          method: "POST",
          body: JSON.stringify({
            store_id: storeId,
            lang: selectedLanguage,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.authorisation.token}`,
          },
        });
        const responseData = await response.json();
        if (responseData.res === true) {
          NotificationManager.success(responseData?.msg);
        } else {
          NotificationManager.warning(responseData?.msg);
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    fetchStoreDetails();
    fetchDetails();
  }, []);

  return (
    <div>
      <Header openModal={openModal} />
      <style>{`body { background: #F5F7FA; }`}</style>

      <div className="fairDiv">
        <div className="fairDtlsBanner">
          <img src={IMAGE_URL + allDetails?.store?.cover} alt="" />
        </div>
        <div className="mainDiv">
          <div className="fairTop">
            <div className="fairLft">
              <div className="logoLft">
                <img src={IMAGE_URL + allDetails?.store?.logo} alt="" />
              </div>
              <div className="logoRgt">
                <h2>{allDetails?.store?.name} </h2>
                <p>{allDetails?.store?.advertisement}</p>
                <a href="javascript:void(0);" onClick={() => handleAddStore()}>
                  {langJson?.followshop}
                </a>
              </div>
            </div>

            <div className="fairRgt">
              <Link to="/" className="cntBtn">
                {langJson?.contactus}
              </Link>
              <a href="javascript:void(0);" className="prceBtn" onClick={() => handleRequestPrice()}>
                {langJson?.requestforprice}
              </a>
            </div>
          </div>
        </div>

        <div className="mainDiv">
          <div className="featureDiv productListMain">
            <div className="mainDiv">
              <div className="hdnSec">
                <h2>{langJson?.privacypolicy}</h2>
              </div>
              <div className="aboutDiv" dangerouslySetInnerHTML={{__html: privacyData?.privacy_policy}} />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default FairTradePrivacyPolicy;
