import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL, IMAGE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";

const eventImg = require("./../assets/img/event1.png");
const eventImg2 = require("./../assets/img/event2-min.png");
const gallery = require("./../assets/img/location-img.png");

const EventDetails = () => {
  const {user} = useAuth();
  const {selectedLanguage, langJson} = useLanguage();
  const {eventId} = useParams();

  const [eventDetails, setEventDetails] = useState();
  const [loading, setLoading] = useState();

  const fetchEventDetails = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}frontend/events/${eventId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setEventDetails(responseData.data);
      } else {
      }
    } catch (error) {
      console.error("Error during Sign UP:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEventDetails();
  }, [eventId]);

  return (
    <div>
      <Header />
      <style>{`body { background: #F5F7FA; }`}</style>

      <div className="mainDiv">
        <div className="featureDiv productListMain">
          <div className="mainDiv">
            <div className="eventDiv">
              <img src={IMAGE_URL + eventDetails?.image} alt="" />
              <h2>{eventDetails?.title}</h2>
              <p>{eventDetails?.description}</p>
            </div>

            <div className="hdnSec lftPad">
              <h2>Gallery</h2>
            </div>

            <ul className="galleryDiv">
              {eventDetails?.images?.map((item, index) => (
                <li key={index}>
                  <img src={IMAGE_URL + item?.image} alt="" />
                </li>
              ))}
            </ul>

            <div className="eventDiv">
              <h2>{langJson?.location}</h2>
              <p>{eventDetails?.location}</p>
            </div>

            <div className="hdnSec">
              <h2>{langJson?.relatedevents}</h2>
            </div>

            <ul className="eventList">
              {eventDetails?.related_events?.map((item, index) => (
                <li key={index}>
                  <Link to={`/event/${item?.id}`}>
                    <img src={IMAGE_URL + item?.image} alt="" />
                    <h3>{item?.title}</h3>
                    <p>{item?.formatted_start_date_time}</p>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default EventDetails;
