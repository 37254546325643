import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";
import Sidebar from "../component/Sidebar";

const OrderDetails = () => {
  const {user} = useAuth();
  const {selectedLanguage, langJson} = useLanguage();
  const navigate = useNavigate();
  const actionDivRef = useRef(null);
  const {orderId} = useParams();

  const [loading, setLoading] = useState();
  const [dashboardDetails, setDashboardDetails] = useState();
  const [allDetails, setAllDetails] = useState();

  const fetchOrdersDetails = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}order/store-orders/${orderId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setAllDetails(responseData?.data);
      } else {
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      fetchOrdersDetails();
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = event => {
      if (!actionDivRef?.current?.contains(event.target)) {
        // Clicked outside the td, add your logic here
        // console.log("Clicked outside the td");
        // setSelectedId(null);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className="dashHeader">
        <Header />
      </div>
      <div className="adminDash">
        <Sidebar />

        <div className="orderRgt">
          <h2>{langJson?.orderdetails}</h2>
          <div className="orderRgtMain">
            <div className="orLft">
              <ul>
                <li>
                  <label>{langJson?.storename}</label>
                  {allDetails?.store?.user?.company_name}
                </li>
                <li>
                  <label>{langJson?.orderno}.</label>
                  {allDetails?.order_number}
                </li>
                <li>
                  <label>{langJson?.orderdate}</label>
                  {allDetails?.ordered_date}
                </li>
                <li>
                  <label>{langJson?.billtoname}</label>
                  {allDetails?.name}
                </li>
                <li>
                  <label>{langJson?.total}</label>${allDetails?.total_amount}
                </li>
                <li>
                  <label>{langJson?.status}</label>
                  {allDetails?.status}
                </li>
                <li>
                  <label>{langJson?.address}</label>
                  {allDetails?.address}
                  {/* Diego Alveraze<br></br>
                  57/3 Lords Avenue, Golf Club, Ground Floor, New Plaza Building 24B Amron street, Texas */}
                </li>
              </ul>
            </div>
            <div className="orRgt">
              <div className="orderTbls">
                <div className="orderMainTbl">
                  <ul>
                    <li>{langJson?.No}.</li>
                    <li>{langJson?.item}</li>
                    <li>{langJson?.size}</li>
                    <li>{langJson?.price}($)</li>
                    <li>{langJson?.qty}</li>
                  </ul>
                  {allDetails?.products.map((chItem, chIndex) => (
                    <ul key={chIndex}>
                      <li>{chIndex + 1 < 10 ? "0" + (chIndex + 1) : chIndex + 1}</li>
                      <li>{chItem?.product?.title}</li>
                      <li>{chItem?.size}</li>
                      <li>{chItem?.price}</li>
                      <li>{chItem?.quantity}</li>
                    </ul>
                  ))}
                </div>
              </div>

              <div class="buttonList2">
                <div class="buttonBox">
                  {langJson?.totalquantity}
                  <strong>
                    {allDetails?.quantity} {langJson?.pcs}
                  </strong>
                </div>
                <div class="buttonBox">
                  {langJson?.totalprice}
                  <strong>${allDetails?.total_amount}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dashFooter">
        <h3>
          {langJson?.copyright} © Fair for Fair {new Date().getFullYear()}
        </h3>
      </div>
      <div className="footInner">
        <Footer />
      </div>
    </div>
  );
};

export default OrderDetails;
