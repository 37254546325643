import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";
import Sidebar from "../component/Sidebar";

const ChooseCategory = () => {
  const navigate = useNavigate();
  const {user} = useAuth();
  const {langJson} = useLanguage();

  const [signupStatus, setSignupStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [allDetails, setallDetails] = useState();
  const [selectedCategoryTypes, setSelectedCategoryTypes] = useState([]);
  const [categoryData, setCategoryData] = useState([]);

  const fetchSavedCategories = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}stores/categories`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        let tempArray = [];
        if (responseData.data.length > 0) {
          responseData.data.forEach(element => {
            tempArray.push(element.category_id);
          });
          setSelectedCategoryTypes(tempArray);
        }
      } else {
      }
    } catch (error) {}
  };

  const fetchCategoryData = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}products/categories`);
      const data = await response.json();

      if (data && Array.isArray(data.data) && data.data.length > 0) {
        setCategoryData(data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = event => {
    const {value, checked} = event.target;
    if (checked) {
      setSelectedCategoryTypes(prevSelected => [...prevSelected, parseInt(value)]);
    } else {
      setSelectedCategoryTypes(prevSelected => prevSelected.filter(paymentType => paymentType !== parseInt(value)));
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}stores/categories`, {
        method: "POST",
        body: JSON.stringify({
          categories: selectedCategoryTypes,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setSignupStatus(responseData.msg);
        setLoading(false);
      } else {
        // Authentication failed, display an error message
        setSignupStatus(responseData.msg);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error during Sign UP:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSavedCategories();
    fetchCategoryData();
  }, []);

  return (
    <div>
      <div className="dashHeader">
        <Header />
      </div>
      <div className="adminDash">
        <Sidebar />

        <div className="rightSide">
          <h2>{langJson?.choosecategory}</h2>
          <div className="chooseCategory">
            <div className="ChooseCategoryRepeatSec">
              {categoryData?.map((item, index) => (
                <ul className="addAddress choosePayment" key={index}>
                  <h3>{item?.title}</h3>
                  {item?.sub_categories?.map((item, index) => (
                    <li key={index}>
                      <input
                        type="checkbox"
                        name="paymentType"
                        value={parseInt(item?.id)}
                        checked={selectedCategoryTypes.includes(parseInt(item?.id))}
                        onChange={handleChange}
                      />
                      <label>{item.title}</label>
                    </li>
                  ))}
                </ul>
              ))}
            </div>
            <button type="submit" disabled={loading} onClick={() => handleSubmit()}>
              {loading ? langJson?.pleasewait : langJson?.savechanges}
            </button>
            <span className="lgnStatus">{signupStatus}</span>
          </div>
        </div>
      </div>
      <div className="dashFooter">
        <h3>
          {langJson?.copyright} © Fair for Fair {new Date().getFullYear()}
        </h3>
      </div>
      <div className="footInner">
        <Footer />
      </div>
    </div>
  );
};

export default ChooseCategory;
