import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";

const carouselImg = require("./../assets/img/banner-min.png");
const passwordIcon = require("./../assets/img/password-icon.svg").default;

const Register = props => {
  const navigate = useNavigate();
  const {selectedLanguage, langJson} = useLanguage();
  const [formData, setFormData] = useState({
    email: "",
    confirmEmail: "",
    password: "",
    confirmPassword: "",
    company: "",
    fullName: "",
    phone: "",
    address: "",
    country: "",
    city: "",
    state: "",
    zipCode: "",
    taxID: "",
  });

  const [signupStatus, setSignupStatus] = useState("");
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChange = event => {
    const {name, value, type, checked} = event.target;
    const newValue = type === "checkbox" ? checked : value;

    setFormData(prevData => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(prevShowPassword => !prevShowPassword);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (!formData.email) {
      setSignupStatus(langJson?.emailisrequired);
    } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(formData.email)) {
      setSignupStatus(langJson?.emailisnotvalid);
    } else if (!formData.confirmEmail) {
      setSignupStatus(langJson?.confirmemailisrequired);
    } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(formData.confirmEmail)) {
      setSignupStatus(langJson?.confirmemailisnotvalid);
    } else if (formData.email !== formData.confirmEmail) {
      setSignupStatus(langJson?.emailconfirmemailshouldbesame);
    } else if (!formData.password) {
      setSignupStatus(langJson?.passwordisrequired);
    } else if (!/(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(formData.password)) {
      setSignupStatus(`${langJson?.yourpasswordisnotvalid} (Ex- Abc@1234)`);
    } else if (!formData.confirmPassword) {
      setSignupStatus(langJson?.confirmpasswordisrequired);
    } else if (formData.password !== formData.confirmPassword) {
      setSignupStatus(langJson?.passwordconfirmpasswordshouldbesame);
    } else if (!formData.company) {
      setSignupStatus(langJson?.companyisrequired);
    } else if (!formData.fullName) {
      setSignupStatus(langJson?.fullnameisrequired);
    } else if (!formData.phone) {
      setSignupStatus(langJson?.phoneisrequired);
    } else if (!formData.address) {
      setSignupStatus(langJson?.addressisrequired);
    } else if (!formData.country) {
      setSignupStatus(langJson?.countryisrequired);
    } else if (!formData.city) {
      setSignupStatus(langJson?.cityisrequired);
    } else if (!formData.state) {
      setSignupStatus(langJson?.stateisrequired);
    } else if (!formData.zipCode) {
      setSignupStatus(langJson?.zipcodeisrequired);
    } else if (!formData.taxID) {
      setSignupStatus(langJson?.taxidisrequired);
    } else {
      setSignupStatus("");
      setLoading(true);
      const form = new FormData();
      form.append("email", formData.email);
      form.append("confirm_email", formData.confirmEmail);
      form.append("password", formData.password);
      form.append("confirm_password", formData.confirmPassword);
      form.append("company_name", formData.company);
      form.append("name", formData.fullName);
      form.append("phone", formData.phone);
      form.append("address", formData.address);
      form.append("country", formData.country);
      form.append("city", formData.city);
      form.append("state", formData.state);
      form.append("zip_code", formData.zipCode);
      form.append("tax_id", formData.taxID);
      form.append("role", "customer");

      try {
        const response = await fetch(`${API_BASE_URL}users/signup`, {
          method: "POST",
          body: form,
        });
        const responseData = await response.json();
        if (responseData.res === true) {
          setFormData({
            email: "",
            confirmEmail: "",
            password: "",
            confirmPassword: "",
            company: "",
            fullName: "",
            phone: "",
            address: "",
            country: "",
            city: "",
            state: "",
            zipCode: "",
            taxID: "",
          });
          setSignupStatus(responseData.msg);
          setLoading(false);
        } else {
          setSignupStatus(responseData.msg);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error during Sign UP:", error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    $(".loginLink").click(() => {
      $(".loginPopup").addClass("active");
    });
    $(".crossBtn").click(() => {
      $(".loginPopup").removeClass("active");
    });
  }, []);

  return (
    <div>
      <Header />
      <div className="registerMainDiv">
        <div className="registerDiv">
          <h2>{langJson?.createaccount}!</h2>
          <p>
            {langJson?.letsgetstartedto} Fair For Fair {langJson?.account}
          </p>

          <form onSubmit={handleSubmit}>
            <ul>
              <li>
                <input type="text" name="email" placeholder={langJson?.emailaddress} value={formData.email} onChange={handleChange} />
              </li>
              <li>
                <input type="text" name="confirmEmail" placeholder={langJson?.confirmemailaddress} value={formData.confirmEmail} onChange={handleChange} />
              </li>

              <li>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder={langJson?.password}
                  value={formData.password}
                  onChange={handleChange}
                />
                <div className="visibleClick" onClick={togglePasswordVisibility}>
                  <img src={passwordIcon} alt="Toggle Password Visibility" />
                </div>
              </li>

              <li>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  placeholder={langJson?.confirmpassword}
                  value={formData.confirmPassword}
                  onChange={handleChange}
                />
                <div className="visibleClick" onClick={toggleConfirmPasswordVisibility}>
                  <img src={passwordIcon} alt="Toggle Password Visibility" />
                </div>
              </li>

              <li>
                <input type="text" name="company" onChange={handleChange} placeholder={langJson?.company} value={formData.company} />
              </li>
              <li>
                <input type="text" name="fullName" onChange={handleChange} placeholder={langJson?.fullname} value={formData.fullName} />
              </li>
              <li>
                <input type="text" name="phone" onChange={handleChange} placeholder={langJson?.phonefaxmobilenumber} value={formData.phone} />
              </li>
              <li>
                <input type="text" name="address" onChange={handleChange} placeholder={langJson?.address} value={formData.address} />
              </li>
              <li>
                <input type="text" name="country" onChange={handleChange} placeholder={langJson?.country} value={formData.country} />
              </li>
              <li>
                <input type="text" name="city" onChange={handleChange} placeholder={langJson?.city} value={formData.city} />
              </li>
              <li>
                <input type="text" name="state" onChange={handleChange} placeholder={langJson?.state} value={formData.state} />
              </li>
              <li>
                <input type="text" name="zipCode" onChange={handleChange} placeholder={langJson?.zipcode} value={formData.zipCode} />
              </li>
              <li>
                <input type="text" name="taxID" onChange={handleChange} placeholder={langJson?.taxid} value={formData.taxID} />
              </li>
              <li>
                <button type="submit" disabled={loading ? true : false}>
                  {langJson?.register}
                </button>
                <span className="lgnStatus">{signupStatus}</span>
                <p className="loginLink">
                  {langJson?.alreadyhaveanaccount}? {langJson?.loginnow}!
                </p>
              </li>
            </ul>
          </form>

          {userData && (
            <div className="home">
              <h2>
                {langJson?.welcome}, {userData.name}!
              </h2>
              <p>
                {langJson?.email}: {userData.email}
              </p>
              <p>
                {langJson?.role}: {userData.role}
              </p>
              {/* Add more fields as needed */}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Register;
