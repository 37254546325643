import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL, IMAGE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";
import Sidebar from "../component/Sidebar";

const deleteIcon = require("./../assets/img/delete-icon.svg").default;

const CustomizeStore = () => {
  const {user} = useAuth();
  const navigate = useNavigate();
  const {selectedLanguage, langJson} = useLanguage();
  const {id} = useParams();

  const [addFormData, setAddFormData] = useState({
    advertisingText: null,
    facecbook: null,
    linkedin: null,
    twitter: null,
    instagram: null,
    youtube: null,
    paymentTerms: "",
    shippingTerms: "",
    privacyPolicy: "",
    tax: "",
  });
  const [sizeArray, setSizeArray] = useState([]);
  const [signupStatus, setSignupStatus] = useState("");
  const [uploadLogoImage, setUploadLogoImage] = useState();
  const [uploadCoverImage, setUploadCoverImage] = useState();
  const [imageError, setImageError] = useState();
  const [loading, setLoading] = useState();
  const [addBtnDis, setAddBtnDis] = useState();
  const [images, setImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [imagesPath, setImagesPath] = useState([]);
  const [allDetails, setAllDetails] = useState();
  const currency = ["$", "€", "CHF"];

  const handleChange = event => {
    const {name, value} = event.target;

    setAddFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchStoreDetails = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}stores/${user?.store}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setAllDetails(responseData.data);
        setAddFormData({
          advertisingText: responseData.data.advertisement,
          facecbook: responseData.data.facebook,
          linkedin: responseData.data.linkedin,
          twitter: responseData.data.twitter,
          instagram: responseData.data.instagram,
          youtube: responseData.data.youtube,
          paymentTerms: responseData.data.payment_terms,
          shippingTerms: responseData.data.shipping_terms,
          privacyPolicy: responseData.data.privacy_policy,
          tax: responseData.data.tax,
        });
        if (responseData.data.logo) {
          try {
            const imageResponse = await fetch(IMAGE_URL + responseData.data.logo);
            const blob = await imageResponse.blob();
            // const file = new File([blob], 'logo.jpg', { type: blob.type });

            // setUploadLogoImage(file);
            setUploadLogoImage(blob);
          } catch (error) {
            console.error("Error fetching image:", error);
          }
        }
        if (responseData.data.cover) {
          try {
            const imageResponse = await fetch(IMAGE_URL + responseData.data.cover);
            const blob = await imageResponse.blob();
            setUploadCoverImage(blob);
          } catch (error) {
            console.error("Error fetching image:", error);
          }
        } else {
        }
      }
    } catch (error) {
      console.error("Error during Sign UP:", error);
    }
  };

  const onImageMutipleUpload = e => {
    const allowedExtensions = ["jpg", "jpeg", "png"];
    const files = e.target.files;

    if (files.length > 0) {
      Array.from(files).forEach(file => {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (allowedExtensions.includes(fileExtension)) {
          setNewImages(prev => [...prev, file]);
        } else {
          setImageError(langJson?.invalid_file_format_Please_upload_a_valid__image_file);
        }
      });
    }
  };

  const handleRemoveImage = index => {
    let tempNewArray = [...newImages];
    let newArray = tempNewArray.filter((item, i) => index !== i);
    setNewImages(newArray);
  };

  const deleteLogoImage = async () => {
    setUploadLogoImage(null);
    try {
      const response = await fetch(`${API_BASE_URL}stores/logo`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
      } else {
      }
    } catch (error) {
      console.error("Error during Sign UP:", error);
    }
  };

  const deleteCoverImage = async () => {
    setUploadCoverImage(null);
    try {
      const response = await fetch(`${API_BASE_URL}stores/cover`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
      } else {
      }
    } catch (error) {
      console.error("Error during Sign UP:", error);
    }
  };

  const onImageLogoUploadChange = e => {
    const allowedExtensions = ["jpg", "jpeg", "png"];
    const file = e.target.files[0];
    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        setUploadLogoImage(file);
        setSignupStatus("");
      } else {
        setUploadLogoImage(null);
        setSignupStatus(langJson?.invalid_file_format_Please_upload_a_valid__image_file);
      }
    }
  };

  const handleLogoImageDrop = event => {
    event.preventDefault();
    const file = event.dataTransfer.files[0]; // Get the first file

    if (file) {
      const allowedExtensions = ["jpg", "jpeg", "png"];
      if (file) {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (allowedExtensions.includes(fileExtension)) {
          setUploadLogoImage(file);
          setSignupStatus("");
        } else {
          setUploadCoverImage(null);
          setSignupStatus(langJson?.invalid_file_format_Please_upload_a_valid__image_file);
        }
      }
    } else {
    }
  };

  const onImageCoverUploadChange = e => {
    const allowedExtensions = ["jpg", "jpeg", "png"];
    const file = e.target.files[0];
    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        setUploadCoverImage(file);
        setSignupStatus("");
      } else {
        setUploadCoverImage(null);
        setSignupStatus(langJson?.invalid_file_format_Please_upload_a_valid__image_file);
      }
    }
  };

  const handleCoverImageDrop = event => {
    event.preventDefault();
    const file = event.dataTransfer.files[0]; // Get the first file

    if (file) {
      const allowedExtensions = ["jpg", "jpeg", "png"];
      if (file) {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (allowedExtensions.includes(fileExtension)) {
          setUploadCoverImage(file);
          setSignupStatus("");
        } else {
          setUploadCoverImage(null);
          setSignupStatus(langJson?.invalid_file_format_Please_upload_a_valid__image_file);
        }
      }
    } else {
    }
  };

  const onEditShop = async () => {
    if (addFormData.advertisingText === null || addFormData.advertisingText === undefined || addFormData.advertisingText === "") {
      setSignupStatus(langJson?.advertisingtextisrequired);
      // } else if (addFormData.facecbook === null || addFormData.facecbook === undefined || addFormData.facecbook === "") {
      //   setSignupStatus(langJson?.facecbookfieldisrequired);
      // } else if (addFormData.linkedin === null || addFormData.linkedin === undefined || addFormData.linkedin === "") {
      //   setSignupStatus(langJson?.linkedinfieldisrequired);
      // } else if (addFormData.twitter === null || addFormData.twitter === undefined || addFormData.twitter === "") {
      //   setSignupStatus(langJson?.twitterfieldisrequired);
      // } else if (addFormData.instagram === null || addFormData.instagram === undefined || addFormData.instagram === "") {
      //   setSignupStatus(langJson?.instagramfieldisrequired);
      // } else if (addFormData.youtube === null || addFormData.youtube === undefined || addFormData.youtube === "") {
      //   setSignupStatus(langJson?.youtubefieldisrequired);
      //   } else if (!addFormData.paymentTerms) {
      //     setSignupStatus(langJson?.paymenttermsisrequired);
      //   } else if (!addFormData.shippingTerms) {
      //     setSignupStatus(langJson?.shippingtermsisrequired);
      //   } else if (!addFormData.tax) {
      //     setSignupStatus(langJson?.taxisrequired);
      // } else if (!uploadLogoImage) {
      //   setSignupStatus(langJson?.logoimageisrequired);
      // } else if (!uploadCoverImage) {
      //   setSignupStatus(langJson?.coverimageisrequired);
    } else {
      setSignupStatus("");
      setAddBtnDis(true);
      const fd = new FormData();

      fd.append("logo", uploadLogoImage ? uploadLogoImage : "");
      fd.append("cover", uploadCoverImage ? uploadCoverImage : "");

      fd.append("advertisement", addFormData.advertisingText ? addFormData.advertisingText : "");
      fd.append("facebook", addFormData.facecbook ? addFormData.facecbook : "");
      fd.append("linkedin", addFormData.linkedin ? addFormData.linkedin : "");
      fd.append("twitter", addFormData.twitter ? addFormData.twitter : "");
      fd.append("instagram", addFormData.instagram ? addFormData.instagram : "");
      fd.append("youtube", addFormData.youtube ? addFormData.youtube : "");
      fd.append("tax", addFormData.tax ? addFormData.tax : 0);
      fd.append("payment_terms", addFormData.paymentTerms ? addFormData.paymentTerms : "");
      fd.append("shipping_terms", addFormData.shippingTerms ? addFormData.shippingTerms : "");
      fd.append("privacy_policy", addFormData.privacyPolicy ? addFormData.privacyPolicy : "");
      fd.append("lang", selectedLanguage);

      try {
        const response = await fetch(`${API_BASE_URL}stores/${user?.store}`, {
          method: "POST",
          body: fd,
          headers: {
            Authorization: `Bearer ${user.authorisation.token}`,
          },
        });
        const responseData = await response.json();
        if (responseData.res === true) {
          setSignupStatus(responseData.msg);
          setAddBtnDis(false);
        } else {
          setSignupStatus(responseData.msg);
          setAddBtnDis(false);
        }
      } catch (error) {
        console.error("Error during Sign UP:", error);
        setAddBtnDis(false);
      }
    }
  };

  useEffect(() => {
    fetchStoreDetails();
  }, []);

  return (
    <div>
      <div className="dashHeader">
        <Header />
      </div>
      <div className="adminDash">
        <Sidebar />
        <div className="rightSide">
          <h2>{langJson?.customizeyourstore}</h2>
          <ul className="addAddress">
            <li>
              <label>{langJson?.advertisingtext}</label>
              <input type="text" name="advertisingText" onChange={handleChange} value={addFormData.advertisingText} />
            </li>
            <li>
              <label>{langJson?.facecbook}</label>
              <input type="text" name="facecbook" onChange={handleChange} value={addFormData.facecbook} />
            </li>
            <li>
              <label>{langJson?.linkedin}</label>
              <input type="text" name="linkedin" onChange={handleChange} value={addFormData.linkedin} />
            </li>
            <li>
              <label>{langJson?.twitter}</label>
              <input type="text" name="twitter" onChange={handleChange} value={addFormData.twitter} />
            </li>
            <li>
              <label>{langJson?.instagram}</label>
              <input type="text" name="instagram" onChange={handleChange} value={addFormData.instagram} />
            </li>
            <li>
              <label>{langJson?.youtube}</label>
              <input type="text" name="youtube" onChange={handleChange} value={addFormData.youtube} />
            </li>
            <li>
              <label>{langJson?.tax}(%):</label>
              <input type="text" name="tax" value={addFormData.tax} onChange={handleChange} />
            </li>
            <li className="fullWidth">
              <label>{langJson?.paymentterms}:</label>
              <textarea name="paymentTerms" value={addFormData.paymentTerms} onChange={handleChange} />
            </li>
            <li className="fullWidth">
              <label>{langJson?.shippingterms}:</label>
              <textarea name="shippingTerms" value={addFormData.shippingTerms} onChange={handleChange} />
            </li>
            <li className="fullWidth">
              <label>{langJson?.imprintprivacypolicy}:</label>
              <textarea name="privacyPolicy" value={addFormData.privacyPolicy} onChange={handleChange} />
            </li>
            <li className="fullWidth">
              <label>{langJson?.logoimage}:</label>
              {!uploadLogoImage ? (
                <>
                  <input type="file" onChange={e => onImageLogoUploadChange(e)} />
                  <div className="uploadFle" onDragOver={e => e.preventDefault()} onDrop={handleLogoImageDrop}></div>
                </>
              ) : (
                <>
                  <img className="productImgClass" src={URL.createObjectURL(uploadLogoImage)} />
                  <img className="productDelIcon" src={deleteIcon} alt="" onClick={() => deleteLogoImage()} />
                </>
              )}
            </li>
            <li className="fullWidth">
              <label>{langJson?.coverimage}:</label>
              {!uploadCoverImage ? (
                <>
                  <input type="file" onChange={e => onImageCoverUploadChange(e)} />
                  <div className="uploadFle" onDragOver={e => e.preventDefault()} onDrop={handleCoverImageDrop}></div>
                </>
              ) : (
                <>
                  <img className="productImgClass" src={URL.createObjectURL(uploadCoverImage)} />
                  <img className="productDelIcon" src={deleteIcon} alt="" onClick={() => deleteCoverImage()} />
                </>
              )}
            </li>
            <li className="fullWidth saveBtn">
              <button type="button" disabled={addBtnDis} onClick={() => onEditShop()}>
                {addBtnDis ? langJson?.pleasewait : langJson?.savechanges}
              </button>
            </li>
            <span className="lgnStatus">{signupStatus}</span>
          </ul>
        </div>
      </div>
      <div className="dashFooter">
        <h3>
          {langJson?.copyright} © Fair for Fair {new Date().getFullYear()}
        </h3>
      </div>
      <div className="footInner">
        <Footer />
      </div>
    </div>
  );
};

export default CustomizeStore;
