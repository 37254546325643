import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL, IMAGE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";
import Sidebar from "../component/Sidebar";

const icon1 = require("./../assets/img/dashboard-icon.svg").default;
const icon2 = require("./../assets/img/info-icon.svg").default;
const icon3 = require("./../assets/img/app-icon.svg").default;
const icon4 = require("./../assets/img/payment-icon.svg").default;
const icon5 = require("./../assets/img/store-icon.svg").default;
const icon6 = require("./../assets/img/list-icon.svg").default;
const icon7 = require("./../assets/img/order-icon.svg").default;
const icon8 = require("./../assets/img/plan-icon.svg").default;
const icon9 = require("./../assets/img/logout-icon.svg").default;
const deleteIcon = require("./../assets/img/delete-icon.svg").default;

const EditEvents = () => {
  const {user} = useAuth();
  const navigate = useNavigate();
  const {selectedLanguage, langJson} = useLanguage();
  const imageBtnRef = useRef();
  const {id} = useParams();
  const coverImageBtnRef = useRef();

  const [addFormData, setAddFormData] = useState({
    title: null,
    description: null,
    start_date_time: null,
    location: null,
  });
  const [signupStatus, setSignupStatus] = useState("");
  const [uploadFrontImage, setUploadFrontImage] = useState();
  const [imageError, setImageError] = useState();
  const [loading, setLoading] = useState();
  const [addBtnDis, setAddBtnDis] = useState();
  const [images, setImages] = useState([]);
  const [prevImages, setPrevImages] = useState([]);
  const [allDetails, setAllDetails] = useState();
  const [imagesPath, setImagesPath] = useState([]);

  const handleChange = event => {
    const {name, value} = event.target;

    setAddFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFrontImageDrop = event => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;

    if (droppedFiles.length > 0) {
      const allowedExtensions = ["jpg", "jpeg", "png"];

      Array.from(droppedFiles).forEach(file => {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (allowedExtensions.includes(fileExtension)) {
          setImages(prev => [...prev, file]);
        } else {
          setImageError(langJson?.invalid_file_format_Please_upload_a_valid__image_file);
        }
      });
    }
  };

  const onImageFrontUploadChange = e => {
    const allowedExtensions = ["jpg", "jpeg", "png"];
    const file = e.target.files[0];
    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        setUploadFrontImage(file);
        setSignupStatus("");
      } else {
        setUploadFrontImage(null);
        setSignupStatus(langJson?.invalid_file_format_Please_upload_a_valid__image_file);
      }
    }
  };

  const onImageMutipleUpload = e => {
    const allowedExtensions = ["jpg", "jpeg", "png"];
    const files = e.target.files;

    if (files.length > 0) {
      Array.from(files).forEach(file => {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (allowedExtensions.includes(fileExtension)) {
          setImages(prev => [...prev, file]);
        } else {
          setImageError(langJson?.invalid_file_format_Please_upload_a_valid__image_file);
        }
      });
    }
  };

  const handleRemoveImage = index => {
    let tempArray = [...images];
    let newArray = tempArray.filter((item, i) => index !== i);
    setImages(newArray);
  };

  const fetchEventDetails = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}stores/events/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setAllDetails(responseData.data);
        setAddFormData({
          title: responseData.data.title,
          description: responseData.data.description,
          start_date_time: responseData.data.start_date_time,
          location: responseData.data.location,
        });

        const fetchImage = async item => {
          try {
            const imageResponse = await fetch(IMAGE_URL + item.image);
            const blob = await imageResponse.blob();
            return blob;
          } catch (error) {
            console.error("Error fetching image:", error);
            // You might want to handle the error here
            return null;
          }
        };

        const fetchImageArray = async () => {
          const imagePromises = responseData.data.images.map(fetchImage);
          try {
            const images = await Promise.all(imagePromises);
            const filteredImages = images.filter(image => image !== null);
            setPrevImages(filteredImages);
          } catch (error) {
            console.error("Error fetching images:", error);
          }
        };
        fetchImageArray();

        const fetchCoverImage = async item => {
          try {
            const imageResponse = await fetch(IMAGE_URL + item);
            const blob = await imageResponse.blob();
            return blob;
          } catch (error) {
            console.error("Error fetching image:", error);
            return null;
          }
        };
        fetchCoverImage(responseData.data.image).then(blob => {
          if (blob) {
            setUploadFrontImage(blob);
          } else {
          }
        });
      } else {
      }
    } catch (error) {
      console.error("Error during Sign UP:", error);
    }
  };

  const onPostArticle = async () => {
    if (addFormData.title === null || addFormData.title === undefined || addFormData.title === "") {
      setSignupStatus(langJson?.titleisrequired);
    } else if (addFormData.start_date_time === null || addFormData.start_date_time === undefined || addFormData.start_date_time === "") {
      setSignupStatus(langJson?.eventstartdateisrequired);
    } else if (addFormData.description === null || addFormData.description === undefined || addFormData.description === "") {
      setSignupStatus(langJson?.descriptionisrequired);
    } else if (!uploadFrontImage) {
      setSignupStatus(langJson?.pleaseselectcoverimage);
    } else if (images.length === 0 && prevImages.length === 0) {
      setSignupStatus(langJson?.pleaseseleaimage);
    } else {
      setAddBtnDis(true);
      setSignupStatus("");
      const fd = new FormData();

      fd.append("image", uploadFrontImage);
      if (images.length > 0) {
        images.map(item => {
          fd.append("images[]", item);
        });
      } else {
        fd.append("images[]", []);
      }

      fd.append("title", addFormData.title);
      fd.append("start_date_time", addFormData.start_date_time);
      fd.append("description", addFormData.description);
      fd.append("location", addFormData.location);
      fd.append("lang", selectedLanguage);
      try {
        const response = await fetch(`${API_BASE_URL}stores/events/${id}`, {
          method: "POST",
          body: fd,
          headers: {
            Authorization: `Bearer ${user.authorisation.token}`,
          },
        });
        const responseData = await response.json();
        if (responseData.res === true) {
          setSignupStatus(responseData.msg);
          setLoading(false);
          setAddBtnDis(false);
        } else {
          setSignupStatus(responseData.msg);
          setLoading(false);
          setAddBtnDis(false);
        }
      } catch (error) {
        console.error("Error during Sign UP:", error);
        setLoading(false);
        setAddBtnDis(false);
      }
    }
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  useEffect(() => {
    setAddFormData({
      title: null,
      description: null,
      start_date_time: null,
      location: null,
    });
    setUploadFrontImage(null);
    setImages([]);
    fetchEventDetails();
  }, []);

  return (
    <div>
      <div className="dashHeader">
        <Header />
      </div>
      <div className="adminDash">
        <Sidebar />
        <div className="rightSide">
          <h2>
            {langJson?.add} {langJson?.events}
          </h2>
          <ul className="addAddress">
            <li>
              <label>{langJson?.title}</label>
              <input type="text" name="title" onChange={handleChange} value={addFormData.title} />
            </li>
            <li>
              <label>{langJson?.eventstartdate}</label>
              <input type="datetime-local" min={getCurrentDateTime()} name="start_date_time" onChange={handleChange} value={addFormData.start_date_time} />
            </li>
            <li className="fullWidth">
              <label>{langJson?.description}</label>
              <textarea name="description" value={addFormData.description} onChange={handleChange}></textarea>
            </li>
            <li>
              <label>{langJson?.location}</label>
              <input type="text" name="location" onChange={handleChange} value={addFormData.location} />
            </li>
            <li className="fullWidth">
              <label>{langJson?.uploadcoverimage}:</label>
              {!uploadFrontImage ? (
                <>
                  <input type="file" ref={coverImageBtnRef} hidden onChange={e => onImageFrontUploadChange(e)} />
                  <button onClick={() => coverImageBtnRef.current.click()}>{langJson?.choosecoverimage}</button>
                  <div className="uploadFle" onDragOver={e => e.preventDefault()} onDrop={handleFrontImageDrop}></div>
                </>
              ) : (
                <>
                  <img className="productImgClass" src={URL.createObjectURL(uploadFrontImage)} />
                  <img className="productDelIcon" src={deleteIcon} alt="" onClick={() => setUploadFrontImage(null)} />
                </>
              )}
            </li>
            <li className="fullWidth">
              <label>{langJson?.uploadimages}:</label>
              <span className="lgnStatus">{imageError}</span>
              <br></br>
              <input type="file" ref={imageBtnRef} hidden onChange={e => onImageMutipleUpload(e)} multiple />
              <button onClick={() => imageBtnRef.current.click()}>{langJson?.chooseimages}</button>
              <div className="uploadFle" onDragOver={e => e.preventDefault()} onDrop={handleFrontImageDrop}></div>
              <br />
              {prevImages?.map((item, index) => {
                return (
                  <>
                    <img key={index} className="productImgClass" src={URL.createObjectURL(item)} />
                    <img key={index} src={deleteIcon} alt="" onClick={() => handleRemoveImage(index)} />
                  </>
                );
              })}
              {images?.map((item, index) => (
                <>
                  <img key={index} className="productImgClass" src={URL.createObjectURL(item)} />
                  <img key={index} src={deleteIcon} alt="" onClick={() => handleRemoveImage(index)} />
                </>
              ))}
            </li>

            <li className="fullWidth saveBtn">
              <button type="button" disabled={addBtnDis} onClick={() => onPostArticle()}>
                {addBtnDis ? langJson?.pleasewait : langJson?.savechanges}
              </button>
            </li>
            <span className="lgnStatus">{signupStatus}</span>
          </ul>
        </div>
      </div>
      <div className="dashFooter">
        <h3>
          {langJson?.copyright} © Fair for Fair {new Date().getFullYear()}
        </h3>
      </div>
      <div className="footInner">
        <Footer />
      </div>
    </div>
  );
};

export default EditEvents;
