import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL, IMAGE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";
import Sidebar from "../component/Sidebar";

const deleteIcon = require("./../assets/img/delete-icon.svg").default;

const ImprintPrivacyPolicy = () => {
  const {user} = useAuth();
  const navigate = useNavigate();
  const {selectedLanguage, langJson} = useLanguage();

  const [addFormData, setAddFormData] = useState({
    imprint: "",
    privacyPolicy: "",
  });
  const [signupStatus, setSignupStatus] = useState("");
  const [addBtnDis, setAddBtnDis] = useState();
  const [allDetails, setAllDetails] = useState();

  const handleChange = event => {
    const {name, value} = event.target;

    setAddFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchStoreDetails = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}stores/${user?.store}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setAllDetails(responseData.data);
        setAddFormData({
          imprint: responseData.data.imprint,
          privacyPolicy: responseData.data.privacy_policy,
        });
      }
    } catch (error) {
      console.error("Error during Sign UP:", error);
    }
  };

  const onEditShop = async () => {
    setSignupStatus("");
    setAddBtnDis(true);
    const fd = new FormData();
    fd.append("imprint", addFormData.imprint ? addFormData.imprint : "");
    fd.append("privacy_policy", addFormData.privacyPolicy ? addFormData.privacyPolicy : "");
    fd.append("lang", selectedLanguage);

    try {
      const response = await fetch(`${API_BASE_URL}stores/${user?.store}`, {
        method: "POST",
        body: fd,
        headers: {
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setSignupStatus(responseData.msg);
        setAddBtnDis(false);
      } else {
        setSignupStatus(responseData.msg);
        setAddBtnDis(false);
      }
    } catch (error) {
      console.error("Error during Sign UP:", error);
      setAddBtnDis(false);
    }
  };

  useEffect(() => {
    fetchStoreDetails();
  }, []);

  return (
    <div>
      <div className="dashHeader">
        <Header />
      </div>
      <div className="adminDash">
        <Sidebar />
        <div className="rightSide">
          <h2>{langJson?.imprintprivacypolicy}</h2>
          <ul className="addAddress">
            <li className="fullWidth">
              <label>{langJson?.imprint}:</label>
              <textarea name="imprint" value={addFormData.imprint} onChange={handleChange} />
            </li>
            <li className="fullWidth">
              <label>{langJson?.privacypolicy}:</label>
              <textarea name="privacyPolicy" value={addFormData.privacyPolicy} onChange={handleChange} />
            </li>
            <li className="fullWidth saveBtn">
              <button type="button" disabled={addBtnDis} onClick={() => onEditShop()}>
                {addBtnDis ? langJson?.pleasewait : langJson?.savechanges}
              </button>
            </li>
            <span className="lgnStatus">{signupStatus}</span>
          </ul>
        </div>
      </div>
      <div className="dashFooter">
        <h3>
          {langJson?.copyright} © Fair for Fair {new Date().getFullYear()}
        </h3>
      </div>
      <div className="footInner">
        <Footer />
      </div>
    </div>
  );
};

export default ImprintPrivacyPolicy;
