import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";

const passwordIcon = require("./../assets/img/password-icon.svg").default;

const ChangePassword = () => {
  const navigate = useNavigate();
  const {user, setUser} = useAuth();
  const {selectedLanguage, langJson} = useLanguage();

  const [signupStatus, setSignupStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChange = event => {
    const {name, value} = event.target;

    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(prev => !prev);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(prev => !prev);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(prev => !prev);
  };

  const onLogout = () => {
    localStorage.removeItem("FFFUserData");
    navigate("/");
    setUser(null);
  };

  const handleSubmit = async () => {
    if (!formData.oldPassword) {
      setSignupStatus(langJson?.oldpasswordisrequired);
    } else if (!formData.newPassword) {
      setSignupStatus(langJson?.newpasswordisrequired);
    } else if (!/(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(formData.newPassword)) {
      setSignupStatus(`${langJson?.yourpasswordisnotvalid} (Ex- Abc@1234)`);
    } else if (!formData.confirmPassword) {
      setSignupStatus(langJson?.confirmpasswordisrequired);
    } else if (formData.newPassword !== formData.confirmPassword) {
      setSignupStatus(langJson?.passwordconfirmpasswordshouldbesame);
    } else {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_URL}users/change-password`, {
          method: "POST",
          body: JSON.stringify({
            new_password: formData.newPassword,
            old_password: formData.oldPassword,
            new_password_confirmation: formData.confirmPassword,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.authorisation.token}`,
          },
        });
        const responseData = await response.json();
        if (responseData.res === true) {
          setSignupStatus(responseData.msg);
          setLoading(false);
          setFormData({
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
        } else {
          setSignupStatus(responseData.msg);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error during Sign UP:", error);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <Header />
      <style>{`body { background: #F5F7FA; }`}</style>

      <div className="mainDiv">
        <div className="accountLink">
          <ul>
            <li className="active">
              <Link to="/my-account">{langJson?.myaccount}</Link>
            </li>
            <li>
              <Link to="/my-orders">{langJson?.myorders}</Link>
            </li>
            <li>
              <Link to="/wishlist">{langJson?.wishlist}</Link>
            </li>
            <li>
              <Link to="/store-followed">{langJson?.storefollowed}</Link>
            </li>
            <li>
              <Link to="/shipping-address">{langJson?.shippingaddress}</Link>
            </li>
            <li>
              <a href="javascript:void(0);" onClick={() => onLogout()}>
                {langJson?.logout}
              </a>
            </li>
          </ul>
        </div>
        <div className="acctHdn">
          <div className="hdnSec">
            <h2>{langJson?.changepassword}</h2>
            {loading ? (
              <button disabled className="editProfileBtn">
                {langJson?.pleasewait}
              </button>
            ) : (
              <button onClick={() => handleSubmit()} className="editProfileBtn">
                {langJson?.update} {langJson?.password}
              </button>
            )}
          </div>

          <span className="lgnStatus">{signupStatus}</span>

          <div className="myaccountDiv changePassDiv">
            <ul>
              <li>
                <label>{langJson?.oldpassword}</label>
                <input
                  type={showOldPassword ? "text" : "password"}
                  name="oldPassword"
                  value={formData.oldPassword}
                  placeholder={langJson?.oldpassword}
                  onChange={handleChange}
                />
                <div className="visibleClick" onClick={toggleOldPasswordVisibility}>
                  <img src={passwordIcon} alt="Toggle Password Visibility" />
                </div>
              </li>
              <li>
                <label>{langJson?.newpassword}</label>
                <input
                  type={showNewPassword ? "text" : "password"}
                  name="newPassword"
                  value={formData.newPassword}
                  placeholder={langJson?.newpassword}
                  onChange={handleChange}
                />
                <div className="visibleClick" onClick={toggleNewPasswordVisibility }>
                  <img src={passwordIcon} alt="Toggle Password Visibility" />
                </div>
              </li>
              <li>
                <label>{langJson?.confirmpassword}</label>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  placeholder={langJson?.confirmpassword}
                  onChange={handleChange}
                />
                <div className="visibleClick" onClick={toggleConfirmPasswordVisibility }>
                  <img src={passwordIcon} alt="Toggle Password Visibility" />
                </div>
              </li>
            </ul>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default ChangePassword;
