import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";
import Sidebar from "../component/Sidebar";

const icon1 = require("./../assets/img/dashboard-icon.svg").default;
const icon2 = require("./../assets/img/info-icon.svg").default;
const icon3 = require("./../assets/img/app-icon.svg").default;
const icon4 = require("./../assets/img/payment-icon.svg").default;
const icon5 = require("./../assets/img/store-icon.svg").default;
const icon6 = require("./../assets/img/list-icon.svg").default;
const icon7 = require("./../assets/img/order-icon.svg").default;
const icon8 = require("./../assets/img/plan-icon.svg").default;
const icon9 = require("./../assets/img/logout-icon.svg").default;
const deleteIcon = require("./../assets/img/delete-icon.svg").default;
const uploadBtn = require("./../assets/img/search-btn.svg").default;
const bulletIcon = require("./../assets/img/bullet.svg").default;

const VendorDashboard = () => {
  const {user} = useAuth();
  const {selectedLanguage, langJson} = useLanguage();
  const navigate = useNavigate();
  const actionDivRef = useRef(null);

  const [loading, setLoading] = useState();
  const [dashboardDetails, setDashboardDetails] = useState();

  const fetchDashboard = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}stores/dashboard`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setDashboardDetails(responseData.data);
      } else {
      }
    } catch (error) {
      console.error("Error during Sign UP:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboard();
  }, []);

  useEffect(() => {
    const handleClickOutside = event => {
      if (!actionDivRef?.current?.contains(event.target)) {
        // Clicked outside the td, add your logic here
        // console.log("Clicked outside the td");
        // setSelectedId(null);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className="dashHeader">
        <Header />
      </div>
      <div className="adminDash">
        <Sidebar />

        <div className="rightSide rightTop">
          <h2>{langJson?.dashboard}</h2>

          <div className="dashTop">
            <ul>
              <li>
                <h3>{dashboardDetails?.total_orders}</h3>
                <p>{langJson?.totalorders}</p>
                {/* <div className="dashDis">+11.2%</div> */}
              </li>
              <li>
                <h3>{dashboardDetails?.total_products}</h3>
                <p>{langJson?.totalproducts}</p>
                {/* <div className="dashDis">+11.2%</div> */}
              </li>
              <li>
                <h3>${dashboardDetails?.total_earnings}</h3>
                <p>{langJson?.totalearnings}</p>
                {/* <div className="dashDis">+11.2%</div> */}
              </li>
              <li>
                <h3>{dashboardDetails?.total_requests}</h3>
                <p>{langJson?.newrequests}</p>
              </li>
            </ul>
          </div>

          <div className="dashHdn">
            <h3>{langJson?.orders}</h3>
            <div className="dashRgt">
              {/* <input type="file" />
              <div className="upBtn"></div> */}
              <button onClick={() => navigate("/orders")}>{langJson?.allorders}</button>
            </div>
          </div>

          <div className="prdtTable">
            <table>
              <tr>
                <th>{langJson?.orderno}</th>
                <th>{langJson?.name}</th>
                <th>{langJson?.country}</th>
                <th>{langJson?.email}</th>
                <th>{langJson?.quantity}</th>
                <th>{langJson?.price}</th>
                <th>{langJson?.orderdate}</th>
                <th>{langJson?.status}</th>
              </tr>
              {dashboardDetails?.orders?.data?.map((item, index) => (
                <tr key={index}>
                  <td>{item?.order_number}</td>
                  <td>{item?.name}</td>
                  <td>{item?.country}</td>
                  <td>{item?.email}</td>
                  <td>{item?.quantity}</td>
                  <td>
                    {item?.currency ? item?.currency : "$"}
                    {item?.total_amount}
                  </td>
                  <td>{item?.ordered_date}</td>
                  <td>{item?.status}</td>
                </tr>
              ))}
            </table>
          </div>

          <div className="dashHdn">
            <h3>{langJson?.products}</h3>
            <div className="dashRgt">
              {/* <input type="file" />
              <div className="upBtn"></div> */}
              <button onClick={() => navigate("/products")}>{langJson?.allproducts}</button>
            </div>
          </div>

          <div className="prdtTable">
            <table>
              <tr>
                {/* <th>
                  <input type="checkbox" />
                </th> */}
                <th>{langJson?.articleno}</th>
                <th>{langJson?.orderno}</th>
                <th>{langJson?.brand}</th>
                <th>{langJson?.category}</th>
                <th>{langJson?.unitprice}</th>
                <th>{langJson?.color}</th>
                <th>{langJson?.size}</th>
                <th>{langJson?.price}</th>
                <th>{langJson?.season}</th>
                <th>{langJson?.originalprice}</th>
                {/* <th>{langJson?.action}</th> */}
              </tr>
              {dashboardDetails?.products?.data?.map((item, index) => (
                <tr key={index}>
                  {/* <td>
                    <input type="checkbox" />
                  </td> */}
                  <td>{item?.article_no}</td>
                  <td>{item?.order_no}</td>
                  <td>{item?.brand}</td>
                  <td>{item?.cat_info?.title}</td>
                  <td>
                    {item?.currency} {item?.unit_price}
                  </td>
                  <td>{item?.color}</td>
                  <td>{item?.size}</td>
                  <td>
                    {item?.currency} {item?.price}
                  </td>
                  <td>{item?.season}</td>
                  <td>
                    {item?.currency} {item?.sale_price}
                  </td>
                  {/* <td className="actionHolder">
                    <div>
                      <img src={bulletIcon} alt="" onClick={() => onBulletClick(item?.id)} />
                      {parseInt(selectedId) === parseInt(item?.id) ? (
                        <div className="actionDiv" ref={actionDivRef}>
                          <button onClick={() => navigate(`/edit-product/${item?.id}`)}>{langJson?.edit}</button>
                          <button onClick={() => handleDelete(item?.id)}>{langJson?.delete}</button>
                        </div>
                      ) : null}
                    </div>
                  </td> */}
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
      <div className="dashFooter">
        <h3>
          {langJson?.copyright} © Fair for Fair {new Date().getFullYear()}
        </h3>
      </div>
      <div className="footInner">
        <Footer />
      </div>
    </div>
  );
};

export default VendorDashboard;
