import React, {Component, useState, useRef, useEffect, createRef} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import {Carousel} from "react-bootstrap";
import {useAuth} from "../context/AuthContext";
import {API_BASE_URL, IMAGE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";
import Slider from "react-slick";
import $ from "jquery";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {NotificationManager} from "react-notifications";
import axios from "axios";

const carouselImg = require("./../assets/img/banner-min.png");
const storeImg = require("./../assets/img/stores1-min.png");
const storeImg2 = require("./../assets/img/stores2-min.png");
const productImg = require("./../assets/img/product1-min.png");
const productImg2 = require("./../assets/img/product2-min.png");
const wishlistImg = require("./../assets/img/wishlist-icon2.svg").default;
const cartImg = require("./../assets/img/cart-icon.svg").default;
const categoryImg1 = require("./../assets/img/category1-min.png");
const categoryImg2 = require("./../assets/img/category2-min.png");
const icon1 = require("./../assets/img/icon1.svg").default;
const icon2 = require("./../assets/img/icon2.svg").default;
const icon3 = require("./../assets/img/icon3.svg").default;
const icon4 = require("./../assets/img/icon4.svg").default;

const Home = () => {
  const {user} = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const {selectedLanguage, langJson} = useLanguage();
  const sliderRef = createRef();
  const sliderCatRef = createRef();

  const [bannerList, setBannerList] = useState();
  const [featuredProductList, setFeaturedProductList] = useState();
  const [storeList, setStoreList] = useState();
  const [shopCategoryList, setShopCategoryList] = useState();
  const [wishBtn, setWishBtn] = useState(false);
  const [wishMsg, setWishMsg] = useState(false);
  const [openModal, setOpenModal] = useState();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: sliderRef.current,
    arrows: true,
    responsive: [
      {
        breakpoint: 768, // Adjust the breakpoint value based on your design needs
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const settingsCat = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: sliderCatRef.current,
    arrows: true,
    responsive: [
      {
        breakpoint: 768, // Adjust the breakpoint value based on your design needs
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const fetchBanners = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}frontend/banners`, {
        method: "GET",
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setBannerList(responseData.data);
      } else {
      }
    } catch (error) {}
  };

  const fetchFeaturedProduct = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}frontend/featured-products`, {
        method: "GET",
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setFeaturedProductList(responseData.data);
      } else {
      }
    } catch (error) {}
  };

  const fetchStores = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}frontend/featured-stores`, {
        method: "GET",
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setStoreList(responseData.data);
      } else {
      }
    } catch (error) {}
  };

  const fetchShopCategories = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}frontend/featured-categories`, {
        method: "GET",
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setShopCategoryList(responseData.data);
      } else {
      }
    } catch (error) {}
  };

  const handleAddWishlist = async productId => {
    if (!user) {
      $(".loginLink").click(() => {
        $(".loginPopup").addClass("active");
      });
      setOpenModal(!openModal);
    } else {
      setWishMsg(null);
      setWishBtn(true);
      try {
        const response = await fetch(`${API_BASE_URL}frontend/wishlists`, {
          method: "POST",
          body: JSON.stringify({
            product_id: productId,
            lang: selectedLanguage,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.authorisation?.token}`,
          },
        });
        const responseData = await response.json();
        if (responseData.res === true) {
          setWishMsg(responseData.msg);
          NotificationManager.success(responseData.msg);
          setWishBtn(false);
        } else {
          setWishMsg(responseData.msg);
          NotificationManager.warning(responseData.msg);
          setWishBtn(false);
        }
        setTimeout(() => {
          setWishMsg(null);
        }, 5000);
      } catch (error) {
        console.error("Error during Sign UP:", error);
        setWishBtn(false);
      }
    }
  };

  const fetchAllData = async () => {
    try {
      // Define the URLs for your API requests
      const bannersUrl = `${API_BASE_URL}frontend/banners`;
      const featuredProductsUrl = `${API_BASE_URL}frontend/featured-products`;
      const featuredStoresUrl = `${API_BASE_URL}frontend/featured-stores`;
      const featuredCategoriesUrl = `${API_BASE_URL}frontend/featured-categories`;

      // Make multiple requests concurrently using axios.all and Promise.all
      const [bannersResponse, featuredProductsResponse, storesResponse, categoriesResponse] = await axios.all([
        axios.get(bannersUrl),
        axios.post(featuredProductsUrl, {user_id: user?.id}),
        axios.get(featuredStoresUrl),
        axios.get(featuredCategoriesUrl),
      ]);

      // Handle each response individually
      if (bannersResponse.data.res === true) {
        setBannerList(bannersResponse.data.data);
      }

      if (featuredProductsResponse.data.res === true) {
        setFeaturedProductList(featuredProductsResponse.data.data);
      }

      if (storesResponse.data.res === true) {
        setStoreList(storesResponse.data.data);
      }

      if (categoriesResponse.data.res === true) {
        setShopCategoryList(categoriesResponse.data.data);
      }
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    // fetchBanners();
    // fetchFeaturedProduct();
    // fetchStores();
    // fetchShopCategories();
    fetchAllData();
    if (user?.role === "vendor" || user?.role === "admin") {
      navigate("/dashboard");
    }
  }, []);

  return (
    <div>
      <Header openModal={openModal} />

      <div className="banner">
        <Carousel>
          {bannerList?.map((item, index) => (
            <Carousel.Item key={index}>
              <img src={item?.image} alt="" />
              <div className="mainDiv">
                <Carousel.Caption>
                  <div dangerouslySetInnerHTML={{__html: item?.description}}></div>
                  {item?.target ? <Link to={item?.target}>{langJson?.shopnow}</Link> : null}
                </Carousel.Caption>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>

      <div className="storeDiv">
        <div className="mainDiv">
          <h2>{langJson?.ourstores}</h2>
          <Slider {...settings} ref={sliderRef}>
            {storeList?.map((item, index) => (
              <div className="storeItem" key={index}>
                <Link to={`/store/${item?.id}`} state={{from: location.pathname}}>
                  <img className="storeItemImg" src={IMAGE_URL + item?.logo} alt={`Store ${index}`} />
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      {featuredProductList?.length > 0 ? (
        <div className="featureDiv">
          <div className="mainDiv">
            <div className="hdnSec">
              <h2>{langJson?.featuredproducts}</h2>
              <Link to="/shop">{langJson?.seemore}</Link>
            </div>
            {/* {wishMsg ? <span className="lgnStatus">{wishMsg}</span> : null} */}
            <div className="productList">
              <ul>
                {featuredProductList?.map((item, index) => (
                  <li key={index}>
                    <div className="imgHolder">
                      <Link to={`/product/${item.slug}`}>
                        <img src={IMAGE_URL + item?.image} alt="" className="prdtImg" />
                        {/* <div className="discountItem">-18%</div> */}
                      </Link>
                      <div className="wishlistItem">
                        <img src={wishlistImg} alt="" onClick={() => handleAddWishlist(item?.id)} />
                      </div>
                    </div>
                    <Link to={`/product/${item.slug}`}>
                      <div className="prdtCaption">
                        <p>{item?.color && <span style={{background: item?.color}}></span>}</p>
                        <h2>{item?.title}</h2>
                        {parseInt(item?.is_public) === 1 ? (
                          <h3>
                            <span>
                              {item.currency}
                              {item.price}
                            </span>
                            {item.currency}
                            {item.sale_price} <img src={cartImg} alt="" />
                          </h3>
                        ) : (
                          <h3>
                            <img src={cartImg} alt="" />
                          </h3>
                        )}
                        {/* <h3>
                          {item?.currency}
                          {item?.price} <img src={cartImg} alt="" />
                        </h3> */}
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ) : null}

      {shopCategoryList?.length > 0 ? (
        <>
          <div className="categoryDiv">
            <div className="mainDiv">
              <h2>{langJson?.shopbycatagory}</h2>
              <Slider {...settingsCat} ref={sliderCatRef}>
                {shopCategoryList?.map((item, index) => (
                  <>
                    <div className="categoryList" key={index}>
                      <img className="d-block w-30" src={IMAGE_URL + item?.image} alt={`Store ${index}`} />
                      <h3>{item?.title}</h3>
                    </div>
                  </>
                ))}
              </Slider>
              {/* <Carousel>
                {shopCategoryList?.map((item, index) => (
                  <Carousel.Item key={index}>
                    <div className="d-flex justify-content-between">
                      <div className="categoryList">
                        <img className="d-block w-30" src={categoryImg1} alt="" />
                        <h3>Handbags of Distinction</h3>
                      </div>
                      <div className="categoryList">
                        <img className="d-block w-30" src={categoryImg2} alt="" />
                        <h3>Summer Sunglasses</h3>
                      </div>
                      <div className="categoryList">
                        <img className="d-block w-30" src={categoryImg1} alt="" />
                        <h3>Corporate Watches</h3>
                      </div>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel> */}
            </div>
          </div>
        </>
      ) : null}

      <div className="newsLetterDiv">
        <h2>{langJson?.getexclusiveoffersthelatesttrendstoyourinbox}</h2>
        <h3>{langJson?.dontmissout}</h3>
        <Link to="">{langJson?.signupforemail}</Link>
      </div>

      {/* <div className="featureDiv recomDiv">
        <div className="mainDiv">
          <div className="hdnSec">
            <h2>{langJson?.recommendedforyou}</h2>
            <Link to="">{langJson?.seemore}</Link>
          </div>

          <div className="productList">
            <Carousel>
              <Carousel.Item>
                <ul>
                  <li>
                    <Link to="/product-details">
                      <div className="imgHolder">
                        <img src={productImg} alt="" className="prdtImg" />
                        <div className="discountItem">-18%</div>
                        <div className="wishlistItem">
                          <img src={wishlistImg} alt="" />
                        </div>
                      </div>

                      <div className="prdtCaption">
                        <p>
                          <span style={{background: "#92582C"}}></span>
                          <span style={{background: "#C22B2B"}}></span>
                          <span style={{background: "#69814B"}}></span>
                        </p>
                        <h2>Gucci Men’s Jacket</h2>
                        <h3>
                          $450.00 <img src={cartImg} alt="" />
                        </h3>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/product-details">
                      <div className="imgHolder">
                        <img src={productImg2} alt="" className="prdtImg" />
                        <div className="discountItem">-18%</div>
                        <div className="wishlistItem">
                          <img src={wishlistImg} alt="" />
                        </div>
                      </div>

                      <div className="prdtCaption">
                        <p>
                          <span style={{background: "#92582C"}}></span>
                          <span style={{background: "#C22B2B"}}></span>
                          <span style={{background: "#69814B"}}></span>
                        </p>
                        <h2>Gucci Men’s Jacket</h2>
                        <h3>
                          $450.00 <img src={cartImg} alt="" />
                        </h3>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/product-details">
                      <div className="imgHolder">
                        <img src={productImg} alt="" className="prdtImg" />
                        <div className="discountItem">-18%</div>
                        <div className="wishlistItem">
                          <img src={wishlistImg} alt="" />
                        </div>
                      </div>

                      <div className="prdtCaption">
                        <p>
                          <span style={{background: "#92582C"}}></span>
                          <span style={{background: "#C22B2B"}}></span>
                          <span style={{background: "#69814B"}}></span>
                        </p>
                        <h2>Gucci Men’s Jacket</h2>
                        <h3>
                          $450.00 <img src={cartImg} alt="" />
                        </h3>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/product-details">
                      <div className="imgHolder">
                        <img src={productImg2} alt="" className="prdtImg" />
                        <div className="discountItem">-18%</div>
                        <div className="wishlistItem">
                          <img src={wishlistImg} alt="" />
                        </div>
                      </div>

                      <div className="prdtCaption">
                        <p>
                          <span style={{background: "#92582C"}}></span>
                          <span style={{background: "#C22B2B"}}></span>
                          <span style={{background: "#69814B"}}></span>
                        </p>
                        <h2>Gucci Men’s Jacket</h2>
                        <h3>
                          $450.00 <img src={cartImg} alt="" />
                        </h3>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/product-details">
                      <div className="imgHolder">
                        <img src={productImg} alt="" className="prdtImg" />
                        <div className="discountItem">-18%</div>
                        <div className="wishlistItem">
                          <img src={wishlistImg} alt="" />
                        </div>
                      </div>

                      <div className="prdtCaption">
                        <p>
                          <span style={{background: "#92582C"}}></span>
                          <span style={{background: "#C22B2B"}}></span>
                          <span style={{background: "#69814B"}}></span>
                        </p>
                        <h2>Gucci Men’s Jacket</h2>
                        <h3>
                          $450.00 <img src={cartImg} alt="" />
                        </h3>
                      </div>
                    </Link>
                  </li>
                </ul>
              </Carousel.Item>
              <Carousel.Item>
                <ul>
                  <li>
                    <Link to="/product-details">
                      <div className="imgHolder">
                        <img src={productImg} alt="" className="prdtImg" />
                        <div className="discountItem">-18%</div>
                        <div className="wishlistItem">
                          <img src={wishlistImg} alt="" />
                        </div>
                      </div>

                      <div className="prdtCaption">
                        <p>
                          <span style={{background: "#92582C"}}></span>
                          <span style={{background: "#C22B2B"}}></span>
                          <span style={{background: "#69814B"}}></span>
                        </p>
                        <h2>Gucci Men’s Jacket</h2>
                        <h3>
                          $450.00 <img src={cartImg} alt="" />
                        </h3>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/product-details">
                      <div className="imgHolder">
                        <img src={productImg2} alt="" className="prdtImg" />
                        <div className="discountItem">-18%</div>
                        <div className="wishlistItem">
                          <img src={wishlistImg} alt="" />
                        </div>
                      </div>

                      <div className="prdtCaption">
                        <p>
                          <span style={{background: "#92582C"}}></span>
                          <span style={{background: "#C22B2B"}}></span>
                          <span style={{background: "#69814B"}}></span>
                        </p>
                        <h2>Gucci Men’s Jacket</h2>
                        <h3>
                          $450.00 <img src={cartImg} alt="" />
                        </h3>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/product-details">
                      <div className="imgHolder">
                        <img src={productImg} alt="" className="prdtImg" />
                        <div className="discountItem">-18%</div>
                        <div className="wishlistItem">
                          <img src={wishlistImg} alt="" />
                        </div>
                      </div>

                      <div className="prdtCaption">
                        <p>
                          <span style={{background: "#92582C"}}></span>
                          <span style={{background: "#C22B2B"}}></span>
                          <span style={{background: "#69814B"}}></span>
                        </p>
                        <h2>Gucci Men’s Jacket</h2>
                        <h3>
                          $450.00 <img src={cartImg} alt="" />
                        </h3>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/product-details">
                      <div className="imgHolder">
                        <img src={productImg2} alt="" className="prdtImg" />
                        <div className="discountItem">-18%</div>
                        <div className="wishlistItem">
                          <img src={wishlistImg} alt="" />
                        </div>
                      </div>

                      <div className="prdtCaption">
                        <p>
                          <span style={{background: "#92582C"}}></span>
                          <span style={{background: "#C22B2B"}}></span>
                          <span style={{background: "#69814B"}}></span>
                        </p>
                        <h2>Gucci Men’s Jacket</h2>
                        <h3>
                          $450.00 <img src={cartImg} alt="" />
                        </h3>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/product-details">
                      <div className="imgHolder">
                        <img src={productImg} alt="" className="prdtImg" />
                        <div className="discountItem">-18%</div>
                        <div className="wishlistItem">
                          <img src={wishlistImg} alt="" />
                        </div>
                      </div>

                      <div className="prdtCaption">
                        <p>
                          <span style={{background: "#92582C"}}></span>
                          <span style={{background: "#C22B2B"}}></span>
                          <span style={{background: "#69814B"}}></span>
                        </p>
                        <h2>Gucci Men’s Jacket</h2>
                        <h3>
                          $450.00 <img src={cartImg} alt="" />
                        </h3>
                      </div>
                    </Link>
                  </li>
                </ul>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div> */}

      <div className="provideDiv">
        <ul>
          <li>
            <img src={icon1} alt="" />
            <h3>{langJson?.easyreturn}</h3>
          </li>
          <li>
            <img src={icon2} alt="" />
            <h3>{langJson?.fastshipping}</h3>
          </li>
          <li>
            <img src={icon3} alt="" />
            <h3>{langJson?.securepayments}</h3>
          </li>
          <li>
            <img src={icon4} alt="" />
            <h3>{langJson?.friendlysupport}</h3>
          </li>
        </ul>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
