import React, {useState, useEffect} from "react";
import {Link, NavLink, useNavigate} from "react-router-dom";
import $ from "jquery";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";

const icon1 = require("./../assets/img/dashboard-icon.svg").default;
const icon2 = require("./../assets/img/info-icon.svg").default;
const icon3 = require("./../assets/img/app-icon.svg").default;
const icon4 = require("./../assets/img/payment-icon.svg").default;
const icon5 = require("./../assets/img/store-icon.svg").default;
const icon6 = require("./../assets/img/list-icon.svg").default;
const icon7 = require("./../assets/img/order-icon.svg").default;
const icon8 = require("./../assets/img/plan-icon.svg").default;
const icon9 = require("./../assets/img/logout-icon.svg").default;
const deleteIcon = require("./../assets/img/delete-icon.svg").default;

const Sidebar = props => {
  const navigate = useNavigate();
  const {langJson} = useLanguage();
  const {user, setUser} = useAuth();
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [storedUserData, setStoredUserData] = useState();

  const onLogout = () => {
    localStorage.removeItem("FFFUserData");
    navigate("/");
    setUser(null);
  };

  useEffect(() => {
    $(".mobileBtn").click(() => {
      $(".sideBar").toggleClass("active");
    });
    const storedUserData = JSON.parse(localStorage.getItem("FFFUserData"));
    setStoredUserData(storedUserData);
  }, []);

  return (
    <>
      {user?.role == "admin" ? (
        <div className="sidedBar">
          <ul>
            <li>
              <Link to="/admin-dashboard">
                <img src={icon1} alt="" />
                {langJson?.dashboard}
              </Link>
            </li>
            <li>
              <Link to="/admin-cms">
                <img src={icon2} alt="" />
                {langJson?.cms}
              </Link>
            </li>
            <li>
              <Link to="/website-info">
                <img src={icon2} alt="" />
                {langJson?.websiteinformation}
              </Link>
            </li>
            <li>
              <a href="javascript:void(0);" onClick={() => onLogout()}>
                <img src={icon9} alt="" />
                {langJson?.logout}
              </a>
            </li>
          </ul>
        </div>
      ) : (
        <div className="sidedBar">
          <ul>
            <li>
              <Link to="/dashboard">
                <img src={icon1} alt="" />
                {langJson?.dashboard}
              </Link>
            </li>
            <li>
              <Link to="/company-info">
                <img src={icon2} alt="" />
                {langJson?.companyinformation}
              </Link>
            </li>
            <li>
              <Link to="/vendor-change-password">
                <img src={icon2} alt="" />
                {langJson?.changepassword}
              </Link>
            </li>
            <li>
              <Link to="/choose-category">
                <img src={icon3} alt="" />
                {langJson?.choosecategory}
              </Link>
            </li>
            <li>
              <Link to="/requests">
                <img src={icon7} alt="" />
                {langJson?.requests}
              </Link>
            </li>
            <li>
              <Link to="/choose-payment">
                <img src={icon4} alt="" />
                {langJson?.choosepayment}
              </Link>
            </li>
            <li>
              <Link to="/customize-store">
                <img src={icon5} alt="" />
                {langJson?.customizeyourstore}
              </Link>
            </li>
            {/* <li>
              <Link to="/imprint-privacy-policy">
                <img src={icon2} alt="" />
                {langJson?.imprintprivacypolicy}
              </Link>
            </li> */}
            <li>
              <Link to="/products">
                <img src={icon6} alt="" />
                {langJson?.products}
              </Link>
            </li>
            <li>
              <Link to="/orders">
                <img src={icon7} alt="" />
                {langJson?.orders}
              </Link>
            </li>
            <li>
              <Link to="/plans-and-pricing">
                <img src={icon8} alt="" />
                {langJson?.upgradeplan}
              </Link>
            </li>
            <li>
              <Link to="/vendor-events">
                <img src={icon8} alt="" />
                {langJson?.events}
              </Link>
            </li>
            <li>
              <Link to="/vendor-videos">
                <img src={icon8} alt="" />
                {langJson?.videos}
              </Link>
            </li>
            <li>
              <a href="javascript:void(0);" onClick={() => onLogout()}>
                <img src={icon9} alt="" />
                {langJson?.logout}
              </a>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default Sidebar;
