import React, {useState, useEffect} from "react";
import {Navigate, Outlet} from "react-router-dom";

const Auth = ({allowedRoles}) => {
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("FFFUserData")));
  const [userRole] = useState(JSON.parse(localStorage.getItem("FFFUserData")) ? JSON.parse(localStorage.getItem("FFFUserData"))?.role : null);

  return allowedRoles.find(role => role.includes(userRole)) ? (
    <Outlet />
  ) : userRole === "vendor" ? (
    <Navigate to={"/dashboard"} replace />
  ) : 
  userRole === "admin" ? (
    <Navigate to={"/admin-dashboard"} replace />
  ):(
    <Navigate to={"/"} replace />
  );
};

export default Auth;
