import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL, IMAGE_URL} from "../utils/app_url";
import {useAuth} from "../context/AuthContext";
import {useLanguage} from "../context/LanguageContext";
import {NotificationManager} from "react-notifications";

const productImg = require("./../assets/img/product1-min.png");
const deleteIcon = require("./../assets/img/delete-icon2.svg").default;
const plusIcon = require("./../assets/img/plus-icon.svg").default;
const minusIcon = require("./../assets/img/minus-icon.svg").default;

const Cart = () => {
  const {user, setCartCount} = useAuth();
  const {selectedLanguage, langJson} = useLanguage();
  const navigate = useNavigate();

  const [cartDetails, setCartDetails] = useState();
  const [loading, setLoading] = useState(false);

  // const QuantitySelector = ({index}) => {
  const [quantity, setQuantity] = useState(1);

  const handleIncrement = async (mainItem, mainIndex, subItem, subIndex) => {
    setQuantity(quantity + 1);
    let tempArray = [...cartDetails];
    if (tempArray[mainIndex].products[subIndex].quantity > 0) {
      tempArray[mainIndex].products[subIndex].quantity = Number(tempArray[mainIndex].products[subIndex].quantity) + 1;
      tempArray[mainIndex].products[subIndex].amount =
        Number(tempArray[mainIndex].products[subIndex].amount) + Number(tempArray[mainIndex].products[subIndex].price);

      tempArray[mainIndex].total = Number(tempArray[mainIndex].total) + Number(Number(tempArray[mainIndex].products[subIndex].price));
      tempArray[mainIndex].sub_total = Number(tempArray[mainIndex].sub_total) + Number(Number(tempArray[mainIndex].products[subIndex].price));
    }
    setCartDetails(tempArray);
    try {
      const response = await fetch(`${API_BASE_URL}cart/update/${subItem.id}`, {
        method: "PUT",
        body: JSON.stringify({
          // id: subItem.id,
          quantity: subItem.quantity,
          lang: selectedLanguage,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
      } else {
      }
    } catch (error) {}
  };

  const handleDecrement = async (mainItem, mainIndex, subItem, subIndex) => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }

    let tempArray = [...cartDetails];
    if (tempArray[mainIndex].products[subIndex].quantity > 1) {
      tempArray[mainIndex].products[subIndex].quantity = Number(tempArray[mainIndex].products[subIndex].quantity) - 1;
      tempArray[mainIndex].products[subIndex].amount =
        Number(tempArray[mainIndex].products[subIndex].amount) - Number(tempArray[mainIndex].products[subIndex].price);

      tempArray[mainIndex].total = Number(tempArray[mainIndex].total) - Number(Number(tempArray[mainIndex].products[subIndex].price));
      tempArray[mainIndex].sub_total = Number(tempArray[mainIndex].sub_total) - Number(Number(tempArray[mainIndex].products[subIndex].price));
    }
    setCartDetails(tempArray);
    try {
      const response = await fetch(`${API_BASE_URL}cart/update/${subItem.id}`, {
        method: "PUT",
        body: JSON.stringify({
          // id: subItem.id,
          quantity: subItem.quantity,
          lang: selectedLanguage,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        NotificationManager.success(responseData.msg);
      } else {
        NotificationManager.error(responseData.msg);
      }
    } catch (error) {
      NotificationManager.error("Something went wrong in server. Please try again.");
    }
  };

  //   return (
  //     <div className="quantity-selector">
  //       <button onClick={handleDecrement}>-</button>
  //       <span>{quantity}</span>
  //       <button onClick={handleIncrement}>+</button>
  //     </div>
  //   );
  // };

  const fetchCart = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}frontend/cart`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setCartDetails(responseData?.data);
      } else {
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteCart = async (mainIndex, subIndex, cartId) => {
    let tempArray = [...cartDetails];
    tempArray[mainIndex].total = Number(tempArray[mainIndex].total) - Number(tempArray[mainIndex].products[subIndex]?.amount);
    tempArray[mainIndex].sub_total = Number(tempArray[mainIndex].sub_total) - Number(Number(tempArray[mainIndex].products[subIndex]?.amount));
    tempArray[mainIndex].products.splice(subIndex, 1);
    if (tempArray[mainIndex].products.length === 0) {
      tempArray.splice(mainIndex, 1);
    }
    setCartDetails(tempArray);
    setCartCount(prev => prev - 1);

    try {
      const response = await fetch(`${API_BASE_URL}cart/remove/${cartId}`, {
        method: "POST",
        body: JSON.stringify({
          // CartId: cartId,
          lang: selectedLanguage,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
      } else {
      }
    } catch (error) {}
  };

  const onPlaceOrder = storeId => {
    navigate("/address");
    sessionStorage.setItem("FFFStoreId", storeId);
  };

  useEffect(() => {
    if (user) {
      fetchCart();
      sessionStorage.removeItem("FFFShippingId");
      sessionStorage.removeItem("FFFStoreId");
    }
  }, []);

  return (
    <div>
      <Header />
      <style>{`body { background: #F5F7FA; }`}</style>

      <div className="mainDiv">
        <div className="mainDiv cartMain">
          <div className="hdnSec">
            <h2>{langJson?.cart}</h2>
          </div>
          {!user ? (
            <div className="cartDiv">
              <h6>{langJson?.noitemsfound}</h6>
            </div>
          ) : cartDetails?.length > 0 ? (
            cartDetails?.map((item, index) => (
              <div className="cartDiv" key={index}>
                <h3>{item?.store?.company_name}</h3>

                {item?.products.map((chItem, chIndex) => (
                  <ul className="cartTable" key={chIndex}>
                    <li>
                      <img src={IMAGE_URL + chItem?.product?.image} alt="" className="cartPrdt" />
                    </li>
                    <li>
                      <h3>{chItem?.product?.title}</h3>
                    </li>
                    <li>
                      <h4>${chItem?.price}</h4>
                    </li>
                    <li>
                      <h5>
                        {langJson?.size}: {chItem?.size}
                      </h5>
                    </li>

                    <li>
                      {/* <QuantitySelector index={0} /> */}
                      <div className="quantity-selector">
                        <button onClick={() => handleDecrement(item, index, chItem, chIndex)}>-</button>
                        <span>{Number(chItem?.quantity)}</span>
                        <button onClick={() => handleIncrement(item, index, chItem, chIndex)}>+</button>
                      </div>
                    </li>
                    <li onClick={() => deleteCart(index, chIndex, chItem.id)} style={{cursor: "pointer"}}>
                      <img src={deleteIcon} alt="" />
                    </li>
                  </ul>
                ))}

                <ul className="totalDiv">
                  <li className="PriceTtl">
                    <label>{langJson?.subtotal}:</label>
                    <span>${item?.sub_total ? item?.sub_total.toFixed(2) : 0}</span>
                  </li>
                  <li className="PriceTtl">
                    <label>
                      {langJson?.tax} ({item?.tax ? item?.tax : "0"}%) :
                    </label>
                    <span>${item?.tax_total ? item?.tax_total : 0}</span>
                  </li>
                  <li className="PriceTtl">
                    <label>{langJson?.total}:</label>
                    <span>${(parseFloat(item?.sub_total) + parseFloat(item?.tax_total)).toFixed(2)}</span>
                    {/* <span>${item?.total ? parseInt(item?.sub_total) + parseInt(item?.tax_total) : 0}</span> */}
                  </li>
                  <li>
                    {item?.store?.payment_terms ? (
                      <p>
                        {langJson?.paymentterms}*: {item?.store?.payment_terms}
                      </p>
                    ) : null}
                    {item?.store?.shipping_terms ? (
                      <p>
                        {langJson?.shippingterms}*: {item?.store?.shipping_terms}
                      </p>
                    ) : null}
                  </li>
                  <li>
                    <button className="orderBtn" onClick={() => onPlaceOrder(item?.store_id)}>
                      {langJson?.placeorder}
                    </button>
                    <Link to="/shop" className="addBtn">
                      {langJson?.addshopping}
                    </Link>
                  </li>
                </ul>
              </div>
            ))
          ) : !loading ? (
            <>
              <div className="cartDiv">
                <h6>{langJson?.noitemsfound}</h6>
              </div>
            </>
          ) : null}
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Cart;
