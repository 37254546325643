import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";

const wishImg = require("./../assets/img/wishlist-icon2.svg").default;
const icon1 = require("./../assets/img/about-icon1.svg").default;
const icon2 = require("./../assets/img/about-icon2.svg").default;
const icon3 = require("./../assets/img/about-icon3.svg").default;
const icon4 = require("./../assets/img/about-icon4.svg").default;
const icon5 = require("./../assets/img/about-icon5.svg").default;
const icon6 = require("./../assets/img/about-icon6.svg").default;
const icon7 = require("./../assets/img/about-icon7.svg").default;
const icon8 = require("./../assets/img/about-icon8.svg").default;

const About = () => {
  const {selectedLanguage, langJson} = useLanguage();
  // const {CategoryName, SubcategoryName} = useParams();
  const {slugName} = useParams();

  const [allDetails, setAllDetails] = useState();

  const fetchPrivacyPolicy = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}frontend/about-us`, {
        method: "GET",
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        // setAllDetails(responseData.data);
        let desc = responseData.data.description.replace(/\\+"/g, '"');
        setAllDetails(desc);
      } else {
      }
    } catch (error) {
      console.error("Error during Sign UP:", error);
    }
  };

  useEffect(() => {
    fetchPrivacyPolicy();
  }, []);

  return (
    <div>
      <Header />
      <style>{`body { background: #F5F7FA; }`}</style>

      <div className="fairDiv">
        <div className="fairBanner aboutBanner">
          <h3>-Fair for Fair {langJson?.team}-</h3>
          <h2>Your Showcase In The World</h2>
        </div>
        <div className="mainDiv">
          <div className="aboutDiv">
            <p style={{whiteSpace: "pre-line"}} dangerouslySetInnerHTML={{__html: allDetails}}></p>
            {/* <p style={{whiteSpace: "pre-line"}} dangerouslySetInnerHTML={{__html: allDetails?.description}}></p> */}
            {/* <h3>FFF Story</h3>
            <h2>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
              veniam, quis nostrud exercitation ill upet ullam co laboris nisi ut aliquip ex eartis commodo consequat. Duis aute irure dolor in reprehenderit in
              voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            </p>
            <p>
              There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or
              randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't
              anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary,
              making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence
              structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour,
              or non-characteristic words etc.
            </p> */}
          </div>

          {/* <div className="whatDiv">
            <h2>{langJson?.whatdowedo}</h2>
            <ul>
              <li>
                <img src={icon1} alt="" />
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              </li>
              <li>
                <img src={icon2} alt="" />
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              </li>
              <li>
                <img src={icon3} alt="" />
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              </li>
              <li>
                <img src={icon4} alt="" />
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              </li>
              <li>
                <img src={icon5} alt="" />
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              </li>
              <li>
                <img src={icon6} alt="" />
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              </li>
              <li>
                <img src={icon7} alt="" />
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              </li>
              <li>
                <img src={icon8} alt="" />
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
              </li>
            </ul>
          </div> */}
        </div>
        {/* <div className="teambanner">
          <div className="mainDiv">
            <h2>{langJson?.ourteam}</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
              veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
              velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim
              id est laborum. If you use this site regularly and would like to help keep the site on the Internet, please consider donating a small sum to help
              pay for the hosting and bandwidth bill. There is no minimum donation, any sum is appreciated - click here to donate using PayPal. Thank you for
              your support
            </p>
          </div>
        </div> */}
      </div>

      <Footer />
    </div>
  );
};

export default About;
