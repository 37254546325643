import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {PayPalScriptProvider, PayPalButtons, FUNDING} from "@paypal/react-paypal-js";
import {API_BASE_URL} from "../utils/app_url";
import {useAuth} from "../context/AuthContext";

const PayPalPayment = props => {
  const {storeId, shippingId, paymentMethod, productPrice} = props;
  const {user} = useAuth();
  const navigate = useNavigate();

  const paypalOptions = {
    // "client-id": "AVPlsa_kigpllpcek2cHJEOLrTl7q9BkX0dgVLgIs-TOac7AxdA32Qwwq0uemIQ5ZA9XH4YtrmmWmbgf",
    // "client-id": "AWuJAMxWf5AnZIixrep5cS8QPLiaBT7TH3J8r_vAQoKDF4vRaflxgoduxwGD28sum7q9W1nB4CG_SUxI",
    "client-id": props?.paypalCreds?.client_id,
    currency: "USD",
  };

  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              value: productPrice,
            },
          },
        ],
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then(orderID => {
        return orderID;
      });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      paymentIntregation(details);
    });
  };

  const paymentIntregation = async data => {
    try {
      const response = await fetch(`${API_BASE_URL}order`, {
        method: "POST",
        body: JSON.stringify({
          store_id: storeId,
          shipping_id: shippingId,
          payment_method: paymentMethod,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        navigate("/payment-success");
        sessionStorage.removeItem("FFFShippingId");
        sessionStorage.removeItem("FFFStoreId");
      } else {
        console.error("Payment failed");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <PayPalScriptProvider options={paypalOptions}>
      <PayPalButtons
        style={{
          layout: "horizontal",
          color: "white",
          shape: "rect",
        }}
        fundingSource={FUNDING.PAYPAL}
        createOrder={createOrder}
        onApprove={onApprove}
        label="Custom Label"
      />
    </PayPalScriptProvider>
  );
};

export default PayPalPayment;
