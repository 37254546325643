import React, {useState, useEffect} from "react";
import Auth from "./ProtectedRoutes/Auth";
import NotLogInAuth from "./ProtectedRoutes/NotLogInAuth";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
//import { Routes, Route } from "react-router-dom";
import {Route, Routes, Navigate} from "react-router-dom";
import Home from "./pages/home";
import Register from "./pages/register";
import ProductListing from "./pages/product-listing";
import ProductDetails from "./pages/product-details";
import Cart from "./pages/cart";
import Address from "./pages/address";
import AddAddress from "./pages/add-address";
import EditAddress from "./pages/edit-address";
import PaymentSuccess from "./pages/payment-success";
import MyAccount from "./pages/my-account";
import EditProfile from "./pages/edit-profile";
import Wishlist from "./pages/wishlist";
import Store from "./pages/store-followed";
import VendorRegister from "./pages/vendor-register";
import ShopCreation from "./pages/shop-creation";
import EditProduct from "./pages/edit-product";
import CompanyInfo from "./pages/company-info";
import AddProduct from "./pages/add-product";
import VendorProductListing from "./pages/vendor-products-listing";
import VendorDashboard from "./pages/vendor-dashboard";
import CustomizeStore from "./pages/customize-store";
import PlansPricing from "./pages/plans-and-pricing";
import EventListing from "./pages/event-listing";
import EventDetails from "./pages/event-details";
import Payment from "./pages/payment";
import ShippingAddress from "./pages/shipping-address";
import MyOrders from "./pages/my-orders";
import FairTrade from "./pages/fair-trade";
import "react-notifications/lib/notifications.css";
import {NotificationContainer} from "react-notifications";
import About from "./pages/about";
import Videos from "./pages/videos";
import FairTradeDetails from "./pages/fair-trade-details";
import FairTradeProducts from "./pages/fair-trade-products";
import VendorOrderListing from "./pages/vendor-orders-listing";
import ImprintPrivacyPolicy from "./pages/imprint-privacy-policy";
import OrderDetails from "./pages/order-details";
import VendorEventListing from "./pages/vendor-event-listing";
import AddEvents from "./pages/add-events";
import VendorVideoListing from "./pages/vendor-video-listing";
import AddVideos from "./pages/add-videos";
import EditEvents from "./pages/edit-events";
import EditVideos from "./pages/edit-videos";
import VideoDetails from "./pages/video-details";
import ChoosePayment from "./pages/choose-payment";
import SearchProducts from "./pages/search-product-listing";
import PrivacyPolicy from "./pages/privacy-policy";
import ChangePassword from "./pages/change-password";
import VendorRequestPriceListing from "./pages/vendor-request-price-listing";
import ChooseCategory from "./pages/choose-category";
import AboutUs from "./pages/Admin/admin-about-us";
import AdminDashboard from "./pages/Admin/admin-dashboard";
import AdminAboutUs from "./pages/Admin/admin-about-us";
import FairTradeEvents from "./pages/Admin/fair-trade-events";
import FairTradeAboutUs from "./pages/Admin/fair-trade-about-us";
import FairTradeVideos from "./pages/Admin/fair-trade-videos";
import FairTradePrivacyPolicy from "./pages/Admin/fair-trade-privacy-policy";
import FairTradeContactUs from "./pages/Admin/fair-trade-contact-us";
import FairTradeSubCategory from "./pages/fair-trade-sub-category";
import Support from "./pages/support";
import VendorChangePassword from "./pages/vendor-change-password";
import ResetPassword from "./pages/reset-password";
import VendorWebsiteInfo from "./pages/Admin/vendor-website-info";
import PlanSubscription from "./pages/plan-subscription";
import TermsOfUse from "./pages/terms-of-use";
import VerifyEmailAddress from "./pages/verify-email-address";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/vendor-register" element={<VendorRegister />} />
        {/* <Route path="/product-listing/:SubcategoryId" element={<ProductListing />} /> */}
        {/* <Route path="/shop/:CategoryName/:SubcategoryName" element={<ProductListing />} /> */}
        <Route path="/shop/:slugName" element={<ProductListing />} />
        <Route path="/shop" element={<ProductListing />} />
        <Route path="/product/:CatalogId" element={<ProductDetails />} />
        <Route path="/shop-creation" element={<ShopCreation />} />
        <Route path="/search/:searchKey" element={<SearchProducts />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/verify-email/:token" element={<VerifyEmailAddress />} />

        {/* Customer allowed routes */}
        <Route element={<Auth allowedRoles={["customer"]} />}>
          <Route path="/address" element={<Address />} />
          <Route path="/add-address" element={<AddAddress />} />
          <Route path="/edit-address/:id" element={<EditAddress />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/my-account" element={<MyAccount />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/store-followed" element={<Store />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/shipping-address" element={<ShippingAddress />} />
          <Route path="/my-orders" element={<MyOrders />} />
        </Route>

        {/* Vendor allowed routes */}
        <Route element={<Auth allowedRoles={["vendor"]} />}>
          <Route path="/add-product" element={<AddProduct />} />
          <Route path="/edit-product/:id" element={<EditProduct />} />
          <Route path="/company-info" element={<CompanyInfo />} />
          <Route path="/products" element={<VendorProductListing />} />
          <Route path="/dashboard" element={<VendorDashboard />} />
          <Route path="/customize-store" element={<CustomizeStore />} />
          <Route path="/plans-and-pricing" element={<PlansPricing />} />
          <Route path="/plan-subscription/:id" element={<PlanSubscription />} />
          <Route path="/orders" element={<VendorOrderListing />} />
          <Route path="/order/:orderId" element={<OrderDetails />} />
          <Route path="/imprint-privacy-policy" element={<ImprintPrivacyPolicy />} />
          <Route path="/vendor-events" element={<VendorEventListing />} />
          <Route path="/vendor-videos" element={<VendorVideoListing />} />
          <Route path="/add-events" element={<AddEvents />} />
          <Route path="/edit-events/:id" element={<EditEvents />} />
          <Route path="/add-videos" element={<AddVideos />} />
          <Route path="/edit-videos/:id" element={<EditVideos />} />
          <Route path="/choose-payment" element={<ChoosePayment />} />
          <Route path="/requests" element={<VendorRequestPriceListing />} />
          <Route path="/choose-category" element={<ChooseCategory />} />
          <Route path="/vendor-change-password" element={<VendorChangePassword />} />
        </Route>

        
          {/* Admin allowed routes */}
          <Route element={<Auth allowedRoles={["admin"]} />}>
            <Route path="/admin-dashboard" element={<AdminDashboard />} />
            <Route path="/admin-cms" element={<AdminAboutUs />} />
            <Route path="/website-info" element={<VendorWebsiteInfo />} />
          </Route>

        <Route path="/cart" element={<Cart />} />
        <Route path="/events" element={<EventListing />} />
        <Route path="/event/:eventId" element={<EventDetails />} />
        <Route path="/store" element={<FairTrade />} />
        <Route path="/store/:storeId" element={<FairTradeDetails />} />
        <Route path="/store/:storeId/:mainCat" element={<FairTradeSubCategory />} />
        <Route path="/store/:storeId/products/:proCatId" element={<FairTradeProducts />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/videos" element={<Videos />} />
        <Route path="/video/:url" element={<VideoDetails />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/support" element={<Support />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />

        <Route path="/store/:storeId/events" element={<FairTradeEvents/>} />
        <Route path="/store/:storeId/about-us" element={<FairTradeAboutUs />} />
        <Route path="/store/:storeId/videos" element={<FairTradeVideos />} />
        <Route path="/store/:storeId/imprint-privacy-policy" element={<FairTradePrivacyPolicy />} />
        <Route path="/store/:storeId/contact-us" element={<FairTradeContactUs />} />

        {/* <Route path="/store/:storeId/:name" element={<AdminFairTradeDetails />} /> */}

      </Routes>

      <NotificationContainer />
    </div>
  );
}

export default App;
