import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {useAuth} from "../context/AuthContext";
import {useLanguage} from "../context/LanguageContext";
import {API_BASE_URL} from "../utils/app_url";

const MyOrders = () => {
  const {user, setUser} = useAuth();
  const {selectedLanguage, langJson} = useLanguage();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [allDetails, setAllDetails] = useState();
  const [selectedStore, setSelectedStore] = useState(0);

  const onLogout = () => {
    localStorage.removeItem("FFFUserData");
    navigate("/");
    setUser(null);
  };

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}order`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.authorisation?.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setAllDetails(responseData.data);
      } else {
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <div>
      <Header />
      <style>{`body { background: #F5F7FA; }`}</style>

      <div className="mainDiv">
        <div className="accountLink">
          <ul>
            <li>
              <Link to="/my-account">{langJson?.myaccount}</Link>
            </li>
            <li className="active">
              <Link to="/my-orders">{langJson?.myorders}</Link>
            </li>
            <li>
              <Link to="/wishlist">{langJson?.wishlist}</Link>
            </li>
            <li>
              <Link to="/store-followed">{langJson?.storefollowed}</Link>
            </li>
            <li>
              <Link to="/shipping-address">{langJson?.shippingaddress}</Link>
            </li>
            <li>
              <a href="javascript:void(0);" onClick={() => onLogout()}>
                {langJson?.logout}
              </a>
            </li>
          </ul>
        </div>
        <div className="acctHdn">
          <div className="hdnSec">
            <h2>{langJson?.myorders}</h2>
          </div>

          {allDetails?.length > 0 ? (
            <div className="myaccountDiv orderDiv">
              <ul className="tableHdn">
                <li>{langJson?.storename}</li>
                <li>{langJson?.orderno}</li>
                <li>{langJson?.orderdate}</li>
                <li>{langJson?.billtoname}</li>
                <li>{langJson?.total}</li>
                <li>{langJson?.status}</li>
                <li className="actionHdn">{langJson?.action}</li>
              </ul>
              {allDetails?.map((item, index) => (
                <div className={selectedStore === index ? `orderMain orderMainActive` : `orderMain`} key={index}>
                  <ul className="tableDesc">
                    <li>{item?.store?.company_name}</li>
                    <li>{item?.order?.order_number}</li>
                    <li>{item?.order?.ordered_date}</li>
                    <li>{item?.order?.name}</li>
                    <li>${item?.order?.total_amount}</li>
                    <li>{item?.status}</li>
                    <li onClick={() => setSelectedStore(selectedStore === index ? null : index)} style={{cursor: "pointer"}}>
                      {langJson?.viewdetails}
                    </li>
                  </ul>
                  {selectedStore === index ? (
                    <>
                      <ul className="tableDesc">
                        <li>{langJson?.No}.</li>
                        <li>{langJson?.item}</li>
                        <li>{langJson?.size}</li>
                        <li>{langJson?.price}($)</li>
                        <li>{langJson?.qty}</li>
                        <li></li>
                        <li></li>
                      </ul>
                      {item?.products?.map((chItem, chIndex) => (
                        <ul className="tableDesc" key={chIndex}>
                          <li>{chIndex + 1 < 10 ? "0" + (chIndex + 1) : chIndex + 1}</li>
                          <li>{chItem?.product?.title}</li>
                          <li>{chItem?.size}</li>
                          <li>{chItem?.price}</li>
                          <li className="qty">{chItem?.quantity}</li>
                        </ul>
                      ))}
                      <div className="buttonList">
                        <div className="buttonBox">
                          {langJson?.totalquantity}
                          <strong>
                            {item?.order?.quantity} {langJson?.pcs}
                          </strong>
                        </div>
                        <div className="buttonBox">
                          {langJson?.totalprice}
                          <strong>${item?.order?.total_amount}</strong>
                        </div>
                      </div>
                      {/* <ul className="tableDesc">
                        <li>01</li>
                        <li>Brettlbuac</li>
                        <li>38(S)</li>
                        <li>140.00</li>
                        <li className="qty">7</li>
                        <li className="buttonList">
                          <div className="buttonBox">
                            {langJson?.totalquantity}
                            <strong>38 {langJson?.pcs}</strong>
                          </div>
                          <div className="buttonBox">
                            {langJson?.totalprice}
                            <strong>$38</strong>
                          </div>
                        </li>
                      </ul> */}
                    </>
                  ) : null}
                </div>
              ))}
            </div>
          ) : !loading ? (
            <div className="myaccountDiv orderDiv">
              <div className="noOrderDiv">{langJson?.noordersfound}</div>
            </div>
          ) : null}
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default MyOrders;
