import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";
import Sidebar from "../component/Sidebar";

const icon1 = require("./../assets/img/dashboard-icon.svg").default;
const icon2 = require("./../assets/img/info-icon.svg").default;
const icon3 = require("./../assets/img/app-icon.svg").default;
const icon4 = require("./../assets/img/payment-icon.svg").default;
const icon5 = require("./../assets/img/store-icon.svg").default;
const icon6 = require("./../assets/img/list-icon.svg").default;
const icon7 = require("./../assets/img/order-icon.svg").default;
const icon8 = require("./../assets/img/plan-icon.svg").default;
const icon9 = require("./../assets/img/logout-icon.svg").default;
const deleteIcon = require("./../assets/img/delete-icon.svg").default;
const passwordIcon = require("./../assets/img/password-icon.svg").default;

const VendorChangePassword = () => {
  const navigate = useNavigate();
  const {user, setUser} = useAuth();
  const {langJson} = useLanguage();

  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [signupStatus, setSignupStatus] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = event => {
    const {name, value} = event.target;

    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(prev => !prev);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(prev => !prev);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(prev => !prev);
  };

  const handleSubmit = async () => {
    if (!formData.oldPassword) {
      setSignupStatus(langJson?.oldpasswordisrequired);
    } else if (!formData.newPassword) {
      setSignupStatus(langJson?.newpasswordisrequired);
    } else if (!/(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(formData.newPassword)) {
      setSignupStatus(`${langJson?.yourpasswordisnotvalid} (Ex- Abc@1234)`);
    } else if (!formData.confirmPassword) {
      setSignupStatus(langJson?.confirmpasswordisrequired);
    } else if (formData.newPassword !== formData.confirmPassword) {
      setSignupStatus(langJson?.passwordconfirmpasswordshouldbesame);
    } else {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_URL}users/change-password`, {
          method: "POST",
          body: JSON.stringify({
            new_password: formData.newPassword,
            old_password: formData.oldPassword,
            new_password_confirmation: formData.confirmPassword,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.authorisation.token}`,
          },
        });
        const responseData = await response.json();
        if (responseData.res === true) {
          setSignupStatus(responseData.msg);
          setLoading(false);
          setFormData({
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
        } else {
          setSignupStatus(responseData.msg);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error during Sign UP:", error);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <div className="dashHeader">
        <Header />
      </div>
      <div className="adminDash">
        <Sidebar />

        <div className="rightSide">
          <h2 style={{textTransform: "uppercase"}}>{langJson?.changepassword}</h2>
          <ul className="addAddress">
            <li>
              <label>{langJson?.oldpassword}</label>
              <input
                type={showOldPassword ? "text" : "password"}
                name="oldPassword"
                value={formData.oldPassword}
                placeholder={langJson?.oldpassword}
                onChange={handleChange}
              />
              <div className="visibleClick vendor" onClick={toggleOldPasswordVisibility}>
                <img src={passwordIcon} alt="Toggle Password Visibility" />
              </div>
            </li>
            <li>
              <label>{langJson?.newpassword}</label>
              <input
                type={showNewPassword ? "text" : "password"}
                name="newPassword"
                value={formData.newPassword}
                placeholder={langJson?.newpassword}
                onChange={handleChange}
              />
              <div className="visibleClick vendor" onClick={toggleNewPasswordVisibility}>
                <img src={passwordIcon} alt="Toggle Password Visibility" />
              </div>
            </li>
            <li>
              <label>{langJson?.confirmpassword}</label>
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                value={formData.confirmPassword}
                placeholder={langJson?.confirmpassword}
                onChange={handleChange}
              />
              <div className="visibleClick vendor" onClick={toggleConfirmPasswordVisibility}>
                <img src={passwordIcon} alt="Toggle Password Visibility" />
              </div>
            </li>
            <li className="fullWidth saveBtn">
              <button type="submit" disabled={loading} onClick={() => handleSubmit()}>
                {loading ? langJson?.pleasewait : langJson?.savechanges}
              </button>
            </li>
            <span className="lgnStatus">{signupStatus}</span>
          </ul>
        </div>
      </div>
      <div className="dashFooter">
        <h3>
          {langJson?.copyright} © Fair for Fair {new Date().getFullYear()}
        </h3>
      </div>
      <div className="footInner">
        <Footer />
      </div>
    </div>
  );
};

export default VendorChangePassword;
