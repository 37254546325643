import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";

const wishImg = require("./../assets/img/wishlist-icon2.svg").default;
const icon1 = require("./../assets/img/about-icon1.svg").default;
const icon2 = require("./../assets/img/about-icon2.svg").default;
const icon3 = require("./../assets/img/about-icon3.svg").default;
const icon4 = require("./../assets/img/about-icon4.svg").default;
const icon5 = require("./../assets/img/about-icon5.svg").default;
const icon6 = require("./../assets/img/about-icon6.svg").default;
const icon7 = require("./../assets/img/about-icon7.svg").default;
const icon8 = require("./../assets/img/about-icon8.svg").default;

const Support = () => {
  const {selectedLanguage, langJson} = useLanguage();
  const [allDetails, setAllDetails] = useState();

  const fetchPrivacyPolicy = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}frontend/support`, {
        method: "GET",
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        // setAllDetails(responseData.data);
        let desc = responseData.data.description.replace(/\\+"/g, '"');
        setAllDetails(desc);
      } else {
      }
    } catch (error) {
      console.error("Error during Sign UP:", error);
    }
  };

  useEffect(() => {
    fetchPrivacyPolicy();
  }, []);

  return (
    <div>
      <Header />
      <style>{`body { background: #F5F7FA; }`}</style>

      <div className="fairDiv">
        <div className="mainDiv">
          <div className="aboutDiv">
            <h2>{langJson?.support}</h2>
            <p dangerouslySetInnerHTML={{__html: allDetails}}></p>
            {/* <p dangerouslySetInnerHTML={{__html: allDetails?.description}}></p> */}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Support;
