import React, {useEffect, useState} from "react";
import {Link, useNavigate, NavLink, useLocation, useParams} from "react-router-dom";
import $ from "jquery";
import {API_BASE_URL} from "../utils/app_url";
import {useAuth} from "../context/AuthContext";
import {useLanguage} from "../context/LanguageContext";
import {NotificationManager} from "react-notifications";

const cross = require("./../assets/img/cross-icon.svg").default;
const passwordIcon = require("./../assets/img/password-icon.svg").default;
const tickIcon = require("./../assets/img/tick.svg").default;

const VerifyEmailAddress = () => {
  const {user, setUser, cartCount, setCartCount, searchTerm, setSearchTerm} = useAuth();
  const {selectedLanguage, changeLanguage, langJson} = useLanguage();
  const navigate = useNavigate();
  const {token} = useParams();
  const location = useLocation();
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loginStatus, setLoginStatus] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = event => {
    const {name, value} = event.target;

    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(prev => !prev);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(prev => !prev);
  };

  const handleVerifyEmail = async e => {
    setLoading(true);
    const fd = new FormData();
    fd.append("token", token);
    try {
      const response = await fetch(`${API_BASE_URL}users/verify-email`, {
        method: "POST",
        body: fd,
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setLoginStatus(responseData.msg);
        setLoading(false);
      } else {
        setLoginStatus(responseData.msg);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error during Sign UP:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleVerifyEmail();
  }, []);

  return (
    <div className="loginPopup active">
      <div className="loginInner">
        {loading ? (
          <>
            <div className="veifyBody">
              <h4>{langJson?.pleasewait}</h4>
            </div>
          </>
        ) : (
          <>
            <div className="verifyEmail">
              <div className="imageSec">
                <img src={tickIcon} alt="Toggle Password Visibility" />
              </div>
            </div>
            <div className="veifyBody">
              <h2>{langJson?.verified}!</h2>
              <h4>{langJson?.youhavesuccessfullyverifiedyouraccount}</h4>
              <button type="button" onClick={() => navigate("/")}>
                {langJson?.continue}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default VerifyEmailAddress;
