import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";
import Sidebar from "../component/Sidebar";

const icon1 = require("./../assets/img/dashboard-icon.svg").default;
const icon2 = require("./../assets/img/info-icon.svg").default;
const icon3 = require("./../assets/img/app-icon.svg").default;
const icon4 = require("./../assets/img/payment-icon.svg").default;
const icon5 = require("./../assets/img/store-icon.svg").default;
const icon6 = require("./../assets/img/list-icon.svg").default;
const icon7 = require("./../assets/img/order-icon.svg").default;
const icon8 = require("./../assets/img/plan-icon.svg").default;
const icon9 = require("./../assets/img/logout-icon.svg").default;
const deleteIcon = require("./../assets/img/delete-icon.svg").default;

const EditVideos = () => {
  const {user} = useAuth();
  const navigate = useNavigate();
  const {id} = useParams();
  const {selectedLanguage, langJson} = useLanguage();
  const imageBtnRef = useRef();
  const coverImageBtnRef = useRef();

  const [addFormData, setAddFormData] = useState({
    title: null,
    url: null,
  });
  const [signupStatus, setSignupStatus] = useState("");
  const [uploadFrontImage, setUploadFrontImage] = useState();
  const [imageError, setImageError] = useState();
  const [loading, setLoading] = useState();
  const [addBtnDis, setAddBtnDis] = useState();
  const [images, setImages] = useState([]);
  const [allDetails, setAllDetails] = useState();

  const handleChange = event => {
    const {name, value} = event.target;

    setAddFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchVideoDetails = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}stores/videos/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setAllDetails(responseData.data);
        setAddFormData({
          title: responseData.data.title,
          url: responseData.data.url,
        });
      }
    } catch (error) {
      console.error("Error during Sign UP:", error);
    }
  };

  const onPostArticle = async () => {
    if (addFormData.title === null || addFormData.title === undefined || addFormData.title === "") {
      setSignupStatus(langJson?.titleisrequired);
    } else if (addFormData.url === null || addFormData.url === undefined || addFormData.url === "") {
      setSignupStatus(langJson?.urlisrequired);
    } else {
      setAddBtnDis(true);
      setSignupStatus("");
      const fd = new FormData();
      fd.append("title", addFormData.title);
      fd.append("url", addFormData.url);
      fd.append("lang", selectedLanguage);
      try {
        const response = await fetch(`${API_BASE_URL}stores/videos/${id}`, {
          method: "POST",
          body: fd,
          headers: {
            Authorization: `Bearer ${user.authorisation.token}`,
          },
        });
        const responseData = await response.json();
        if (responseData.res === true) {
          setSignupStatus(responseData.msg);
          setLoading(false);
          setAddBtnDis(false);
        } else {
          setSignupStatus(responseData.msg);
          setLoading(false);
          setAddBtnDis(false);
        }
      } catch (error) {
        console.error("Error during Sign UP:", error);
        setLoading(false);
        setAddBtnDis(false);
      }
    }
  };

  useEffect(() => {
    fetchVideoDetails();
    setAddFormData({
      title: null,
      url: null,
    });
  }, []);

  return (
    <div>
      <div className="dashHeader">
        <Header />
      </div>
      <div className="adminDash">
        <Sidebar />
        <div className="rightSide">
          <h2>
            {langJson?.add} {langJson?.videos}
          </h2>
          <ul className="addAddress">
            <li>
              <label>{langJson?.title}</label>
              <input type="text" name="title" onChange={handleChange} value={addFormData.title} />
            </li>
            <li>
              <label>{langJson?.youtubevideourl}</label>
              <input type="text" name="url" onChange={handleChange} value={addFormData.url} />
            </li>

            <li className="fullWidth saveBtn">
              <button type="button" disabled={addBtnDis} onClick={() => onPostArticle()}>
                {addBtnDis ? langJson?.pleasewait : langJson?.savechanges}
              </button>
            </li>
            <span className="lgnStatus">{signupStatus}</span>
          </ul>
        </div>
      </div>
      <div className="dashFooter">
        <h3>
          {langJson?.copyright} © Fair for Fair {new Date().getFullYear()}
        </h3>
      </div>
      <div className="footInner">
        <Footer />
      </div>
    </div>
  );
};

export default EditVideos;
