import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";
import Sidebar from "../component/Sidebar";
import {NotificationManager} from "react-notifications";

const icon1 = require("./../assets/img/dashboard-icon.svg").default;
const icon2 = require("./../assets/img/info-icon.svg").default;
const icon3 = require("./../assets/img/app-icon.svg").default;
const icon4 = require("./../assets/img/payment-icon.svg").default;
const icon5 = require("./../assets/img/store-icon.svg").default;
const icon6 = require("./../assets/img/list-icon.svg").default;
const icon7 = require("./../assets/img/order-icon.svg").default;
const icon8 = require("./../assets/img/plan-icon.svg").default;
const icon9 = require("./../assets/img/logout-icon.svg").default;
const deleteIcon = require("./../assets/img/delete-icon.svg").default;
const uploadBtn = require("./../assets/img/search-btn.svg").default;
const bulletIcon = require("./../assets/img/bullet.svg").default;

const VendorRequestPriceListing = () => {
  const {user} = useAuth();
  const {selectedLanguage, langJson} = useLanguage();
  const navigate = useNavigate();

  const [loading, setLoading] = useState();
  const [allDetails, setAllDetails] = useState();

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}stores/price-requests`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setAllDetails(responseData?.data);
      } else {
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onStatusChange = async (id, statusValue) => {
    try {
      const response = await fetch(`${API_BASE_URL}stores/price-requests/${id}`, {
        method: "POST",
        body: JSON.stringify({is_granted: statusValue}),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        NotificationManager.success(responseData?.msg);
        let tempArray = [...allDetails];
        let index = tempArray.findIndex(item => parseInt(item?.id) === parseInt(id));
        tempArray[index].is_granted = statusValue;
        setAllDetails(tempArray);
      } else {
        NotificationManager.success(responseData?.msg);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (user) {
      fetchOrders();
    }
  }, []);

  return (
    <div>
      <div className="dashHeader">
        <Header />
      </div>
      <div className="adminDash">
        <Sidebar />

        <div className="rightSide rightTop">
          <h2>{langJson?.requests}</h2>

          <div className="prdtTable">
            <table>
              <tr>
                <th>{langJson?.requestedcompanyname}</th>
                <th>{langJson?.fullname}</th>
                <th>{langJson?.telephone}</th>
                <th>{langJson?.email}</th>
                <th>{langJson?.address}</th>
                <th>{langJson?.action}</th>
              </tr>
              {allDetails?.length > 0 ? (
                allDetails?.map((item, index) => (
                  <tr key={index}>
                    <td>{item?.company_name}</td>
                    <td>{item?.name}</td>
                    <td>{item?.phone}</td>
                    <td>{item?.email}</td>
                    <td>{item?.full_address}</td>
                    <td>
                      {parseInt(item?.is_granted) === 1 ? (
                        <button className="btn declineBtn" onClick={() => onStatusChange(item?.id, 0)}>
                          Decline
                        </button>
                      ) : (
                        <button className="btn acceptBtn" onClick={() => onStatusChange(item?.id, 1)}>
                          Accept
                        </button>
                      )}
                    </td>
                  </tr>
                ))
              ) : !loading ? (
                <tr>
                  <td colSpan={8} style={{textAlign: "center"}}>
                    {langJson?.noresultsfound}
                  </td>
                </tr>
              ) : null}
            </table>
          </div>
        </div>
      </div>
      <div className="dashFooter">
        <h3>
          {langJson?.copyright} © Fair for Fair {new Date().getFullYear()}
        </h3>
      </div>
      <div className="footInner">
        <Footer />
      </div>
    </div>
  );
};

export default VendorRequestPriceListing;
