import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";

const EditAddress = props => {
  const {user} = useAuth();
  const navigate = useNavigate();
  const {selectedLanguage, langJson} = useLanguage();
  const {id} = useParams();

  const [addShipFormData, setAddShipFormData] = useState({
    shipping_name: null,
    shipping_address: null,
    city: null,
    state: null,
    phone: null,
    zipcode: null,
    country: null,
    email: null,
  });
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [signupStatus, setSignupStatus] = useState("");
  const [allDetails, seAllDetails] = useState();

  const handleChange = event => {
    const {name, value, type, checked} = event.target;
    const newValue = type === "checkbox" ? checked : value;

    setAddShipFormData(prevData => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const fetchShippingDetails = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}users/shipping/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        seAllDetails(responseData.data);
        setAddShipFormData({
          shipping_name: responseData.data.name,
          shipping_address: responseData.data.address,
          city: responseData.data.city,
          state: responseData.data.state,
          phone: responseData.data.phone,
          zipcode: responseData.data.zip_code,
          country: responseData.data.country,
          email: responseData.data.email,
        });
      } else {
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onEditShipping = async () => {
    if (addShipFormData.shipping_name === null || addShipFormData.shipping_name === undefined || addShipFormData.shipping_name === "") {
      setSignupStatus(langJson?.shippingnameisrequired);
    } else if (addShipFormData.shipping_address === null || addShipFormData.shipping_address === undefined || addShipFormData.shipping_address === "") {
      setSignupStatus(langJson?.shippingaddressisrequired);
    } else if (addShipFormData.city === null || addShipFormData.city === undefined || addShipFormData.city === "") {
      setSignupStatus(langJson?.cityisrequired);
    } else if (addShipFormData.state === null || addShipFormData.state === undefined || addShipFormData.state === "") {
      setSignupStatus(langJson?.shippingstateisrequired);
    } else if (addShipFormData.phone === null || addShipFormData.phone === undefined || addShipFormData.phone === "") {
      setSignupStatus(langJson?.phoneisrequired);
    } else if (addShipFormData.zipcode === null || addShipFormData.zipcode === undefined || addShipFormData.zipcode === "") {
      setSignupStatus(langJson?.shippingzipcodeisrequired);
    } else if (addShipFormData.country === null || addShipFormData.country === undefined || addShipFormData.country === "") {
      setSignupStatus(langJson?.shippingcountryisrequired);
    } else if (addShipFormData.email && !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(addShipFormData.email)) {
      setSignupStatus(langJson?.emailisnotvalid);
    } else {
      setSignupStatus("");
      setLoadingBtn(true);
      try {
        const response = await fetch(`${API_BASE_URL}users/shipping/${id}`, {
          method: "PUT",
          body: JSON.stringify({
            name: addShipFormData.shipping_name,
            address: addShipFormData.shipping_address,
            city: addShipFormData.city,
            state: addShipFormData.state,
            phone: addShipFormData.phone,
            zip_code: addShipFormData.zipcode,
            country: addShipFormData.country,
            email: addShipFormData.email,
            lang: selectedLanguage,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.authorisation.token}`,
          },
        });
        const responseData = await response.json();
        if (responseData.res === true) {
          setSignupStatus(responseData.msg);
          setAddShipFormData({shipping_name: null, shipping_address: null, city: null, state: null, phone: null, zipcode: null, country: null, email: null});
        } else {
          setSignupStatus(responseData.msg);
        }
        setLoadingBtn(false);
      } catch (error) {
        setLoadingBtn(false);
      }
    }
  };

  useEffect(() => {
    fetchShippingDetails();
  }, []);

  return (
    <div>
      <Header />
      <style>{`body { background: #F5F7FA; }`}</style>

      <div className="mainDiv">
        <div className="mainDiv cartMain addMain">
          <div className="hdnSec">
            <h2>{langJson?.editshippingaddress}</h2>
          </div>
        </div>

        <form>
          <ul className="addAddress">
            <li>
              <input type="text" name="shipping_name" onChange={handleChange} placeholder={langJson?.fullname} value={addShipFormData.shipping_name} />
            </li>
            <li>
              <input
                type="text"
                name="shipping_address"
                onChange={handleChange}
                placeholder={langJson?.shippingaddress}
                value={addShipFormData.shipping_address}
              />
            </li>
            <li>
              <input type="text" name="country" onChange={handleChange} placeholder={langJson?.country} value={addShipFormData.country} />
            </li>
            <li>
              <input type="text" name="state" onChange={handleChange} placeholder={langJson?.state} value={addShipFormData.state} />
            </li>
            <li>
              <input type="text" name="city" onChange={handleChange} placeholder={langJson?.city} value={addShipFormData.city} />
            </li>
            <li>
              <input type="text" name="phone" onChange={handleChange} placeholder={langJson?.phone} value={addShipFormData.phone} />
            </li>
            <li>
              <input type="text" name="zipcode" onChange={handleChange} placeholder={langJson?.zipcode} value={addShipFormData.zipcode} />
            </li>
            <li>
              <input type="text" name="email" onChange={handleChange} placeholder={langJson?.email} value={addShipFormData.email} />
            </li>
            {/* <li>
              <input type="checkbox" />
              {langJson?.saveforfastercheckoutnexttime}
            </li> */}
            <li>
              {loadingBtn ? (
                <button type="button" disabled>
                  {langJson?.pleasewait}
                </button>
              ) : (
                <button type="button" onClick={() => onEditShipping()}>
                  {langJson?.edit} {langJson?.address}
                </button>
              )}
              <span className="lgnStatus">{signupStatus}</span>
            </li>
          </ul>
        </form>

        <Footer />
      </div>
    </div>
  );
};

export default EditAddress;
