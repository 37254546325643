import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL, IMAGE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";

const eventImg = require("./../assets/img/event1.png");
const prevIcon = require("./../assets/img/chev-left.svg").default;
const nextIcon = require("./../assets/img/chev-right.svg").default;

const EventListing = () => {
  const {user} = useAuth();
  const {selectedLanguage, langJson} = useLanguage();
  // const {CategoryName, SubcategoryName} = useParams();
  const {slugName} = useParams();

  const [eventList, setEventList] = useState();
  const [loading, setLoading] = useState();

  const fetchEvents = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}frontend/events`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setEventList(responseData.data.data);
      } else {
      }
    } catch (error) {
      console.error("Error during Sign UP:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <div>
      <Header />
      <style>{`body { background: #F5F7FA; }`}</style>

      <div className="mainDiv">
        <div className="featureDiv productListMain">
          <div className="mainDiv">
            <div className="hdnSec">
              <h2>{langJson?.events}</h2>
            </div>

            <ul className="eventList">
              {eventList?.map((item, index) => (
                <li key={index}>
                  <Link to={`/event/${item?.id}`}>
                    <img src={IMAGE_URL + item?.image} alt="" />
                    <h3>{item?.title}</h3>
                    <p>{item?.formatted_start_date_time}</p>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* <div className="paginationDiv">
          <ul>
            <li>
              <Link to="">
                <img src={prevIcon} alt="" />
              </Link>
            </li>
            <li>
              <Link to="">1</Link>
            </li>
            <li>
              <Link to="">2</Link>
            </li>
            <li>
              <Link to="">3</Link>
            </li>
            <li>
              <Link to="">4</Link>
            </li>
            <li>
              <Link to="">5</Link>
            </li>
            <li>
              <Link to="">
                <img src={nextIcon} alt="" />
              </Link>
            </li>
          </ul>
        </div> */}
      </div>

      <Footer />
    </div>
  );
};

export default EventListing;
