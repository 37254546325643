import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";
import Sidebar from "../component/Sidebar";
import PayPalPayment from "./PayPalPayment";
import {NotificationManager} from "react-notifications";

const icon1 = require("./../assets/img/dashboard-icon.svg").default;
const icon2 = require("./../assets/img/info-icon.svg").default;
const icon3 = require("./../assets/img/app-icon.svg").default;
const icon4 = require("./../assets/img/payment-icon.svg").default;
const icon5 = require("./../assets/img/store-icon.svg").default;
const icon6 = require("./../assets/img/list-icon.svg").default;
const icon7 = require("./../assets/img/order-icon.svg").default;
const icon8 = require("./../assets/img/plan-icon.svg").default;
const icon9 = require("./../assets/img/logout-icon.svg").default;
const deleteIcon = require("./../assets/img/delete-icon.svg").default;

const PlanSubscription = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const {user, setUser} = useAuth();
  const {langJson} = useLanguage();

  const [formData, setFormData] = useState({
    email: "",
    company: "",
    firstName: "",
    salutation: "",
    phone: "",
    address: "",
    country: "",
    city: "",
    state: "",
    federalState: "",
    zipCode: "",
    taxID: "",
    paymentTerms: "",
    shippingTerms: "",
    tax: "",
    termsAggreed: true,
    selectedPricing: "",
    couponCode: "",
    selectedPayment: "",
    finaltermsAggreed: true,
    accountHolder: "",
    accountIBAN: "",
    accountBIC: "",
  });
  const [signupStatus, setSignupStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState();

  const handleChange = event => {
    const {name, value} = event.target;

    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    // if (!formData.company) {
    //   setSignupStatus(langJson?.companyisrequired);
    //   // } else if (!formData.salutation) {
    //   //   setSignupStatus(langJson?.salutationisrequired);
    // } else if (!formData.firstName) {
    //   setSignupStatus(langJson?.pleaseenterfirstname);
    // } else if (!formData.address) {
    //   setSignupStatus(langJson?.addressisrequired);
    // } else if (!formData.zipCode) {
    //   setSignupStatus(langJson?.zipcodeisrequired);
    // } else if (!formData.city) {
    //   setSignupStatus(langJson?.cityisrequired);
    // } else if (!formData.state) {
    //   setSignupStatus(langJson?.stateisrequired);
    // } else if (!formData.country) {
    //   setSignupStatus(langJson?.countryisrequired);
    // } else if (!formData.phone) {
    //   setSignupStatus(langJson?.phoneisrequired);
    // } else if (!formData.email) {
    //   setSignupStatus(langJson?.emailisrequired);
    // } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(formData.email)) {
    //   setSignupStatus(langJson?.emailisnotvalid);
    // } else if (!formData.termsAggreed) {
    //   setSignupStatus(langJson?.pleaseacceptcompanytermsandpolicy);
    // } else
    // if (!formData.selectedPricing) {
    //   setSignupStatus(langJson?.pleaseselectapricingplan);
    // } else
    if (!formData.selectedPayment) {
      setSignupStatus(langJson?.pleasechoosePaymentMethod);
    } else if (formData.selectedPayment === 2 && (!formData.accountHolder || !formData.accountIBAN || !formData.accountBIC)) {
      setSignupStatus(langJson?.pleaseenterbankaccountdetails);
    } else {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_URL}stores/update-plan`, {
          method: "POST",
          body: JSON.stringify({
            plan_id: id,
            amount: (parseFloat(selectedPlan?.price) + (parseFloat(selectedPlan?.price) * 19) / 100).toFixed(2),
            payment_method: formData.selectedPayment === 1 ? "PayPal" : "Direct Debit",
            coupon_code: formData.couponCode ? formData.couponCode : "",
            account_holder: formData.accountHolder ? formData.accountHolder : "",
            iban: formData.accountIBAN ? formData.accountIBAN : "",
            bic: formData.accountBIC ? formData.accountBIC : "",
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.authorisation.token}`,
          },
        });
        const responseData = await response.json();
        if (responseData.res === true) {
          setSignupStatus(responseData.msg);
          NotificationManager.success(responseData.msg);
          navigate("/dashboard");
          setLoading(false);
        } else {
          // Authentication failed, display an error message
          setSignupStatus(responseData.msg);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error during Sign UP:", error);
        setLoading(false);
      }
    }
  };

  const fetchPlanDetails = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}frontend/plans`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        let tempSelItem = responseData.data.find(item => parseInt(item?.id) === parseInt(id));
        setSelectedPlan(tempSelItem);
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchPlanDetails();
  }, []);

  return (
    <div>
      <div className="dashHeader">
        <Header />
      </div>
      <div className="adminDash">
        <div className="planMain">
          <div className="plansDiv">
            {/* <h2>{langJson?.plansandpricing}</h2> */}
            <h2>{selectedPlan?.name} Plan</h2>
            <p className="planPrice">EUR {selectedPlan?.price}</p>
          </div>
          <div className="chooseDiv planSub">
            <div className="rightSide">
              <ul className="addAddress">
                {/* <li>
                  <label>{langJson?.emailaddress}:</label>
                  <input type="text" name="email" value={formData.email} readOnly onChange={handleChange} />
                </li>
                <li>
                  <label>{langJson?.companyname}:</label>
                  <input type="text" name="company" value={formData.company} onChange={handleChange} />
                </li>
                <li>
                  <label>{langJson?.salutation}:</label>
                  <select name="salutation" value={formData.salutation} onChange={handleChange}>
                    <option selected hidden>
                      Select
                    </option>
                    <option value={"Mr."}>Mr.</option>
                    <option value={"Mrs."}>Mrs.</option>
                  </select>
                </li>
                <li>
                  <label>{langJson?.fullname}:</label>
                  <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} />
                </li>
                <li>
                  <label>{langJson?.address}:</label>
                  <input type="text" name="address" value={formData.address} onChange={handleChange} />
                </li>
                <li>
                  <label>{langJson?.zipcode}:</label>
                  <input type="text" name="zipCode" value={formData.zipCode} onChange={handleChange} />
                </li>
                <li>
                  <label>{langJson?.city}:</label>
                  <input type="text" name="city" value={formData.city} onChange={handleChange} />
                </li>
                <li>
                  <label>{langJson?.state}:</label>
                  <input type="text" name="state" value={formData.state} onChange={handleChange} />
                </li>
                <li>
                  <label>{langJson?.country}:</label>
                  <input type="text" name="country" value={formData.country} onChange={handleChange} />
                </li>
                <li>
                  <label>{langJson?.phonefaxmobilenumber}:</label>
                  <input type="text" name="phone" value={formData.phone} onChange={handleChange} />
                </li>
                <li className="sizeInput">
                  <label>{langJson?.taxid}:</label>
                  <input type="text" name="taxID" value={formData.taxID} onChange={handleChange} />
                </li>
                <li className="fullWidth checkPlan">
                  <input type="checkbox" checked={formData.termsAggreed} onChange={e => setFormData(prev => ({...prev, termsAggreed: e.target.checked}))} />{" "}
                  {langJson?.iagreedtermsinplan}
                </li> */}
                {/* <li className="fullWidth algnLf">
                  <h3>Pricing</h3>
                </li>
                <li className="fullWidth algnLf" onClick={() => setFormData(prev => ({...prev, selectedPayment: 1}))}>
                  <input type="radio" name="pricingType" checked={formData?.selectedPayment === 1} /> The monthly portal rent is € 19.90 net + 19% VAT (€ 1.88)
                  = gross € 11.78
                </li>
                <li className="fullWidth" onClick={() => setFormData(prev => ({...prev, selectedPayment: 2}))}>
                  <input type="radio" name="pricingType" checked={formData?.selectedPayment === 2} /> The monthly portal rent is € 19.90 net + 19% VAT (€ 3.78)
                  = gross € 23.68
                </li> */}
                <li className="fullWidth algnLf">
                  <h4>
                    {selectedPlan?.price ? (
                      <>
                        The monthly portal rent is € {selectedPlan?.price} net + 19% VAT (€ {((parseFloat(selectedPlan?.price) * 19) / 100).toFixed(2)}) = gross
                        € {(parseFloat(selectedPlan?.price) + (parseFloat(selectedPlan?.price) * 19) / 100).toFixed(2)}
                      </>
                    ) : null}
                  </h4>
                </li>
                <li className="fullWidth algnLf">
                  <h5>{langJson?.couponcodepromotioncode}</h5>
                </li>
                <li className="fullWidth entrCoupon">
                  {langJson?.entercouponcode}{" "}
                  <input type="text" value={formData?.couponCode} onChange={e => setFormData(prev => ({...prev, couponCode: e.target.value}))} />
                  {langJson?.tohaveafreemonth}
                </li>
                <li className="fullWidth algnLf">
                  <h5>{langJson?.payment}</h5>
                </li>
                <li className="fullWidth algnLf entrCoupon">
                  <input
                    type="radio"
                    name="paymentType"
                    checked={formData?.selectedPayment === 1}
                    onClick={() => setFormData(prev => ({...prev, selectedPayment: 1}))}
                  />
                  <div className="payPalDiv">{langJson?.paypal}</div>
                </li>
                {formData?.selectedPayment === 1 ? (
                  <>
                    <li className="fullWidth algnLf">
                      <PayPalPayment paymentMethod={"Paypal"} />
                      {/* <PayPalPayment storeId={storeId} shippingId={shippingId} paymentMethod={"Paypal"} productPrice={allDetails} /> */}
                    </li>
                  </>
                ) : null}
                <li className="fullWidth">
                  <input
                    type="radio"
                    name="paymentType"
                    checked={formData?.selectedPayment === 2}
                    onClick={() => setFormData(prev => ({...prev, selectedPayment: 2}))}
                  />
                  {langJson?.paymentbydirectdebitfromagermanbankaccount}
                </li>
                {formData?.selectedPayment === 2 ? (
                  <>
                    <li className="sizeInput">
                      <label>{langJson?.accountholder}:</label>
                      <input type="text" name="accountHolder" value={formData.accountHolder} onChange={handleChange} />
                    </li>
                    <li className="sizeInput">
                      <label>{langJson?.iban}:</label>
                      <input type="text" name="accountIBAN" value={formData.accountIBAN} onChange={handleChange} />
                    </li>
                    <li className="sizeInput">
                      <label>{langJson?.bic}:</label>
                      <input type="text" name="accountBIC" value={formData.accountBIC} onChange={handleChange} />
                    </li>
                  </>
                ) : null}

                <li className="fullWidth checkPlan">
                  <input
                    type="checkbox"
                    checked={formData.finaltermsAggreed}
                    onChange={e => setFormData(prev => ({...prev, finaltermsAggreed: e.target.checked}))}
                  />{" "}
                  {langJson?.iherebyrevocablyplan}
                </li>
                <li className="fullWidth saveBtn">
                  <button type="submit" disabled={loading} onClick={() => handleSubmit()}>
                    {/* {loading ? langJson?.pleasewait : langJson?.savechanges} */}
                    {loading ? langJson?.pleasewait : langJson?.submit}
                  </button>
                </li>
                <span className="lgnStatus">{signupStatus}</span>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="dashFooter">
        <h3>
          {langJson?.copyright} © Fair for Fair {new Date().getFullYear()}
        </h3>
      </div>
      <div className="footInner">
        <Footer />
      </div>
    </div>
  );
};

export default PlanSubscription;
