import React, {createContext, useContext, useState} from "react";

const AuthContext = createContext();

export function AuthProvider({children}) {
  const [user, setUser] = useState(JSON.parse(localStorage?.getItem("FFFUserData")));
  const [cartCount, setCartCount] = useState();
  const [cartPrice, setCartPrice] = useState();
  const [searchTerm, setSearchTerm] = useState();

  return (
    <AuthContext.Provider value={{user, setUser, cartCount, setCartCount, cartPrice, setCartPrice, searchTerm, setSearchTerm}}>{children}</AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
