import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";

const EditProfile = () => {
  const navigate = useNavigate();
  const {user, setUser} = useAuth();
  const {selectedLanguage, langJson} = useLanguage();

  const [signupStatus, setSignupStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    company: "",
    firstName: "",
    phone: "",
    address: "",
    country: "",
    city: "",
    state: "",
    zipCode: "",
    taxID: "",
  });

  const handleChange = event => {
    const {name, value} = event.target;

    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onLogout = () => {
    localStorage.removeItem("FFFUserData");
    navigate("/");
    setUser(null);
  };

  const fetchUserDetails = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}users/details`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setFormData({
          email: responseData.data.email,
          company: responseData.data.company_name,
          firstName: responseData.data.name,
          phone: responseData.data.phone,
          address: responseData.data.address,
          country: responseData.data.country,
          state: responseData.data.state,
          city: responseData.data.city,
          zipCode: responseData.data.zip_code,
          taxID: responseData.data.tax_id,
        });
        // setBannerList(responseData.data);
      } else {
      }
    } catch (error) {}
  };

  const handleSubmit = async () => {
    if (!formData.email) {
      setSignupStatus(langJson?.emailisrequired);
    } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(formData.email)) {
      setSignupStatus(langJson?.emailisnotvalid);
    } else if (!formData.company) {
      setSignupStatus(langJson?.companyisrequired);
    } else if (!formData.firstName) {
      setSignupStatus(langJson?.pleaseenterfirstname);
    } else if (!formData.phone) {
      setSignupStatus(langJson?.phoneisrequired);
    } else if (!formData.address) {
      setSignupStatus(langJson?.addressisrequired);
    } else if (!formData.country) {
      setSignupStatus(langJson?.countryisrequired);
    } else if (!formData.city) {
      setSignupStatus(langJson?.cityisrequired);
    } else if (!formData.state) {
      setSignupStatus(langJson?.stateisrequired);
    } else if (!formData.zipCode) {
      setSignupStatus(langJson?.zipcodeisrequired);
    } else if (!formData.taxID) {
      setSignupStatus(langJson?.taxidisrequired);
    } else {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_URL}users/details`, {
          method: "PUT",
          body: JSON.stringify({
            email: formData.email,
            company_name: formData.company,
            name: formData.firstName,
            phone: formData.phone,
            address: formData.address,
            country: formData.country,
            state: formData.state,
            city: formData.city,
            zip_code: formData.zipCode,
            tax_id: formData.taxID,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.authorisation.token}`,
          },
        });
        const responseData = await response.json();
        if (responseData.res === true) {
          setSignupStatus(responseData.msg);
          setLoading(false);
        } else {
          // Authentication failed, display an error message
          console.log("Sign UP failed");
          setSignupStatus(responseData.msg);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error during Sign UP:", error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  return (
    <div>
      <Header />
      <style>{`body { background: #F5F7FA; }`}</style>

      <div className="mainDiv">
        <div className="accountLink">
          <ul>
            <li className="active">
              <Link to="/my-account">{langJson?.myaccount}</Link>
            </li>
            <li>
              <Link to="/my-orders">{langJson?.myorders}</Link>
            </li>
            <li>
              <Link to="/wishlist">{langJson?.wishlist}</Link>
            </li>
            <li>
              <Link to="/store-followed">{langJson?.storefollowed}</Link>
            </li>
            <li>
              <Link to="/shipping-address">{langJson?.shippingaddress}</Link>
            </li>
            <li>
              <a href="javascript:void(0);" onClick={() => onLogout()}>
                {langJson?.logout}
              </a>
            </li>
          </ul>
        </div>
        <div className="acctHdn">
          <div className="hdnSec">
            <h2>{langJson?.editprofile}</h2>
            <button onClick={() => handleSubmit()} disabled={loading} className="editProfileBtn">
              {langJson?.updateprofile}
            </button>
          </div>

          <span className="lgnStatus">{signupStatus}</span>

          <div className="myaccountDiv">
            <ul>
              <li>
                <label>{langJson?.emailaddress}</label>
                <input type="email" name="email" onChange={handleChange} value={formData?.email} />
              </li>
              <li>
                <label>{langJson?.company}</label>
                <input type="text" name="company" onChange={handleChange} value={formData?.company} />
              </li>
              <li>
                <label>{langJson?.firstname}</label>
                <input type="text" name="firstName" onChange={handleChange} value={formData?.firstName} />
              </li>
              <li>
                <label>{langJson?.phonefaxmobilenumber}</label>
                <input type="text" name="phone" onChange={handleChange} value={formData?.phone} />
              </li>
              <li>
                <label>{langJson?.address}</label>
                <input type="text" name="address" onChange={handleChange} value={formData?.address} />
              </li>
              <li>
                <label>{langJson?.country}</label>
                <input type="text" name="country" onChange={handleChange} value={formData?.country} />
              </li>
              <li>
                <label>{langJson?.state}</label>
                <input type="text" name="state" onChange={handleChange} value={formData?.state} />
              </li>
              <li>
                <label>{langJson?.city}</label>
                <input type="text" name="city" onChange={handleChange} value={formData?.city} />
              </li>
              <li>
                <label>{langJson?.zipcode}</label>
                <input type="text" name="zipCode" onChange={handleChange} value={formData?.zipCode} />
              </li>
              <li>
                <label>{langJson?.taxid}</label>
                <input type="text" name="taxID" onChange={handleChange} value={formData?.taxID} />
              </li>
            </ul>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default EditProfile;
