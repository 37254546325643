import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";
import Sidebar from "../component/Sidebar";
import {NotificationManager} from "react-notifications";
import DataTable from "react-data-table-component";

const icon1 = require("./../assets/img/dashboard-icon.svg").default;
const icon2 = require("./../assets/img/info-icon.svg").default;
const icon3 = require("./../assets/img/app-icon.svg").default;
const icon4 = require("./../assets/img/payment-icon.svg").default;
const icon5 = require("./../assets/img/store-icon.svg").default;
const icon6 = require("./../assets/img/list-icon.svg").default;
const icon7 = require("./../assets/img/order-icon.svg").default;
const icon8 = require("./../assets/img/plan-icon.svg").default;
const icon9 = require("./../assets/img/logout-icon.svg").default;
const deleteIcon = require("./../assets/img/delete-icon.svg").default;
const uploadBtn = require("./../assets/img/search-btn.svg").default;
const bulletIcon = require("./../assets/img/bullet.svg").default;

const VendorOrderListing = () => {
  const {user} = useAuth();
  const {selectedLanguage, langJson} = useLanguage();
  const navigate = useNavigate();

  const [loading, setLoading] = useState();
  const [allDetails, setAllDetails] = useState();
  const [searchQuery, setSearchQuery] = useState("");

  const columns = [
    {
      name: langJson?.orderno,
      selector: row => row.order_number,
      sortable: true,
      // minWidth: "117px",
      maxWidth: "13%",
    },
    {
      name: langJson?.name,
      selector: row => row.name,
      sortable: true,
      // minWidth: "117px",
      maxWidth: "13%",
    },
    {
      name: langJson?.country,
      selector: row => row.country,
      sortable: true,
      // minWidth: "117px",
      maxWidth: "13%",
    },
    {
      name: langJson?.email,
      selector: row => row.email,
      sortable: true,
      // minWidth: "117px",
      maxWidth: "13%",
    },
    {
      name: langJson?.quantity,
      selector: row => row.quantity,
      sortable: true,
      // minWidth: "117px",
      maxWidth: "13%",
    },
    {
      name: langJson?.price,
      selector: row => (row.currency ? row.currency : "$" + row.total_amount),
      sortable: true,
      // minWidth: "117px",
      maxWidth: "13%",
    },
    {
      name: langJson?.orderdate,
      selector: row => row.ordered_date,
      sortable: true,
      // minWidth: "117px",
      maxWidth: "13%",
    },
    {
      name: langJson?.status,
      selector: row => row?.status,
      cell: row => (
        <div>
          <select value={row?.status} onChange={e => onStatusChange(e, row?.id)}>
            <option value={"new"}>new</option>
            <option value={"process"}>process</option>
            <option value={"delivered"}>delivered</option>
            <option value={"cancel"}>cancel</option>
          </select>
        </div>
      ),
      sortable: true,
      // minWidth: "117px",
      maxWidth: "13%",
    },
  ];

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}order/store-orders`, {
        method: "POST",
        // body: {},
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setAllDetails(responseData?.data);
      } else {
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleRowClick = orderNumber => {
    navigate(`/order/${orderNumber}`);
  };

  const onStatusChange = async (event, id) => {
    if (event.target.value) {
      let tempArray = [...allDetails];
      let index = tempArray.findIndex(item => parseInt(item?.id) === parseInt(id));
      tempArray[index].status = event.target.value;
      setAllDetails(tempArray);
      try {
        const response = await fetch(`${API_BASE_URL}order/update-order/${id}`, {
          method: "POST",
          body: JSON.stringify({status: event.target.value}),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.authorisation.token}`,
          },
        });
        const responseData = await response.json();
        if (responseData.res === true) {
          NotificationManager.success(`Status changed to ${event.target.value} successfully.`);
        } else {
          NotificationManager.success(responseData?.msg);
        }
      } catch (error) {}
    }
  };

  const filteredDetails =
    searchQuery.trim() === ""
      ? allDetails
      : allDetails?.filter(item => {
          const searchLower = searchQuery.toLowerCase();
          return (
            item?.order_number.toLowerCase().includes(searchLower) ||
            item?.name.toLowerCase().includes(searchLower) ||
            item?.country.toLowerCase().includes(searchLower) ||
            item?.email.toLowerCase().includes(searchLower) ||
            item?.quantity.toString()?.toLowerCase().includes(searchLower) ||
            item?.status.toLowerCase().includes(searchLower) ||
            (item?.sub_total).toString()?.toLowerCase().includes(searchLower)
          );
        });

  useEffect(() => {
    if (user) {
      fetchOrders();
    }
  }, []);

  return (
    <div>
      <div className="dashHeader">
        <Header />
      </div>
      <div className="adminDash">
        <Sidebar />

        <div className="rightSide rightTop">
          <h2>{langJson?.myorders}</h2>
          <div className="prdtTopRight">
            <input type="text" placeholder={langJson?.search + " " + langJson?.orders} value={searchQuery} onChange={e => setSearchQuery(e.target.value)} />
          </div>
          <div className="prdtTable">
            <div className="data-table-section bookDataSec">
              {!loading ? (
                <DataTable
                  key={"order-list-vendor"}
                  className="Tbl productListDataTable"
                  columns={columns}
                  data={filteredDetails}
                  highlightOnHover
                  pagination
                  responsive
                />
              ) : null}
            </div>
          </div>

          {/* <div className="prdtTable">
            <table>
              <tr>
                <th>{langJson?.orderno}</th>
                <th>{langJson?.name}</th>
                <th>{langJson?.country}</th>
                <th>{langJson?.email}</th>
                <th>{langJson?.quantity}</th>
                <th>{langJson?.price}</th>
                <th>{langJson?.orderdate}</th>
                <th>{langJson?.status}</th>
              </tr>
              {allDetails?.length > 0 ? (
                allDetails?.map((item, index) => (
                  <tr key={index} style={{cursor: "pointer"}}>
                    <td onClick={() => handleRowClick(item.id)}>{item?.order_number}</td>
                    <td onClick={() => handleRowClick(item.id)}>{item?.name}</td>
                    <td onClick={() => handleRowClick(item.id)}>{item?.country}</td>
                    <td onClick={() => handleRowClick(item.id)}>{item?.email}</td>
                    <td onClick={() => handleRowClick(item.id)}>{item?.quantity}</td>
                    <td onClick={() => handleRowClick(item.id)}>
                      {item?.currency ? item?.currency : "$"}
                      {item?.total_amount}
                    </td>
                    <td onClick={() => handleRowClick(item.id)}>{item?.ordered_date}</td>
                    <td>
                      <select value={item?.status} onChange={e => onStatusChange(e, item?.id)}>
                        <option value={"new"}>new</option>
                        <option value={"process"}>process</option>
                        <option value={"delivered"}>delivered</option>
                        <option value={"cancel"}>cancel</option>
                      </select>
                    </td>
                  </tr>
                ))
              ) : !loading ? (
                <tr>
                  <td colSpan={8} style={{textAlign: "center"}}>
                    {langJson?.noordersfound}
                  </td>
                </tr>
              ) : null}
            </table>
          </div> */}
        </div>
      </div>
      <div className="dashFooter">
        <h3>
          {langJson?.copyright} © Fair for Fair {new Date().getFullYear()}
        </h3>
      </div>
      <div className="footInner">
        <Footer />
      </div>
    </div>
  );
};

export default VendorOrderListing;
