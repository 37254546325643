import React, {useEffect, useState} from "react";
import {Link, useNavigate, NavLink} from "react-router-dom";
import $ from "jquery";
import {useAuth} from "../context/AuthContext";
import {useLanguage} from "../context/LanguageContext";
import {API_BASE_URL} from "../utils/app_url";
import facebookIcon from "../assets/img/facebook-icon.png";
import instagramIcon from "../assets/img/instagram-icon.png";
import snapchatIcon from "../assets/img/snapchat-icon.png";
import twitterxIcon from "../assets/img/twitterx-icon.png";
import youtubeIcon from "../assets/img/youtube-icon.png";

const logo = require("./../assets/img/logo.svg").default;

const Footer = () => {
  const {user} = useAuth();
  const {langJson} = useLanguage();
  const navigate = useNavigate();
  const [allDetails, setAllDetails] = useState();

  const fetchConfigDetails = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}frontend/configs`, {
        method: "GET",
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setAllDetails(responseData.data);
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (user?.role !== "admin") {
      fetchConfigDetails();
    }
  }, []);

  return (
    <div>
      <div className="footerDiv">
        <div className="mainDiv">
          <div className="footSec">
            <h2>{langJson?.maywehelpyou}</h2>
            <ul>
              <li>
                <Link to="/shop/made-in-italy">{langJson?.madeinitaly}</Link>
              </li>
              <li>
                <Link to="/store-followed">{langJson?.stores}</Link>
              </li>
              <li>
                <Link to="/store">{langJson?.fairtradeexhibitionhall}</Link>
              </li>
            </ul>
          </div>

          <div className="footSec">
            <h2>{langJson?.getintouch}</h2>
            <ul>
              <li>
                <Link to="/about-us">{langJson?.aboutus}</Link>
              </li>
              <li>
                <Link to="/support">{langJson?.support}</Link>
              </li>
              <li>
                <Link to="/privacy-policy">{langJson?.privacypolicy}</Link>
              </li>
              <li>
                <Link to="/">{langJson?.contactus}</Link>
              </li>
              <li>
                <Link to="/terms-of-use">{langJson?.termofuse}</Link>
              </li>
            </ul>
          </div>

          <div className="footSec">
            <h2>{langJson?.customerservice}</h2>
            <ul>
              {user ? (
                user?.role === "customer" ? (
                  <li>
                    <Link to={"/my-account"}>{langJson?.myaccount}</Link>
                  </li>
                ) : null
              ) : (
                <li className="loginLink" style={{cursor: "pointer"}}>
                  {langJson?.myaccount}
                </li>
              )}
              {/* <li>
                <Link to="">{langJson?.myaccount}</Link>
              </li> */}
              <li>
                <Link to="">{langJson?.lovelist}</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="footerBottomDiv">
        <div className="mainDiv">
          <div className="footSec">
            <h2>
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
            </h2>
            <ul>
              <li>
                {langJson?.copyright} © Fair for Fair {new Date().getFullYear()}
              </li>
            </ul>
          </div>

          <div className="footSec">
            <h2>{langJson?.contactus}</h2>
            <ul>
              <li>
                {langJson?.advisor} & {langJson?.support}: {allDetails?.find(item => item.name === "support")?.value}
              </li>
            </ul>
          </div>

          <div className="footSec">
            <h2 className="opacDiv">{langJson?.email}</h2>
            <ul>
              <li>
                {langJson?.email}:
                <Link to={`${allDetails?.find(item => item.name === "email")?.value}`} className="mailLink">
                  {allDetails?.find(item => item.name === "email")?.value}
                </Link>
              </li>
            </ul>
          </div>

          <div className="footSec touchIcons">
            <ul>
              <li>
                <Link to={allDetails?.find(item => item.name === "facebook")?.value} target="_blank">
                  <img src={facebookIcon} />
                </Link>
              </li>
              <li>
                <Link to={allDetails?.find(item => item.name === "twitter")?.value} target="_blank">
                  <img src={twitterxIcon} />
                </Link>
              </li>
              <li>
                <Link to={allDetails?.find(item => item.name === "instagram")?.value} target="_blank">
                  <img src={instagramIcon} />
                </Link>
              </li>
              <li>
                <Link to={allDetails?.find(item => item.name === "snapchat")?.value} target="_blank">
                  <img src={snapchatIcon} />
                </Link>
              </li>
              <li>
                <Link to={allDetails?.find(item => item.name === "youtube")?.value} target="_blank">
                  <img src={youtubeIcon} />
                </Link>
              </li>
            </ul>
          </div>

          {!user ? (
            <div className="footSec">
              <h2 className="opacDiv">{langJson?.login}</h2>
              <ul>
                <li>
                  <Link to="/shop-creation" className="venderLgn">
                    {langJson?.vendor}
                  </Link>
                </li>
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Footer;
