import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL, IMAGE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";
import Sidebar from "../component/Sidebar";
import {NotificationManager} from "react-notifications";

const icon1 = require("./../assets/img/dashboard-icon.svg").default;
const icon2 = require("./../assets/img/info-icon.svg").default;
const icon3 = require("./../assets/img/app-icon.svg").default;
const icon4 = require("./../assets/img/payment-icon.svg").default;
const icon5 = require("./../assets/img/store-icon.svg").default;
const icon6 = require("./../assets/img/list-icon.svg").default;
const icon7 = require("./../assets/img/order-icon.svg").default;
const icon8 = require("./../assets/img/plan-icon.svg").default;
const icon9 = require("./../assets/img/logout-icon.svg").default;
const deleteIcon = require("./../assets/img/delete-icon.svg").default;

const EditProduct = () => {
  const {user} = useAuth();
  const navigate = useNavigate();
  const {selectedLanguage, langJson} = useLanguage();
  const {id} = useParams();
  const imageBtnRef = useRef();

  const [addFormData, setAddFormData] = useState({
    article_no: null,
    order_no: null,
    article_name: null,
    brand: null,
    color: null,
    article_description: null,
    unit_price: null,
    currency: "$",
    price: null,
    article_type: null,
    size: null,
    original_price: null,
    main_category: null,
    sub_category: null,
    show_category: null,
    made_in_italy: 0,
  });
  const [sizeArray, setSizeArray] = useState([]);
  const [signupStatus, setSignupStatus] = useState("");
  const [uploadFrontImage, setUploadFrontImage] = useState();
  const [uploadBackImage, setUploadBackImage] = useState();
  const [sizeError, setSizeError] = useState();
  const [imageError, setImageError] = useState();
  const [loading, setLoading] = useState();
  const [addBtnDis, setAddBtnDis] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [showCategoryList, setShowCategoryList] = useState([]);
  const [images, setImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [imagesPath, setImagesPath] = useState([]);
  const [allDetails, setAllDetails] = useState();
  const currency = ["$", "€", "CHF"];

  const handleChange = event => {
    const {name, value} = event.target;

    setAddFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const addSize = () => {
    if (!addFormData.size) {
      setSizeError(langJson?.sizeisrequired);
    } else {
      let tempArray = [...sizeArray];
      let index = tempArray.findIndex(item => item?.size === addFormData.size);
      if (index === -1) {
        if (addFormData.size) {
          tempArray.push({size: addFormData.size, quantity: 1});
        } else {
          setSignupStatus(langJson?.pleaseentersize);
        }
        setSizeArray(tempArray);
        setAddFormData(prev => ({...prev, size: ""}));
      } else {
      }
    }
  };

  const removeSize = index => {
    let tempArray = [...sizeArray];
    tempArray.splice(index, 1);
    setSizeArray(tempArray);
  };

  const onQuantityChange = (event, index) => {
    let array = [...sizeArray];
    if (event) {
      array[index].quantity = event.target.value;
    } else {
      array[index].quantity = 0;
    }
    setSizeArray(array);
  };

  const fetchProductDetails = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}products/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setAllDetails(responseData.data);
        setAddFormData({
          article_no: responseData.data.article_no,
          order_no: responseData.data.order_no,
          article_name: responseData.data.title,
          brand: responseData.data.brand,
          color: responseData.data.color,
          article_description: responseData.data.description,
          unit_price: responseData.data.unit_price,
          currency: responseData.data.currency ? responseData.data.currency : "$",
          price: responseData.data.sale_price,
          original_price: responseData.data.price,
          main_category: responseData.data.season,
          // main_category: responseData.data.CatalogBrand,
          sub_category: responseData.data.pro_cat_id,
          show_category: responseData.data.cat_id,
          article_type: responseData.data.is_public == 1 ? "public" : "hidden",
          made_in_italy: parseInt(responseData.data.made_in_italy),
        });
        let tempArray = [];
        responseData.data.stocks.map((item, index) => {
          tempArray.push({size: item.size, quantity: parseInt(item.stock)});
        });
        setSizeArray(tempArray);

        const fetchImage = async item => {
          try {
            const imageResponse = await fetch(IMAGE_URL + item.image);
            const blob = await imageResponse.blob();
            setImagesPath(prev => [...prev, item]);
            return blob;
          } catch (error) {
            console.error("Error fetching image:", error);
            // You might want to handle the error here
            return null;
          }
        };

        const fetchImageArray = async () => {
          const imagePromises = responseData.data.images.map(fetchImage);

          try {
            const images = await Promise.all(imagePromises);
            // Filter out any null values (errors during fetching)
            const filteredImages = images.filter(image => image !== null);
            setImages(filteredImages);
          } catch (error) {
            console.error("Error fetching images:", error);
          }
        };
        fetchImageArray();
        // if (responseData.data.CatalogFrontImage) {
        //   try {
        //     const response = await fetch(responseData.data.CatalogFrontImage);
        //     const blob = await response.blob();
        //     setUploadFrontImage(blob);
        //   } catch (error) {
        //     console.error("Error fetching image:", error);
        //   }
        //   // setUploadFrontImage({
        //   //   uri: responseData.data.CatalogFrontImage,
        //   // });
        // }
        // if (responseData.data.CatalogBackImage) {
        //   try {
        //     const response = await fetch(responseData.data.CatalogBackImage);
        //     const blob = await response.blob();
        //     setUploadBackImage(blob);
        //   } catch (error) {
        //     console.error("Error fetching image:", error);
        //   }
        //   // setUploadBackImage({
        //   //   uri: responseData.data.CatalogBackImage,
        //   // });
        // }
      } else {
      }
    } catch (error) {
      console.error("Error during Sign UP:", error);
    }
  };

  const fetchCategory = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}users/search-main-category`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        let tempArray = responseData?.data.filter(
          (item, index) => item.CatalogSession !== "null" && item.CatalogSession !== "" && item.CatalogSession !== null
        );
        setCategoryList(tempArray);
      } else {
      }
    } catch (error) {
      console.error("Error during Sign UP:", error);
    }
  };

  const fetchSubCategory = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}products/product-categories`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setSubCategoryList(responseData.data);
      } else {
      }
    } catch (error) {
      console.error("Error during Sign UP:", error);
      setLoading(false);
    }
  };

  const fetchShowSubCategory = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}products/categories`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        // let categoryArray = {};
        // responseData.data.forEach((element, key) => {
        //   let category = element?.SubcategoryName?.split("/")[0];
        //   if (!categoryArray[category]) {
        //     categoryArray[category] = [];
        //   }
        //   categoryArray[category].push(element);
        // });
        // let newArray = [];
        // Object.entries(categoryArray).map((item, index) => {
        //   newArray.push({cat: item[0], items: item[1]});
        // });
        setShowCategoryList(responseData.data);
      } else {
      }
    } catch (error) {
      console.error("Error during Sign UP:", error);
      setLoading(false);
    }
  };

  const handleFrontImageDrop = event => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;

    if (droppedFiles.length > 0) {
      const allowedExtensions = ["jpg", "jpeg", "png"];

      Array.from(droppedFiles).forEach(file => {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (allowedExtensions.includes(fileExtension)) {
          setNewImages(prev => [...prev, file]);
        } else {
          setImageError(langJson?.invalid_file_format_Please_upload_a_valid__image_file);
        }
      });
    }
  };

  // const handleFrontImageDrop = event => {
  //   event.preventDefault();
  //   const droppedFiles = event.dataTransfer.files;

  //   if (droppedFiles.length > 0) {
  //     const allowedExtensions = ["jpg", "jpeg", "png"];

  //     Array.from(droppedFiles).forEach(file => {
  //       const fileExtension = file.name.split(".").pop().toLowerCase();
  //       if (allowedExtensions.includes(fileExtension)) {
  //         setImages(prev => [...prev, file]);
  //       } else {
  //         setImageError(langJson?.invalid_file_format_Please_upload_a_valid__image_file);
  //       }
  //     });
  //   }
  // };

  const handleBackImageDrop = event => {
    event.preventDefault();
    const file = event.dataTransfer.files[0]; // Get the first file

    if (file) {
      const allowedExtensions = ["jpg", "jpeg", "png"];
      if (file) {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (allowedExtensions.includes(fileExtension)) {
          setUploadBackImage(file);
          setSignupStatus("");
        } else {
          setUploadBackImage(null);
          setSignupStatus(langJson?.invalid_file_format_Please_upload_a_valid__image_file);
        }
      }
    } else {
    }
  };

  const onImageFrontUploadChange = e => {
    const allowedExtensions = ["jpg", "jpeg", "png"];
    const file = e.target.files[0];
    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        setUploadFrontImage(file);
        setSignupStatus("");
      } else {
        setUploadFrontImage(null);
        setSignupStatus(langJson?.invalid_file_format_Please_upload_a_valid__image_file);
      }
    }
  };

  const onImageBackUploadChange = e => {
    const allowedExtensions = ["jpg", "jpeg", "png"];
    const file = e.target.files[0];
    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        setUploadBackImage(file);
        setSignupStatus("");
      } else {
        setUploadBackImage(null);
        setSignupStatus(langJson?.invalid_file_format_Please_upload_a_valid__image_file);
      }
    }
  };

  const onImageMutipleUpload = e => {
    const allowedExtensions = ["jpg", "jpeg", "png"];
    const files = e.target.files;

    if (files.length > 0) {
      Array.from(files).forEach(file => {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (allowedExtensions.includes(fileExtension)) {
          setNewImages(prev => [...prev, file]);
        } else {
          setImageError(langJson?.invalid_file_format_Please_upload_a_valid__image_file);
        }
      });
    }
  };

  const handleRemoveImage = index => {
    let tempNewArray = [...newImages];
    let newArray = tempNewArray.filter((item, i) => index !== i);
    setNewImages(newArray);
  };

  const handleRemoveImageFromServer = async index => {
    let tempArray = [...images];
    let newArray = tempArray.filter((item, i) => index !== i);
    setImages(newArray);

    try {
      const response = await fetch(`${API_BASE_URL}products/images/${imagesPath[index].id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
      } else {
      }
    } catch (error) {
      console.error("Error during Sign UP:", error);
    }
  };

  const onEditArticle = async () => {
    if (addFormData.article_no === null || addFormData.article_no === undefined || addFormData.article_no === "") {
      setSignupStatus(langJson?.articlenoisrequired);
    } else if (addFormData.order_no === null || addFormData.order_no === undefined || addFormData.order_no === "") {
      setSignupStatus(langJson?.ordernoisrequired);
    } else if (addFormData.article_name === null || addFormData.article_name === undefined || addFormData.article_name === "") {
      setSignupStatus(langJson?.productnameisrequired);
      // } else if (
      //   addFormData.category === null ||
      //   addFormData.category === undefined ||
      //   addFormData.category === ''
      // ) {
      //   setSignupStatus('Category is required.');
    } else if (addFormData.brand === null || addFormData.brand === undefined || addFormData.brand === "") {
      setSignupStatus(langJson?.brandisrequired);
    } else if (addFormData.color === null || addFormData.color === undefined || addFormData.color === "") {
      setSignupStatus(langJson?.colorisrequired);
    } else if (addFormData.currency === null || addFormData.currency === undefined || addFormData.currency === "") {
      setSignupStatus(langJson?.currencyisrequired);
    } else if (addFormData.article_description === null || addFormData.article_description === undefined || addFormData.article_description === "") {
      setSignupStatus(langJson?.articledescriptionisrequired);
    } else if (addFormData.unit_price === null || addFormData.unit_price === undefined || addFormData.unit_price === "") {
      setSignupStatus(langJson?.unitpriceisrequired);
    } else if (!/^[0-9,.]+$/.test(addFormData.unit_price)) {
      setSignupStatus(langJson?.unitpriceacceptsonlynumbers);
    } else if (sizeArray?.length == 0) {
      setSignupStatus(langJson?.sizeisrequired);
    } else if (addFormData.price === null || addFormData.price === undefined || addFormData.price === "") {
      setSignupStatus(langJson?.priceisrequired);
    } else if (!/^[0-9,.]+$/.test(addFormData.price)) {
      setSignupStatus(langJson?.priceacceptsonlynumbers);
    } else if (addFormData.original_price === null || addFormData.original_price === undefined || addFormData.original_price === "") {
      setSignupStatus(langJson?.originalpriceisrequired);
    } else if (!/^[0-9,.]+$/.test(addFormData.original_price)) {
      setSignupStatus(langJson?.originalpriceacceptsonlynumbers);
    } else if (addFormData.main_category === null || addFormData.main_category === undefined || addFormData.main_category === "") {
      setSignupStatus(langJson?.maincategoryisrequired);
    } else if (addFormData.sub_category === null || addFormData.sub_category === undefined || addFormData.sub_category === "") {
      setSignupStatus(langJson?.subcategoryisrequired);
    } else if (addFormData.show_category === null || addFormData.show_category === undefined || addFormData.show_category === "") {
      setSignupStatus(langJson?.showcategoryisrequired);
    } else if (images.length === 0 && newImages.length === 0) {
      setSignupStatus(langJson?.pleaseselectaphoto);
    } else {
      setSignupStatus("");
      setAddBtnDis(true);
      const fd = new FormData();

      // fd.append("front_image", uploadFrontImage);
      // fd.append("back_image", uploadBackImage);

      newImages.map(item => {
        fd.append("images[]", item);
      });

      // fd.append("company_id", user?.UserId);
      fd.append("article_no", addFormData.article_no);
      fd.append("order_no", addFormData.order_no);
      fd.append("title", addFormData.article_name);
      fd.append("brand", addFormData.brand);
      fd.append("color", addFormData.color);
      fd.append("description", addFormData.article_description);
      fd.append("unit_price", addFormData.unit_price);
      fd.append("currency", addFormData.currency ? addFormData.currency : "$");
      fd.append("sale_price", addFormData.price);
      fd.append("price", addFormData.original_price);
      fd.append("season", addFormData.main_category);
      fd.append("pro_cat_id", addFormData.sub_category);
      fd.append("cat_id", addFormData.show_category);
      fd.append("is_public", addFormData.article_type === "public" ? 1 : 0);
      fd.append("stocks", JSON.stringify(sizeArray));
      fd.append("made_in_italy", addFormData.made_in_italy ? addFormData.made_in_italy : 0);
      fd.append("lang", selectedLanguage);

      try {
        const response = await fetch(`${API_BASE_URL}products/${id}`, {
          method: "POST",
          body: fd,
          headers: {
            Authorization: `Bearer ${user.authorisation.token}`,
          },
        });
        const responseData = await response.json();
        if (responseData.res === true) {
          setSignupStatus(responseData.msg);
          NotificationManager.success(responseData.msg);
          setAddBtnDis(false);
        } else {
          // Authentication failed, display an error message
          setSignupStatus(responseData.msg);
          NotificationManager.warning(responseData.msg);
          setAddBtnDis(false);
        }
      } catch (error) {
        console.error("Error during Sign UP:", error);
        setAddBtnDis(false);
      }
    }
  };

  useEffect(() => {
    // alert(id);
    fetchProductDetails();
    fetchSubCategory();
    fetchShowSubCategory();
  }, []);

  return (
    <div>
      <div className="dashHeader">
        <Header />
      </div>
      <div className="adminDash">
        <Sidebar />
        <div className="rightSide">
          <h2>{langJson?.editproduct}</h2>
          <ul className="addAddress">
            <li>
              <label>{langJson?.articleno}.</label>
              <input type="text" name="article_no" onChange={handleChange} value={addFormData.article_no} />
            </li>
            <li>
              <label>{langJson?.orderno}.</label>
              <input type="text" name="order_no" onChange={handleChange} value={addFormData.order_no} />
            </li>
            <li>
              <label>{langJson?.name}</label>
              <input type="text" name="article_name" onChange={handleChange} value={addFormData.article_name} />
            </li>
            <li>
              <label>{langJson?.brand}</label>
              <input type="text" name="brand" onChange={handleChange} value={addFormData.brand} />
            </li>
            <li>
              <label>{langJson?.colour}</label>
              <input type="text" name="color" onChange={handleChange} value={addFormData.color} />
            </li>
            <li>
              <label>{langJson?.currency}</label>
              <select name="currency" onChange={handleChange} value={addFormData.currency}>
                {currency?.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </li>
            <li className="fullWidth">
              <label>{langJson?.articledescription}</label>
              <textarea name="article_description" value={addFormData.article_description} onChange={handleChange}></textarea>
            </li>

            {sizeError ? <span className="lgnStatus">{sizeError}</span> : null}

            <li className="sizeInput">
              <label>{langJson?.size}:</label>

              <input type="text" name="size" value={addFormData.size} onChange={handleChange} />
              <button type="button" className="addBtn" onClick={() => addSize()}>
                {langJson?.add}
              </button>
              <span onClick={() => setAddFormData(prev => ({...prev, article_type: "public"}))}>
                <input type="radio" name="articleType" checked={addFormData.article_type === "public" ? true : false} />
                {langJson?.public}
              </span>
              <span onClick={() => setAddFormData(prev => ({...prev, article_type: "hidden"}))}>
                <input type="radio" name="articleType" checked={addFormData.article_type === "hidden" ? true : false} />
                {langJson?.hidden}
              </span>
              {sizeArray?.map((item, index) => (
                <div className="sizeLoop" key={index}>
                  <div className="quan">
                    {item?.size} <img className="delIcon" src={deleteIcon} alt="" onClick={() => removeSize(index)} />
                  </div>
                  <input type="text" value={item?.quantity} onChange={e => onQuantityChange(e, index)} />
                </div>
              ))}
            </li>
            <li>
              <label>{langJson?.price}:</label>
              <input type="number" name="price" onChange={handleChange} value={addFormData.price} />
            </li>
            <li>
              <label>{langJson?.unitprice}:</label>
              <input type="number" name="unit_price" onChange={handleChange} value={addFormData.unit_price} />
            </li>

            <li>
              <label>{langJson?.originalprice}:</label>
              <input type="number" name="original_price" onChange={handleChange} value={addFormData.original_price} />
            </li>
            <li>
              <label>{langJson?.maincategorygroupseasonarticle}:</label>
              <input type="text" name="main_category" onChange={handleChange} value={addFormData.main_category} />
            </li>
            <li>
              <label>{langJson?.subcategory}:</label>
              <select name="sub_category" onChange={handleChange} value={addFormData.sub_category}>
                <option value={null}>{langJson?.subcategory}</option>
                {subCategoryList?.map((item, index) => (
                  <option key={index} value={item?.id}>
                    {item?.title}
                  </option>
                ))}
              </select>
            </li>
            <li>
              <label>{langJson?.showcategory}:</label>
              <select name="show_category" onChange={handleChange} value={addFormData.show_category}>
                <option value={null}>{langJson?.showcategory}</option>
                {showCategoryList?.map((item, index) => (
                  <optgroup label={item?.title} key={index}>
                    {item?.sub_categories?.map((chItem, chIndex) => (
                      <option key={chIndex} value={chItem?.id}>
                        {chItem?.title}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>
            </li>
            <li>
              <span>
                <input
                  type="checkbox"
                  name="madeInItaly"
                  checked={addFormData?.made_in_italy === 1 ? true : false}
                  onChange={e => setAddFormData(prev => ({...prev, made_in_italy: e.target.checked ? 1 : 0}))}
                />
                {langJson?.madeinitaly}
              </span>
            </li>
            <li className="fullWidth">
              <label>{langJson?.uploadimages}:</label>
              <span className="lgnStatus">{imageError}</span>
              <br></br>
              <input type="file" ref={imageBtnRef} hidden onChange={e => onImageMutipleUpload(e)} multiple />
              <button onClick={() => imageBtnRef.current.click()}>{langJson?.chooseimages}</button>
              <div className="uploadFle" onDragOver={e => e.preventDefault()} onDrop={handleFrontImageDrop}></div>
              <br />
              {images?.map((item, index) => {
                return (
                  <>
                    <img key={index} className="productImgClass" src={URL.createObjectURL(item)} />
                    <img key={index} src={deleteIcon} alt="" onClick={() => handleRemoveImageFromServer(index)} />
                  </>
                );
              })}
              {newImages?.map((item, index) => {
                return (
                  <>
                    <img key={index} className="productImgClass" src={URL.createObjectURL(item)} />
                    <img key={index} src={deleteIcon} alt="" onClick={() => handleRemoveImage(index)} />
                  </>
                );
              })}
              {/* {images?.map((item, index) => {
                return (
                  <>
                    <img key={index} className="productImgClass" src={URL.createObjectURL(item)} />
                    {allDetails?.images?.some(chItem => chItem.image === imagesPath[index].image) ? (
                      <img key={index} src={deleteIcon} alt="" onClick={() => handleRemoveImageFromServer(index)} />
                    ) : (
                      <img key={index} src={deleteIcon} alt="" onClick={() => handleRemoveImage(index)} />
                    )}
                  </>
                );
              })} */}
            </li>
            <li className="fullWidth saveBtn">
              <button type="button" disabled={addBtnDis} onClick={() => onEditArticle()}>
                {addBtnDis ? langJson?.pleasewait : langJson?.savechanges}
              </button>
            </li>
            <span className="lgnStatus">{signupStatus}</span>
          </ul>
        </div>
      </div>
      <div className="dashFooter">
        <h3>
          {langJson?.copyright} © Fair for Fair {new Date().getFullYear()}
        </h3>
      </div>
      <div className="footInner">
        <Footer />
      </div>
    </div>
  );
};

export default EditProduct;
