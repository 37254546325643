import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";
import Sidebar from "../component/Sidebar";

const icon1 = require("./../assets/img/dashboard-icon.svg").default;
const icon2 = require("./../assets/img/info-icon.svg").default;
const icon3 = require("./../assets/img/app-icon.svg").default;
const icon4 = require("./../assets/img/payment-icon.svg").default;
const icon5 = require("./../assets/img/store-icon.svg").default;
const icon6 = require("./../assets/img/list-icon.svg").default;
const icon7 = require("./../assets/img/order-icon.svg").default;
const icon8 = require("./../assets/img/plan-icon.svg").default;
const icon9 = require("./../assets/img/logout-icon.svg").default;
const deleteIcon = require("./../assets/img/delete-icon.svg").default;
const uploadBtn = require("./../assets/img/search-btn.svg").default;
const bulletIcon = require("./../assets/img/bullet.svg").default;

const VendorVideoListing = () => {
  const {user} = useAuth();
  const {selectedLanguage, langJson} = useLanguage();
  const navigate = useNavigate();
  const actionDivRef = useRef(null);

  const [loading, setLoading] = useState();
  const [allDetails, setAllDetails] = useState();
  const [videoList, setVideoList] = useState();
  const [selectedId, setSelectedId] = useState();

  const fetchVideos = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}stores/videos`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setAllDetails(responseData.data);
        setVideoList(responseData.data.data);
      } else {
      }
    } catch (error) {
      console.error("Error during Sign UP:", error);
      setLoading(false);
    }
  };

  const onBulletClick = productId => {
    if (parseInt(selectedId) !== parseInt(productId)) {
      setSelectedId(parseInt(productId));
    } else {
      setSelectedId(null);
    }
  };

  const handleDelete = async productId => {
    // Perform the deletion logic here
    if (productId) {
      const confirmDelete = window.confirm("Are you sure you want to delete this product?");

      if (confirmDelete) {
        // Delete the item (replace this with your actual deletion logic)
        try {
          const response = await fetch(`${API_BASE_URL}stores/videos/${productId}`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.authorisation.token}`,
            },
          });
          const responseData = await response.json();
          if (responseData.res === true) {
            let tempArray = [...videoList];
            let selIndex = tempArray.findIndex(item => parseInt(item?.id) === parseInt(productId));
            tempArray.splice(selIndex, 1);
            setVideoList(tempArray);
            setSelectedId(null);
          } else {
          }
        } catch (error) {
          console.error("Error during Sign UP:", error);
          setLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    fetchVideos();
  }, []);

  useEffect(() => {
    const handleClickOutside = event => {
      if (!actionDivRef?.current?.contains(event.target)) {
        // Clicked outside the td, add your logic here
        // console.log("Clicked outside the td");
        // setSelectedId(null);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className="dashHeader">
        <Header />
      </div>
      <div className="adminDash">
        <Sidebar />

        <div className="rightSide rightTop">
          <h2>{langJson?.videos}</h2>
          <div className="prdtTopRight">
            <Link to="/add-videos">{langJson?.addnewvideos}</Link>
          </div>
          <div className="prdtTable">
            <table>
              <tr>
                <th>{langJson?.title}</th>
                <th>{langJson?.action}</th>
              </tr>
              {videoList?.map((item, index) => (
                <tr key={index}>
                  <td>{item?.title}</td>
                  <td className="actionHolder">
                    <div>
                      <img src={bulletIcon} alt="" onClick={() => onBulletClick(item?.id)} />
                      {parseInt(selectedId) === parseInt(item?.id) ? (
                        <div className="actionDiv" ref={actionDivRef}>
                          <button onClick={() => navigate(`/edit-videos/${item?.id}`)}>{langJson?.edit}</button>
                          <button onClick={() => handleDelete(item?.id)}>{langJson?.delete}</button>
                        </div>
                      ) : null}
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
      <div className="dashFooter">
        <h3>
          {langJson?.copyright} © Fair for Fair {new Date().getFullYear()}
        </h3>
      </div>
      <div className="footInner">
        <Footer />
      </div>
    </div>
  );
};

export default VendorVideoListing;
