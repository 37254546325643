import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";
import {API_BASE_URL, IMAGE_URL} from "../utils/app_url";
import {NotificationManager} from "react-notifications";

const wishlistImg = require("./../assets/img/wishlist-icon2.svg").default;
const cartImg = require("./../assets/img/cart-icon.svg").default;
const storeImg = require("./../assets/img/stores1-min.png");
const storeImg2 = require("./../assets/img/stores2-min.png");

const Products = () => {
  const {user, setUser} = useAuth();
  const {selectedLanguage, langJson} = useLanguage();
  const navigate = useNavigate();

  const [allDetails, setAllDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");

  const onLogout = () => {
    localStorage.removeItem("FFFUserData");
    navigate("/");
    setUser(null);
  };

  const fetchStores = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}frontend/liked-stores`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setAllDetails(responseData.data);
      } else {
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleRemoveStore = async id => {
    try {
      const response = await fetch(`${API_BASE_URL}frontend/liked-stores/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        // setResponseStatus(responseData?.msg);
        NotificationManager.success(responseData?.msg);

        let updatedArray = allDetails?.filter(item => parseInt(item?.id) !== parseInt(id));
        setAllDetails(updatedArray);
      } else {
        // setResponseStatus(responseData?.msg);
        NotificationManager.warning(responseData?.msg);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchStores();
    $(".dropDiv").each(function () {
      $("h3", this).click(() => {
        $(".subSidebar").hide();
        $(this).find(".subSidebar").toggle();
      });
    });

    $(".filterBtn").click(() => {
      $(".sideBar").show();
    });

    $(".crossBtn").click(() => {
      $(".sideBar").hide();
    });
  }, []);

  return (
    <div>
      <Header />
      <style>{`body { background: #F5F7FA; }`}</style>

      <div className="mainDiv">
        <div className="accountLink">
          <ul>
            <li className="active">
              <Link to="/my-account">{langJson?.myaccount}</Link>
            </li>
            <li>
              <Link to="/my-orders">{langJson?.myorders}</Link>
            </li>
            <li>
              <Link to="/wishlist">{langJson?.wishlist}</Link>
            </li>
            <li>
              <Link to="/store-followed">{langJson?.storefollowed}</Link>
            </li>
            <li>
              <Link to="/shipping-address">{langJson?.shippingaddress}</Link>
            </li>
            <li>
              <a href="javascript:void(0);" onClick={() => onLogout()}>
                {langJson?.logout}
              </a>
            </li>
          </ul>
        </div>
        <div className="acctHdn">
          <div className="hdnSec">
            <h2>{langJson?.storefollowed}</h2>
          </div>
          {responseStatus ? <span className="lgnStatus">{responseStatus}</span> : null}
          <div className="wishlistDiv">
            <div className="productList">
              <ul>
                {allDetails?.length > 0
                  ? allDetails?.map((item, index) => (
                      <li key={index}>
                        <div className="imgHolder">
                          <Link to={`/store/${item?.store_id}`}>
                            <img src={IMAGE_URL + item?.store?.logo} alt="" className="prdtImg" />
                          </Link>
                          <div className="wishlistItem" style={{cursor: "pointer"}} onClick={() => handleRemoveStore(item?.id)}>
                            <img src={wishlistImg} alt="" />
                          </div>
                        </div>
                      </li>
                    ))
                  : !loading && langJson?.noitemsfound}
              </ul>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Products;
