import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";
import PayPalPayment from "./PayPalPayment";

const paypalImg = require("./../assets/img/paypal.png");
const paypalImg2 = require("./../assets/img/graphic-paypal.png");
const cartIcon = require("./../assets/img/cart-icon3.svg").default;

const Payment = () => {
  const {user} = useAuth();
  const navigate = useNavigate();
  const {selectedLanguage, langJson} = useLanguage();
  const [shippingId] = useState(sessionStorage.getItem("FFFShippingId"));
  const [storeId] = useState(sessionStorage.getItem("FFFStoreId"));

  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [allDetails, seAllDetails] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [paymentMethodList, setPaymentMethodList] = useState();
  const [signupStatus, setSignupStatus] = useState("");
  const [paypalCreds, setPaypalCreds] = useState();
  // const [type,setType] = useState();
  // const [paymentProcess,setPaymentProcess] = useState('1');

  const fetchCartAmont = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}cart/amount/${storeId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        seAllDetails(responseData.data);
      } else {
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onPlaceOrder = async () => {
    if (!paymentMethod) {
      setSignupStatus(langJson?.pleasechoosePaymentMethod);
    } else {
      setLoadingBtn(true);
      try {
        const response = await fetch(`${API_BASE_URL}order`, {
          method: "POST",
          body: JSON.stringify({
            store_id: storeId,
            shipping_id: shippingId,
            payment_method: paymentMethod,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.authorisation.token}`,
          },
        });
        const responseData = await response.json();
        if (responseData.res === true) {
          navigate("/payment-success");
          sessionStorage.removeItem("FFFShippingId");
          sessionStorage.removeItem("FFFStoreId");
        } else {
        }
        setLoadingBtn(false);
      } catch (error) {
        setLoadingBtn(false);
      }
    }
  };

  const fetchPayments = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}stores/${storeId}/payments`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        let tempArray = [];
        if(responseData.client_id){
          setPaypalCreds({client_id: responseData.client_id, secret_key: responseData.secret_key})
        }
        if (responseData.data.length > 0) {
          responseData.data.forEach(element => {
            tempArray.push(element.payment.title);
          });
          setPaymentMethodList(tempArray);
        } else {
          setPaymentMethodList([]);
        }
      } else {
      }
    } catch (error) {}
    // try {
    //   const response = await fetch(`${API_BASE_URL}frontend/payments`, {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${user.authorisation.token}`,
    //     },
    //   });
    //   const responseData = await response.json();
    //   if (responseData.res === true) {
    //     if (responseData.data?.length > 0) {
    //       let tempArray = [];
    //       responseData.data?.map(item => {
    //         tempArray.push(item?.title);
    //       });
    //       setPaymentMethodList(tempArray);
    //     }
    //   } else {
    //   }
    // } catch (error) {}
  };

  useEffect(() => {
    fetchCartAmont();
    fetchPayments();
    console.log(allDetails);
  }, []);

  return (
    <div>
      <Header />
      <style>{`body { background: #F5F7FA; }`}</style>

      <div className="mainDiv">
        <div className="paymentDiv">
          <div className="mainDiv">
            <ul>
              {paymentMethodList?.includes("Paypal") ? (
                <>
                  <li>
                    <div className="payMntcart">
                      <img src={cartIcon} alt="" />${!loading ? (allDetails ? allDetails : 0) : ""}
                    </div>
                  </li>
                </>
              ) : null}
              {paymentMethodList?.includes("Cash on delivery") ? (
                <li className="cashDelivery">
                  <input
                    name="paymentType"
                    type="radio"
                    value=""
                    onClick={() => {
                      setPaymentMethod("cod");
                      setSignupStatus("");
                    }}
                  />
                  {langJson?.cashondelivery}
                </li>
              ) : null}
              {paymentMethodList?.includes("Pre Payment") ? (
                <li className="cashDelivery">
                  <input
                    name="paymentType"
                    type="radio"
                    value=""
                    onClick={() => {
                      setPaymentMethod("prepayment");
                      setSignupStatus("");
                    }}
                  />
                  {langJson?.prepayment}
                </li>
              ) : null}
              {paymentMethodList?.includes("As agreed") ? (
                <li className="cashDelivery">
                  <input
                    name="paymentType"
                    type="radio"
                    value=""
                    onClick={() => {
                      setPaymentMethod("asagreed");
                      setSignupStatus("");
                    }}
                  />
                  {langJson?.asagreed}
                </li>
              ) : null}

              {paymentMethodList?.length === 0 ? <li className="cashDelivery">{langJson?.nopaymentmethodfound}</li> : null}

              <li>
                {loadingBtn ? <button disabled>{langJson?.pleasewait}</button> : <button onClick={() => onPlaceOrder()}>{langJson?.placeorder}</button>}
                {signupStatus ? <span className="lgnStatus">{signupStatus}</span> : null}
              </li>
              {allDetails > 0 ? (
                <>
                  <span className="lgnStatus OrSpan">Or</span>
                  <div className="payPalDiv">
                    <PayPalPayment storeId={storeId} shippingId={shippingId} paymentMethod={"paypal"} productPrice={allDetails} paypalCreds={paypalCreds} />
                  </div>
                </>
              ) : null}

              {paymentMethodList?.length === 0 ? <li className="cashDelivery">{langJson?.nopaymentmethodfound}</li> : null}
            </ul>

            <div className="paymentRgt">
              <img src={paypalImg2} alt="" />
              <h3>{langJson?.newfastereasier}</h3>
              <p>{langJson?.welcometothenewpaypalcheckoutthesecurityyourelyonnowevenfaster}</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Payment;
