import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {useLanguage} from "../context/LanguageContext";
import {API_BASE_URL} from "../utils/app_url";
import {useAuth} from "../context/AuthContext";

const deleteIcon = require("./../assets/img/delete-icon3.svg").default;
const editIcon = require("./../assets/img/edit-icon.svg").default;

const Address = () => {
  const {user} = useAuth();
  const {selectedLanguage, langJson} = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [allDetails, seAllDetails] = useState();
  const [selectedShipping, setSelectedShipping] = useState();
  const [signupStatus, setSignupStatus] = useState("");
  const [privacyChecked, setPrivacyChecked] = useState(false);

  const fetchShipping = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}users/shipping`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        seAllDetails(responseData.data);
      } else {
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (e, item) => {
    if (e.target.checked) {
      setSelectedShipping(item.id);
      setSignupStatus("");
    } else {
      setSelectedShipping(null);
      setSignupStatus("");
    }
  };

  const deleteShipping = async id => {
    const confirmDelete = window.confirm(langJson?.areyousureyouwanttodelete);

    if (confirmDelete) {
      try {
        const response = await fetch(`${API_BASE_URL}users/shipping/${id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.authorisation.token}`,
          },
        });
        const responseData = await response.json();
        if (responseData.res === true) {
          let tempArray = allDetails.filter(item => parseInt(item.id) !== parseInt(id));
          seAllDetails(tempArray);
        } else {
        }
      } catch (error) {}
    }
  };

  const placeOrder = async () => {
    if (!selectedShipping) {
      setSignupStatus(langJson?.pleaseselectoneaddress);
    } else if (!privacyChecked) {
      setSignupStatus(langJson?.pleaseacceptcompanytermsandpolicy);
    } else {
      setLoadingBtn(true);
      sessionStorage.setItem("FFFShippingId", selectedShipping);
      navigate("/payment");
      setLoadingBtn(false);
    }
  };

  useEffect(() => {
    fetchShipping();
  }, []);

  return (
    <div>
      <Header />
      <style>{`body { background: #F5F7FA; }`}</style>

      <div className="mainDiv">
        <div className="mainDiv cartMain">
          <div className="hdnSec">
            <h2>{langJson?.selectaddress}</h2>
            <div className="addAddressBtn">
              <Link to="/add-address" state={{from: location.pathname}}>
                {langJson?.addnew}
              </Link>
            </div>
          </div>

          {allDetails?.length > 0 ? (
            <>
              <ul className="addressStore">
                {allDetails?.map((item, index) => (
                  <li key={index}>
                    <input
                      type="checkbox"
                      onChange={e => handleCheckboxChange(e, item)}
                      // onClick={() => {
                      //   setSelectedShipping(item?.id);
                      //   setSignupStatus("");
                      // }}
                    />
                    <div className="topEdit">
                      <Link to={`/edit-address/${item?.id}`} className="orderBtn">
                        <img src={editIcon} alt="" />
                      </Link>
                      <img src={deleteIcon} alt="" onClick={() => deleteShipping(item?.id)} />
                    </div>
                    {/* <h3>Store 1</h3> */}
                    <h4>{item?.name}</h4>
                    <p>{item?.address}</p>
                    <p>{item?.phone}</p>
                    <p>{item?.city}</p>
                  </li>
                ))}
              </ul>

              <div className="bottomAddress">
                <h2>
                  {langJson?.gewunschtzahlart}/{langJson?.requestedpayment}:
                </h2>
                <p>
                  <input type="checkbox" value={privacyChecked} onChange={e => setPrivacyChecked(e.target.checked)} />
                  <span>
                    {langJson?.Withyourorderyouexplainbindingintentiontopurchasethegoods}. {langJson?.yourorderwillbeatthecompany}
                    {langJson?.forwardedtothedispatch}.{langJson?.yesiacceptthetermsandconditionsandtheplatformprivacypolicyoperatedbysdiverwaltungsug}.
                  </span>
                </p>
                {loadingBtn ? <button disabled>{langJson?.confirmorder}</button> : <button onClick={() => placeOrder()}>{langJson?.confirmorder}</button>}
                <span className="lgnStatus">{signupStatus}</span>
              </div>
            </>
          ) : !loading ? (
            <ul className="addressStore">
              <li>
                <h3>{langJson?.norecordsfound}</h3>
              </li>
            </ul>
          ) : null}
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Address;
