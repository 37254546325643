import React, {useEffect, useState} from "react";
import {Link, useNavigate, NavLink, useLocation} from "react-router-dom";
import $ from "jquery";
import {API_BASE_URL} from "../utils/app_url";
import {useAuth} from "../context/AuthContext";
import {useLanguage} from "../context/LanguageContext";
import {NotificationManager} from "react-notifications";

const logo = require("./../assets/img/logo.svg").default;
const wishlist = require("./../assets/img/wishlist-icon1.svg").default;
const cart = require("./../assets/img/cart-icon.svg").default;
const userLogo = require("./../assets/img/user.svg").default;
const language = require("./../assets/img/language.svg").default;
const toggle = require("./../assets/img/menu-btn.svg").default;
const cross = require("./../assets/img/cross-icon.svg").default;
const passwordIcon = require("./../assets/img/password-icon.svg").default;

const Header = props => {
  const {user, setUser, cartCount, setCartCount, searchTerm, setSearchTerm} = useAuth();
  const {selectedLanguage, changeLanguage, langJson} = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [forgotEmail, setForgotEmail] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [loginStatus, setLoginStatus] = useState("");
  const [userData, setUserData] = useState(null);
  const [categoryData, setCategoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [forgotPassSec, setForgotPassSec] = useState(false);

  const handleChange = event => {
    const {name, value, type, checked} = event.target;
    const newValue = type === "checkbox" ? checked : value;

    setFormData(prevData => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (!formData.email) {
      setLoginStatus(langJson?.emailisrequired);
      // } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(formData.email)) {
      //   setLoginStatus(langJson?.emailisnotvalid);
    } else if (!formData.password) {
      setLoginStatus(langJson?.passwordisrequired);
    } else {
      setLoginStatus("");
      setLoading(true);
      const form = new FormData();
      form.append("login", formData.email);
      form.append("password", formData.password);

      try {
        const response = await fetch(`${API_BASE_URL}users/login`, {
          method: "POST",
          body: form,
        });
        const responseData = await response.json();
        if (responseData.res === true) {
          // Authentication successful, you can redirect the user or perform further actions
          // if (Number(responseData.data.status) === 1) {
          setLoginStatus(responseData.msg);
          fetchCartCount();
          localStorage.setItem("FFFUserData", JSON.stringify(responseData.data));
          setUser(responseData.data);
          if (responseData.data.role === "customer") {
            if (location.pathname === "/register" || location.pathname === "/shop-creation" || location.pathname === "/vendor-register") {
              navigate("/");
            } else {
              $(".loginPopup").removeClass("active");
            }
          } else if (responseData.data.role === "admin") {
            $(".loginPopup").removeClass("active");
            navigate("/admin-dashboard");
          } else {
            $(".loginPopup").removeClass("active");
            navigate("/dashboard");
          }
          // navigate("/");
          // } else {
          //   setLoginStatus(responseData.msg);
          // }
          setLoading(false);
        } else {
          // Authentication failed, display an error message
          setLoginStatus(responseData.msg);
          setLoading(false);
        }
      } catch (error) {
        NotificationManager.error(langJson?.somethingwentwronginserverpleasetryagain);
        console.error("Error during login:", error);
        setLoading(false);
      }
    }
  };

  const handleForgotPassSubmit = async event => {
    event.preventDefault();
    if (!forgotEmail) {
      setLoginStatus(langJson?.emailisrequired);
    } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(forgotEmail)) {
      setLoginStatus(langJson?.emailisnotvalid);
    } else {
      setLoginStatus("");
      setLoading(true);
      const form = new FormData();
      form.append("email", forgotEmail);
      try {
        const response = await fetch(`${API_BASE_URL}users/forget-password`, {
          method: "POST",
          body: form,
        });
        const responseData = await response.json();
        if (responseData.res === true) {
          // Authentication successful, you can redirect the user or perform further actions
          // if (Number(responseData.data.status) === 1) {
          setLoginStatus(responseData.msg);
          setForgotEmail("");
          setLoading(false);
        } else {
          // Authentication failed, display an error message
          setLoginStatus(responseData.msg);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error during login:", error);
        setLoading(false);
      }
    }
  };

  const onSearchClick = () => {
    if (!searchTerm) {
      NotificationManager.warning(langJson?.pleaseentersearchvalue);
    } else {
      navigate(`/search/${searchTerm}`);
    }
  };

  const fetchCartCount = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}cart/count`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setCartCount(parseInt(responseData.data));
      } else {
      }
    } catch (error) {}
  };

  const fetchData = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}products/categories`);
      const data = await response.json();

      if (data && Array.isArray(data.data) && data.data.length > 0) {
        setCategoryData(data.data);
        $(document).ready(function () {
          $(".submenuLink").click(function () {
            $(".sub-submenu").removeClass("active");
            $(this).find(".sub-submenu").addClass("active");
            $(".sub-submenu li", this).click(function () {
              $(".submenu").removeClass("active");
            });
          });
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onSelectLanguage = lang => {
    localStorage.setItem("appLanguage", lang);
    changeLanguage(lang);
  };

  useEffect(() => {
    fetchData();
    $(".mobileBtn").click(() => {
      $(".navDiv").toggleClass("active");
    });
    $(".categoryLink").click(() => {
      $(".submenu").toggleClass("active");
    });

    $(".loginLink").click(() => {
      $(".loginPopup").addClass("active");
    });
    $(".crossBtn").click(() => {
      $(".loginPopup").removeClass("active");
    });
  }, []);

  useEffect(() => {
    fetchCartCount();
  }, [user]);

  useEffect(() => {
    if (props?.openModal === true || props?.openModal === false) {
      $(".loginPopup").addClass("active");
    }
  }, [props?.openModal]);

  return (
    <div>
      <div className="header">
        <div className="mainDiv">
          <div className="topHeader">
            <div className="logo">
              <NavLink to="/">
                <img src={logo} alt="" />
              </NavLink>
            </div>
            <div className="topSearch">
              {user?.role !== "vendor" && user?.role !== "admin" ? (
                <>
                  <input type="text" value={searchTerm} name="" onChange={e => setSearchTerm(e.target.value)} />
                  <button type="submit" onClick={() => onSearchClick()}>
                    {langJson?.search}
                  </button>
                </>
              ) : null}
            </div>
            <div className="topRight">
              <ul>
                <li className="topLang">
                  <select value={selectedLanguage} onChange={e => onSelectLanguage(e.target.value)}>
                    <option value="en">English</option>
                    <option value="es">Spanish</option>
                    <option value="fr">French</option>
                    <option value="it">Italian</option>
                    <option value="de">German</option>
                    <option value="ru">Russian</option>
                    <option value="tr">Turkish</option>
                  </select>
                </li>
                {user ? (
                  <li>
                    <Link to={"/wishlist"}>
                      <img src={wishlist} alt="" />
                    </Link>
                  </li>
                ) : null}
                {/* {user ? ( */}
                <li className="topCart">
                  <Link to="/cart">
                    <img src={cart} alt="" />
                    {cartCount ? <span>{cartCount}</span> : null}
                  </Link>
                </li>
                {/* ) : null} */}
                {user ? (
                  user?.role === "customer" ? (
                    <li>
                      <Link to={"/my-account"}>
                        <img src={userLogo} alt="" />
                      </Link>
                    </li>
                  ) : null
                ) : (
                  <li className="loginLink">
                    <img src={userLogo} alt="" />
                  </li>
                )}
                <li className="mobileBtn">
                  <img src={toggle} alt="image" />
                </li>
              </ul>
            </div>
          </div>

          <div className="navDiv">
            <ul>
              <li>
                <NavLink to="" className="categoryLink">
                  {langJson?.categories}
                </NavLink>
                <ul className="submenu">
                  {categoryData.map(category => (
                    <li className="submenuLink" key={category.id}>
                      <NavLink to="">{category.title}</NavLink>
                      <ul className="sub-submenu">
                        {category.sub_categories.map(subCategory => (
                          <li key={subCategory.id}>
                            <Link
                              to={{
                                pathname: `/shop/${subCategory.slug}`,
                              }}>
                              {subCategory.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <NavLink to="/shop/made-in-italy">{langJson?.madeinitaly}</NavLink>
              </li>
              {/* <li>
                <NavLink to="/store-followed">{langJson?.stores}</NavLink>
              </li> */}
              <li>
                <NavLink to="/store">{langJson?.fairtradeexhibitionhall}</NavLink>
              </li>
              <li>
                <NavLink to="/about-us">{langJson?.aboutus}</NavLink>
              </li>
              <li>
                <NavLink to="/events">{langJson?.events}</NavLink>
              </li>
              <li>
                <NavLink to="/videos">{langJson?.videos}</NavLink>
              </li>
              <li>
                <NavLink to="">{langJson?.contactus}</NavLink>
              </li>
              {!user ? (
                <li>
                  <NavLink to="/shop-creation">{langJson?.vendor}</NavLink>
                </li>
              ) : null}
            </ul>
          </div>
        </div>
      </div>
      <div className="loginPopup">
        <div className="loginInner">
          {!forgotPassSec ? (
            <>
              <img src={cross} alt="" className="crossBtn" />
              <h2>{langJson?.login}</h2>

              <form onSubmit={handleSubmit}>
                <ul>
                  <li>
                    {/* <input type="text" name="email" placeholder={langJson?.emailormobileno} value={formData.email} onChange={handleChange} /> */}
                    <input type="text" name="email" placeholder={langJson?.email} value={formData.email} onChange={handleChange} />
                  </li>
                  <li>
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      placeholder={langJson?.password}
                    />
                    <div className="visibleClick" onClick={togglePasswordVisibility}>
                      <img src={passwordIcon} alt="Toggle Password Visibility" />
                    </div>
                    <NavLink to="javascript:void(0);" onClick={() => setForgotPassSec(true)}>
                      {langJson?.didyouforgetyourpassword}
                    </NavLink>
                  </li>

                  <li>
                    <button type="submit" disabled={loading}>
                      {/* {loading ? langJson?.pleasewait : langJson?.signin} */}
                      {langJson?.signin}
                    </button>
                    <span className="lgnStatus">{loginStatus}</span>
                    <NavLink to="/register" className="createBtn">
                      {langJson?.donthaveanaccount} {langJson?.createnow}
                    </NavLink>
                  </li>
                </ul>
              </form>
            </>
          ) : (
            <>
              <img src={cross} alt="" className="crossBtn" onClick={() => setForgotPassSec(false)} />
              <h2>{langJson?.forgotpassword}</h2>

              <form onSubmit={handleForgotPassSubmit}>
                <ul>
                  <li>
                    {/* <input type="text" name="email" placeholder={langJson?.emailormobileno} value={formData.email} onChange={handleChange} /> */}
                    <input type="text" name="email" placeholder={langJson?.email} value={forgotEmail} onChange={e => setForgotEmail(e.target.value)} />
                  </li>
                  <li></li>
                  <li>
                    <button type="submit" disabled={loading}>
                      {/* {loading ? langJson?.pleasewait : langJson?.signin} */}
                      {langJson?.continue}
                    </button>
                    <span className="lgnStatus">{loginStatus}</span>
                  </li>
                </ul>
              </form>
            </>
          )}

          {userData && (
            <div className="home">
              <h2>
                {langJson?.welcome}, {userData.name}!
              </h2>
              <p>
                {langJson?.email}: {userData.email}
              </p>
              <p>
                {langJson?.role}: {userData.role}
              </p>
              {/* Add more fields as needed */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
