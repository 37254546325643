import React, { Component, useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import { API_BASE_URL } from "../../utils/app_url";
import { useLanguage } from "../../context/LanguageContext";
import { useAuth } from "../../context/AuthContext";
import Sidebar from "../../component/Sidebar";
import { NotificationManager } from "react-notifications";
import JoditEditor from "jodit-react";

const AdminAboutUs = () => {
  const { user, setUser } = useAuth();
  const { selectedLanguage, langJson } = useLanguage();
  const editor = useRef(null);
  const [aboutUs, setAboutUs] = useState();
  const [support, setSupport] = useState();
  const [privacy, setPrivacy] = useState();
  const [termOfUse, setTermOfUse] = useState();
  const [addBtnDis, setAddBtnDis] = useState(false);
  const [content, setContent] = useState("");
  const [editorInstance, setEditorInstance] = useState(null);
  const config = {
    buttons: ["bold", "italic", "underline", "strikethrough", "|", "ul", "ol", "|", "center", "left", "right", "justify", "|", "link", "image"],
    uploader: { insertImageAsBase64URI: true },
    removeButtons: ["brush", "file"],
    showXPathInStatusbar: false,
    showCharsCounter: true,
    showWordsCounter: true,
    toolbarAdaptive: true,
  };

  const fetchData = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}stores/fetch-settings`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setAboutUs(responseData?.data?.about_us?.description);
        setSupport(responseData?.data?.support?.description);
        setPrivacy(responseData?.data?.privacy_policy?.description);
        setTermOfUse(responseData?.data?.terms_use?.description);
      } else {
        NotificationManager.warning(responseData.msg);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateData = async () => {
    try {
      setAddBtnDis(true);
      const formData = new FormData();
      formData.append("about_us", aboutUs);
      formData.append("support", support);
      formData.append("privacy_policy", privacy);
      formData.append("terms_use", termOfUse);
      const response = await fetch(`${API_BASE_URL}stores/update-settings`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        NotificationManager.success(responseData.msg);
        setAddBtnDis(false);
      } else {
        NotificationManager.warning(responseData.msg);
        setAddBtnDis(false);
      }
    } catch (error) {
      NotificationManager.error('Something went wrong in server. Please try again.');
      console.error(error);
      setAddBtnDis(false);
    }
  };

  const handleContentChange = (newContent) => {
    setContent(newContent);
  };

  const handleEditorReady = (editor) => {
    setEditorInstance(editor);
  };

  useEffect(() => {
    fetchData();
    console.log(content);
  }, [content]);

  useEffect(() => {
    const elements = document.querySelectorAll(".jodit-react-container .jodit-ui-group_line_true ");
    elements.forEach((element) => {
      const nextElement = element.nextElementSibling;
      if (nextElement) {
        nextElement.style.display = "none";
      }
    });
  }, []);

  return (
    <div>
      <div className="dashHeader">
        <Header />
      </div>
      <div className="adminDash">
        <Sidebar />
        <div className="rightSide">
          <h2>{langJson?.cms}</h2>
          <div className="MainSection">
            <label className="cmsLevel">{langJson?.aboutus}</label>
            <JoditEditor ref={editor} value={aboutUs} tabIndex={1} onChange={(e) => setAboutUs(e)} />
          </div>

          <div className="MainSection">
            <label className="cmsLevel">{langJson?.support}</label>
            <JoditEditor ref={editor} value={support} tabIndex={1} onChange={(e) => setSupport(e)} />
          </div>

          <div className="MainSection">
            <label className="cmsLevel">{langJson?.privacypolicy}</label>
            <JoditEditor ref={editor} value={privacy} tabIndex={1} onChange={(e) => setPrivacy(e)} />
          </div>

          <div className="MainSection">
            <label className="cmsLevel">{langJson?.termofuse}</label>
            <JoditEditor ref={editor} value={termOfUse} tabIndex={1} onChange={(e) => setTermOfUse(e)} />
          </div>

          <ul className="addAddress">
            {/* <li className="fullWidth">
              <label className="cmsLevel">{langJson?.aboutus}</label>
              <textarea className="longTextArea" name="about_us" value={aboutUs} onChange={(e) => setAboutUs(e.target.value)}></textarea>
            </li>

            <li className="fullWidth">
              <label>{langJson?.support}</label>
              <textarea className="longTextArea" name="support" value={support} onChange={(e) => setSupport(e.target.value)}></textarea>
            </li>

            <li className="fullWidth">
              <label>{langJson?.privacypolicy}</label>
              <textarea className="longTextArea" name="privacy_policy" value={privacy} onChange={(e) => setPrivacy(e.target.value)}></textarea>
            </li> */}

            <li className="fullWidth saveBtn">
              <button type="button" disabled={addBtnDis} onClick={() => updateData()}>
                {addBtnDis ? langJson?.pleasewait : langJson?.update}
              </button>
            </li>
          </ul>
        </div>
      </div>

      <div className="dashFooter">
        <h3>
          {langJson?.copyright} © Fair for Fair {new Date().getFullYear()}
        </h3>
      </div>
      <div className="footInner">
        <Footer />
      </div>
    </div>
  );
};

export default AdminAboutUs;
