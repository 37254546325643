import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {useAuth} from "../context/AuthContext";
import {useLanguage} from "../context/LanguageContext";
import {API_BASE_URL} from "../utils/app_url";

const ShippingAddress = () => {
  const {user, setUser} = useAuth();
  const {selectedLanguage, langJson} = useLanguage();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [allDetails, seAllDetails] = useState();

  const onLogout = () => {
    localStorage.removeItem("FFFUserData");
    navigate("/");
    setUser(null);
  };

  const fetchShipping = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}users/shipping`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        seAllDetails(responseData.data);
      } else {
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteShipping = async id => {
    const confirmDelete = window.confirm(langJson?.areyousureyouwanttodelete);

    if (confirmDelete) {
      try {
        const response = await fetch(`${API_BASE_URL}users/shipping/${id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.authorisation.token}`,
          },
        });
        const responseData = await response.json();
        if (responseData.res === true) {
          let tempArray = allDetails.filter(item => parseInt(item.id) !== parseInt(id));
          seAllDetails(tempArray);
        } else {
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    fetchShipping();
  }, []);

  return (
    <div>
      <Header />
      <style>{`body { background: #F5F7FA; }`}</style>

      <div className="mainDiv">
        <div className="accountLink">
          <ul>
            <li>
              <Link to="/my-account">{langJson?.myaccount}</Link>
            </li>
            <li>
              <Link to="/my-orders">{langJson?.myorders}</Link>
            </li>
            <li>
              <Link to="/wishlist">{langJson?.wishlist}</Link>
            </li>
            <li>
              <Link to="/store-followed">{langJson?.storefollowed}</Link>
            </li>
            <li className="active">
              <Link to="/shipping-address">{langJson?.shippingaddress}</Link>
            </li>
            <li>
              <a href="javascript:void(0);" onClick={() => onLogout()}>
                {langJson?.logout}
              </a>
            </li>
          </ul>
        </div>
        <div className="acctHdn">
          <div className="hdnSec">
            <h2>{langJson?.shippingaddress}</h2>
            <Link to="/add-address" className="editProfileBtn addAddreBtn">
              {langJson?.addnewaddress}
            </Link>
          </div>

          {allDetails?.length > 0 ? (
            <>
              {allDetails?.map((item, index) => (
                <div className="myaccountDiv shipDiv" key={index}>
                  <ul>
                    <li>
                      <label>{langJson?.fullname}</label>
                      {item?.name}
                    </li>
                    <li>
                      <label>{langJson?.address}</label>
                      {item?.address}
                    </li>
                    <li>
                      <label>{langJson?.zipcode}</label>
                      {item?.zip_code}
                    </li>
                    <li>
                      <label>{langJson?.city}</label>
                      {item?.city}
                    </li>
                    <li>
                      <label>{langJson?.country}</label>
                      {item?.country}
                    </li>
                    <li>
                      <button className="removeBtn" onClick={() => deleteShipping(item?.id)}>
                        {langJson?.remove}
                      </button>
                      <Link to={`/edit-address/${item?.id}`} className="orderBtn">
                        <button className="editBtn">{langJson?.edit}</button>
                      </Link>
                    </li>
                  </ul>

                  <h3 className="dflt">
                    <input type="radio" />
                    {langJson?.useasdefaultaddress}
                  </h3>
                </div>
              ))}
            </>
          ) : !loading ? (
            <ul className="addressStore">
              <li>
                <h3>{langJson?.norecordsfound}</h3>
              </li>
            </ul>
          ) : null}
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default ShippingAddress;
