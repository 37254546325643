import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {useAuth} from "../context/AuthContext";
import {useLanguage} from "../context/LanguageContext";
import {API_BASE_URL} from "../utils/app_url";
import {NotificationManager} from "react-notifications";

const MyAccount = () => {
  const {user, setUser, setCartCount} = useAuth();
  const {selectedLanguage, langJson} = useLanguage();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    company: "",
    firstName: "",
    phone: "",
    address: "",
    country: "",
    city: "",
    state: "",
    zipCode: "",
    taxID: "",
  });

  const handleDeleteAccount = () => {
    const confirmed = window.confirm("Are you sure you want to delete your account?");
    if (confirmed) {
      // Handle deletion logic here
      // For example, call a function to delete the account
      deleteAccount();
    }
  };

  const deleteAccount = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}users/delete-account`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        NotificationManager.success(responseData?.msg);
        localStorage.removeItem("FFFUserData");
        navigate("/");
        setCartCount(0);
        setUser(null);
      } else {
        // Authentication failed, display an error message
        NotificationManager.error(responseData?.msg);
      }
    } catch (error) {}
  };

  const onLogout = () => {
    localStorage.removeItem("FFFUserData");
    navigate("/");
    setCartCount(0);
    setUser(null);
  };

  const fetchUserDetails = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}users/details`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setFormData({
          email: responseData.data.email,
          company: responseData.data.company_name,
          firstName: responseData.data.name,
          phone: responseData.data.phone,
          address: responseData.data.address,
          country: responseData.data.country,
          state: responseData.data.state,
          city: responseData.data.city,
          zipCode: responseData.data.zip_code,
          taxID: responseData.data.tax_id,
        });
        // setBannerList(responseData.data);
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  return (
    <div>
      <Header />
      <style>{`body { background: #F5F7FA; }`}</style>

      <div className="mainDiv">
        <div className="accountLink">
          <ul>
            <li className="active">
              <Link to="/my-account">{langJson?.myaccount}</Link>
            </li>
            <li>
              <Link to="/my-orders">{langJson?.myorders}</Link>
            </li>
            <li>
              <Link to="/wishlist">{langJson?.wishlist}</Link>
            </li>
            <li>
              <Link to="/store-followed">{langJson?.storefollowed}</Link>
            </li>
            <li>
              <Link to="/shipping-address">{langJson?.shippingaddress}</Link>
            </li>
            <li>
              <a href="javascript:void(0);" onClick={() => onLogout()}>
                {langJson?.logout}
              </a>
            </li>
          </ul>
        </div>
        <div className="acctHdn">
          <div className="hdnSec">
            <h2>{langJson?.myaccount}</h2>
            <Link to="/edit-profile" className="editProfileBtn">
              {langJson?.editprofile}
            </Link>
            <Link to="/change-password" className="editProfileBtn" style={{marginRight: "10px"}}>
              {langJson?.changepassword}
            </Link>
          </div>

          <div className="myaccountDiv">
            <ul>
              <li>
                <label>{langJson?.emailaddress}</label>
                {formData.email}
              </li>
              <li>
                <label>{langJson?.company}</label>
                {formData?.company}
              </li>
              <li>
                <label>{langJson?.fullname}</label>
                {formData?.firstName}
              </li>
              <li>
                <label>{langJson?.phonefaxmobilenumber}</label>
                {formData?.phone}
              </li>
              <li>
                <label>{langJson?.address}</label>
                {formData?.address}
              </li>
              <li>
                <label>{langJson?.country}</label>
                {formData?.country}
              </li>
              <li>
                <label>{langJson?.state}</label>
                {formData?.state}
              </li>
              <li>
                <label>{langJson?.city}</label>
                {formData?.city}
              </li>
              <li>
                <label>{langJson?.zipcode}</label>
                {formData?.zipCode}
              </li>
              <li>
                <label>{langJson?.taxid}</label>
                {formData?.taxID}
              </li>
            </ul>
          </div>
          <div className="hdnSec">
            <button className="editProfileBtn deleteAccountBtn" onClick={handleDeleteAccount}>
              {langJson?.delete} {langJson?.myaccount}
            </button>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default MyAccount;
