import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";
import Sidebar from "../component/Sidebar";

const ChoosePayment = () => {
  const navigate = useNavigate();
  const {user} = useAuth();
  const {langJson} = useLanguage();

  const [signupStatus, setSignupStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [allDetails, setallDetails] = useState();
  const [selectedPaymentTypes, setSelectedPaymentTypes] = useState([]);

  const handleChange = event => {
    const {value, checked} = event.target;
    if (checked) {
      setSelectedPaymentTypes(prevSelected => [...prevSelected, parseInt(value)]);
    } else {
      setSelectedPaymentTypes(prevSelected => prevSelected.filter(paymentType => paymentType !== parseInt(value)));
    }
  };

  const fetchPayments = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}frontend/payments`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setallDetails(responseData.data);
      } else {
      }
    } catch (error) {}
  };

  const fetchSavedPayments = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}stores/payments`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        let tempArray = [];
        if (responseData.data.length > 0) {
          responseData.data.forEach(element => {
            tempArray.push(element.payment_id);
          });
          setSelectedPaymentTypes(tempArray);
        }
      } else {
      }
    } catch (error) {}
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}stores/payments`, {
        method: "POST",
        body: JSON.stringify({
          payments: selectedPaymentTypes,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setSignupStatus(responseData.msg);
        setLoading(false);
      } else {
        // Authentication failed, display an error message
        setSignupStatus(responseData.msg);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error during Sign UP:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPayments();
    fetchSavedPayments();
  }, []);

  return (
    <div>
      <div className="dashHeader">
        <Header />
      </div>
      <div className="adminDash">
        <Sidebar />

        <div className="rightSide">
          <h2>{langJson?.choosepayment}</h2>
          <ul className="addAddress choosePayment">
            {allDetails?.map((item, index) => (
              <li key={index}>
                <input
                  type="checkbox"
                  name="paymentType"
                  value={parseInt(item?.id)}
                  checked={selectedPaymentTypes.includes(parseInt(item?.id))}
                  onChange={handleChange}
                />
                <label>{item.title}</label>
              </li>
            ))}

            <li className="fullWidth saveBtn">
              <button type="submit" disabled={loading} onClick={() => handleSubmit()}>
                {loading ? langJson?.pleasewait : langJson?.savechanges}
              </button>
            </li>
            <span className="lgnStatus">{signupStatus}</span>
          </ul>
        </div>
      </div>
      <div className="dashFooter">
        <h3>
          {langJson?.copyright} © Fair for Fair {new Date().getFullYear()}
        </h3>
      </div>
      <div className="footInner">
        <Footer />
      </div>
    </div>
  );
};

export default ChoosePayment;
