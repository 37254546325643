import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";
import {API_BASE_URL, IMAGE_URL} from "../utils/app_url";

const productImg = require("./../assets/img/product1-min.png");
const productImg2 = require("./../assets/img/product2-min.png");
const wishlistImg = require("./../assets/img/wishlist-icon2.svg").default;
const cartImg = require("./../assets/img/cart-icon.svg").default;

const Products = () => {
  const {user, setUser} = useAuth();
  const {selectedLanguage, langJson} = useLanguage();
  const navigate = useNavigate();

  const [allDetails, setAllDetails] = useState();
  const [loading, setLoading] = useState(false);

  const onLogout = () => {
    localStorage.removeItem("FFFUserData");
    navigate("/");
    setUser(null);
  };

  const fetchWishlists = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}frontend/wishlists`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setAllDetails(responseData.data);
      } else {
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleRemoveWishlist = async id => {
    try {
      const response = await fetch(`${API_BASE_URL}frontend/wishlists/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        let tempArray = [...allDetails];
        let selectedIndex = tempArray.findIndex(item => Number(item?.id) === Number(id));
        tempArray.splice(selectedIndex, 1);
        setAllDetails(tempArray);
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    $(".dropDiv").each(function () {
      $("h3", this).click(() => {
        $(".subSidebar").hide();
        $(this).find(".subSidebar").toggle();
      });
    });

    $(".filterBtn").click(() => {
      $(".sideBar").show();
    });

    $(".crossBtn").click(() => {
      $(".sideBar").hide();
    });

    fetchWishlists();
  }, []);

  return (
    <div>
      <Header />
      <style>{`body { background: #F5F7FA; }`}</style>

      <div className="mainDiv">
        <div className="accountLink">
          <ul>
            <li className="active">
              <Link to="/my-account">{langJson?.myaccount}</Link>
            </li>
            <li>
              <Link to="/my-orders">{langJson?.myorders}</Link>
            </li>
            <li>
              <Link to="/wishlist">{langJson?.wishlist}</Link>
            </li>
            <li>
              <Link to="/store-followed">{langJson?.storefollowed}</Link>
            </li>
            <li>
              <Link to="/shipping-address">{langJson?.shippingaddress}</Link>
            </li>
            <li>
              <a href="javascript:void(0);" onClick={() => onLogout()}>
                {langJson?.logout}
              </a>
            </li>
          </ul>
        </div>
        <div className="acctHdn">
          <div className="hdnSec">
            <h2>{langJson?.mywishlist}</h2>
          </div>

          <div className="wishlistDiv">
            <div className="productList">
              <ul>
                {allDetails?.length > 0
                  ? allDetails?.map((item, index) => (
                      <li key={index}>
                        <div className="imgHolder">
                          <Link to={`/product/${item?.product?.slug}`}>
                            <img src={IMAGE_URL + item?.product?.image} alt="" className="prdtImg" />
                          </Link>
                          {/* <div className="discountItem">-18%</div> */}
                          <div className="wishlistItem" onClick={() => handleRemoveWishlist(item?.id)}>
                            <img src={wishlistImg} alt="" />
                          </div>
                        </div>
                        <Link to={`/product/${item?.product?.slug}`}>
                          <div className="prdtCaption">
                            <p>{item?.product?.color && <span style={{background: item?.product?.color}}></span>}</p>
                            <h2>{item?.product?.title}</h2>
                            <h3>
                              {item?.product?.currency}
                              {item?.product?.price} <img src={cartImg} alt="" />
                            </h3>
                          </div>
                        </Link>
                      </li>
                    ))
                  : !loading && langJson?.noitemsfound}
              </ul>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Products;
