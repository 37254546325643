import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {useLanguage} from "../context/LanguageContext";

const checkIcon = require("./../assets/img/check-icon2.svg").default;

const PaymentSuccess = props => {
  const {selectedLanguage, langJson} = useLanguage();

  return (
    <div>
      <Header />
      <style>{`body { background: #F5F7FA; }`}</style>

      <div className="mainDiv">
        <div className="orderDiv">
          <img src={checkIcon} alt="" />
          <h2>{langJson?.orderplaced}</h2>
          <p>
            {langJson?.yourorderwasplacedsuccessfullyformoredetailscheck}
            <Link to=""> “{langJson?.myorders}”</Link>
          </p>
          <div className="orderBtns">
            <Link to="/my-orders" className="myOrderBtn">
              {langJson?.myorder}
            </Link>
            <Link to="/shop" className="contBtn">
              {langJson?.continueshopping}
            </Link>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default PaymentSuccess;
