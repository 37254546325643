import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL, IMAGE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";
import Sidebar from "../component/Sidebar";
import DataTable from "react-data-table-component";

const icon1 = require("./../assets/img/dashboard-icon.svg").default;
const icon2 = require("./../assets/img/info-icon.svg").default;
const icon3 = require("./../assets/img/app-icon.svg").default;
const icon4 = require("./../assets/img/payment-icon.svg").default;
const icon5 = require("./../assets/img/store-icon.svg").default;
const icon6 = require("./../assets/img/list-icon.svg").default;
const icon7 = require("./../assets/img/order-icon.svg").default;
const icon8 = require("./../assets/img/plan-icon.svg").default;
const icon9 = require("./../assets/img/logout-icon.svg").default;
const deleteIcon = require("./../assets/img/delete-icon.svg").default;
const uploadBtn = require("./../assets/img/search-btn.svg").default;
const bulletIcon = require("./../assets/img/bullet.svg").default;

const VendorProductListing = () => {
  const {user} = useAuth();
  const {selectedLanguage, langJson} = useLanguage();
  const navigate = useNavigate();
  const actionDivRef = useRef(null);

  const [loading, setLoading] = useState();
  const [allDetails, setAllDetails] = useState();
  const [selectedId, setSelectedId] = useState();
  const [searchQuery, setSearchQuery] = useState("");

  const columns = [
    {
      name: langJson?.image,
      selector: row => (row.images && row.images.length > 0 ? row.images[0].image : ""), // Extract the URL of the first image
      cell: row => (
        <div className="dataTableProImg">
          <img src={row.images && row.images.length > 0 ? IMAGE_URL + row.images[0].image : ""} alt="Product" />
        </div>
      ),
      sortable: true,
      maxWidth: "13%",
    },
    {
      name: langJson?.articleno,
      selector: row => row.article_no,
      sortable: true,
      // minWidth: "117px",
      maxWidth: "13%",
    },
    {
      name: langJson?.orderno,
      selector: row => row.order_no,
      sortable: true,
      // minWidth: "117px",
      maxWidth: "13%",
    },
    {
      name: langJson?.brand,
      selector: row => row.brand,
      sortable: true,
      // minWidth: "117px",
      maxWidth: "13%",
    },
    {
      name: langJson?.category,
      selector: row => row.cat_info?.title,
      sortable: true,
      // minWidth: "117px",
      maxWidth: "13%",
    },
    {
      name: langJson?.unitprice,
      selector: row => row.currency + row.unit_price,
      sortable: true,
      // minWidth: "117px",
      maxWidth: "13%",
    },
    {
      name: langJson?.color,
      selector: row => row.color,
      sortable: true,
      // minWidth: "117px",
      maxWidth: "13%",
    },
    {
      name: langJson?.size,
      selector: row => row.size,
      sortable: true,
      // minWidth: "117px",
      maxWidth: "13%",
    },
    {
      name: langJson?.price,
      selector: row => row.currency + row.sale_price,
      sortable: true,
      // minWidth: "117px",
      maxWidth: "13%",
    },
    {
      name: langJson?.season,
      selector: row => row?.season,
      sortable: true,
      // minWidth: "117px",
      maxWidth: "13%",
    },
    {
      name: langJson?.originalprice,
      selector: row => row.currency + row.price,
      sortable: true,
      // minWidth: "117px",
      maxWidth: "13%",
    },
    {
      name: langJson?.action,
      selector: row => row?.season,
      cell: row => (
        <div>
          <img src={bulletIcon} alt="" onClick={() => onBulletClick(row?.id)} />
          {parseInt(selectedId) === parseInt(row?.id) ? (
            <div className="actionDiv" ref={actionDivRef}>
              <button onClick={() => navigate(`/edit-product/${row?.id}`)}>{langJson?.edit}</button>
              <button onClick={() => handleDelete(row?.id)}>{langJson?.delete}</button>
            </div>
          ) : null}
        </div>
      ),
      sortable: true,
      // minWidth: "117px",
      maxWidth: "13%",
    },
  ];

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}products`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setAllDetails(responseData.data);
      } else {
      }
      setLoading(false);
    } catch (error) {
      console.error("Error during Sign UP:", error);
      setLoading(false);
    }
  };

  const onBulletClick = productId => {
    if (parseInt(selectedId) !== parseInt(productId)) {
      setSelectedId(parseInt(productId));
    } else {
      setSelectedId(null);
    }
  };

  const handleDelete = async productId => {
    // Perform the deletion logic here
    if (productId) {
      const confirmDelete = window.confirm("Are you sure you want to delete this product?");

      if (confirmDelete) {
        // Delete the item (replace this with your actual deletion logic)
        try {
          const response = await fetch(`${API_BASE_URL}products/${productId}`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.authorisation.token}`,
            },
          });
          const responseData = await response.json();
          if (responseData.res === true) {
            let tempArray = [...allDetails?.data];
            let selIndex = tempArray.findIndex(item => parseInt(item?.id) === parseInt(productId));
            tempArray.splice(selIndex, 1);
            setAllDetails(tempArray);
            setSelectedId(null);
          } else {
          }
        } catch (error) {
          console.error("Error during Sign UP:", error);
          setLoading(false);
        }
      }
    }
  };

  const filteredDetails =
    searchQuery.trim() === ""
      ? allDetails?.data
      : allDetails?.data?.filter(item => {
          const searchLower = searchQuery.toLowerCase();
          return (
            item?.article_no.toLowerCase().includes(searchLower) ||
            item?.order_no.toLowerCase().includes(searchLower) ||
            item?.brand.toLowerCase().includes(searchLower) ||
            item?.season.toLowerCase().includes(searchLower) ||
            item?.color.toLowerCase().includes(searchLower) ||
            item?.size.toLowerCase().includes(searchLower) ||
            item?.cat_info?.title.toLowerCase().includes(searchLower) ||
            (item?.unit_price).toString()?.toLowerCase().includes(searchLower) ||
            (item?.sale_price).toString()?.toLowerCase().includes(searchLower) ||
            (item?.price).toString()?.toLowerCase().includes(searchLower)
          );
        });

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    const handleClickOutside = event => {
      if (!actionDivRef?.current?.contains(event.target)) {
        // Clicked outside the td, add your logic here
        // console.log("Clicked outside the td");
        // setSelectedId(null);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // useEffect(() => {
  //   // Add event listener to handle clicks outside the actionDiv
  //   const handleClickOutside = event => {
  //     console.log(actionDivRef.current);
  //     if (setSelectedId && !actionDivRef.current) {
  //       // Clicked outside the actionDiv, so reset selectedId
  //       setSelectedId(null);
  //       console.log("first");
  //     }
  //   };

  //   // Attach the event listener
  //   document.addEventListener("click", handleClickOutside);

  //   // Clean up the event listener on component unmount
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, [actionDivRef]);

  return (
    <div>
      <div className="dashHeader">
        <Header />
      </div>
      <div className="adminDash">
        <Sidebar />

        <div className="rightSide rightTop">
          <h2>{langJson?.products}</h2>
          <div className="prdtTopRight">
            <input type="text" placeholder={langJson?.searchforarticlesnobrandseason} value={searchQuery} onChange={e => setSearchQuery(e.target.value)} />
            {/* <div className="inputFile">
              <input type="file" />
              <img src={uploadBtn} alt="" className="uplodBtn" />
            </div> */}
            <Link to="/add-product">{langJson?.addnewproduct}</Link>
          </div>
          <div className="prdtTable">
            <div className="data-table-section bookDataSec">
              {!loading ? (
                <DataTable
                  key={"product-list-vendor"}
                  className="Tbl productListDataTable"
                  columns={columns}
                  data={filteredDetails}
                  highlightOnHover
                  pagination
                  responsive
                />
              ) : null}
            </div>
          </div>

          {/* <div className="prdtTable">
            <table>
              <tr>
                <th>{langJson?.articleno}</th>
                <th>{langJson?.orderno}</th>
                <th>{langJson?.brand}</th>
                <th>{langJson?.category}</th>
                <th>{langJson?.unitprice}</th>
                <th>{langJson?.color}</th>
                <th>{langJson?.size}</th>
                <th>{langJson?.price}</th>
                <th>{langJson?.season}</th>
                <th>{langJson?.originalprice}</th>
                <th>{langJson?.action}</th>
              </tr>
              {filteredDetails?.map((item, index) => (
                <tr key={index}>
                  <td>{item?.article_no}</td>
                  <td>{item?.order_no}</td>
                  <td>{item?.brand}</td>
                  <td>{item?.cat_info?.title}</td>
                  <td>
                    {item?.currency} {item?.unit_price}
                  </td>
                  <td>{item?.color}</td>
                  <td>{item?.size}</td>
                  <td>
                    {item?.currency} {item?.sale_price}
                  </td>
                  <td>{item?.season}</td>
                  <td>
                    {item?.currency} {item?.price}
                  </td>
                  <td className="actionHolder">
                    <div>
                      <img src={bulletIcon} alt="" onClick={() => onBulletClick(item?.id)} />
                      {parseInt(selectedId) === parseInt(item?.id) ? (
                        <div className="actionDiv" ref={actionDivRef}>
                          <button onClick={() => navigate(`/edit-product/${item?.id}`)}>{langJson?.edit}</button>
                          <button onClick={() => handleDelete(item?.id)}>{langJson?.delete}</button>
                        </div>
                      ) : null}
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </div> */}
        </div>
      </div>
      <div className="dashFooter">
        <h3>
          {langJson?.copyright} © Fair for Fair {new Date().getFullYear()}
        </h3>
      </div>
      <div className="footInner">
        <Footer />
      </div>
    </div>
  );
};

export default VendorProductListing;
