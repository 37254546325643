import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL, IMAGE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";

const fairbanner = require("./../assets/img/fair-banner.png");
const logo = require("./../assets/img/puma-logo.png");
const wishlistImg = require("./../assets/img/wishlist-icon2.svg").default;
const cartImg = require("./../assets/img/cart-icon.svg").default;

const FairTradeProducts = () => {
  const {user} = useAuth();
  const {selectedLanguage, langJson} = useLanguage();
  const {storeId, proCatId} = useParams();

  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState("default"); // default category
  const [sortKey, setSortKey] = useState("default"); // default sort key
  const [allDetails, setAllDetails] = useState();
  const [wishBtn, setWishBtn] = useState(false);
  const [wishMsg, setWishMsg] = useState(false);
  const [openModal, setOpenModal] = useState();

  const fetchData = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}frontend/store/products`, {
        method: "POST",
        body: JSON.stringify({
          store_id: storeId,
          pro_cat_id: proCatId,
          lang: selectedLanguage,
          user_id: user?.id,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setProducts(data.data.products);
      setAllDetails(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleAddWishlist = async productId => {
    if (!user) {
      $(".loginLink").click(() => {
        $(".loginPopup").addClass("active");
      });
      setOpenModal(!openModal);
    } else {
      setWishMsg(null);
      setWishBtn(true);
      try {
        const response = await fetch(`${API_BASE_URL}frontend/wishlists`, {
          method: "POST",
          body: JSON.stringify({
            product_id: productId,
            lang: selectedLanguage,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.authorisation?.token}`,
          },
        });
        const responseData = await response.json();
        if (responseData.res === true) {
          setWishMsg(responseData.msg);
          setWishBtn(false);
        } else {
          setWishMsg(responseData.msg);
          setWishBtn(false);
        }
        setTimeout(() => {
          setWishMsg(null);
        }, 5000);
      } catch (error) {
        console.error("Error during Sign UP:", error);
        setWishBtn(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [user]);

  return (
    <div>
      <Header />
      <style>{`body { background: #F5F7FA; }`}</style>

      <div className="fairDiv">
        <div className="fairDtlsBanner">
          <img src={IMAGE_URL + allDetails?.store?.cover} alt="" />
        </div>
        <div className="mainDiv">
          <div className="fairTop">
            <div className="fairLft">
              <div className="logoLft">
                <img src={IMAGE_URL + allDetails?.store?.logo} alt="" />
              </div>
              <div className="logoRgt">
                <h2>{allDetails?.store?.name} </h2>
                <p>{allDetails?.store?.advertisement}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="featureDiv productListMain">
          <div className="mainDiv">
            <div className="productList">
              <ul>
                {products &&
                  products.map((product, index) => (
                    <li key={index}>
                      <div className="imgHolder">
                        <Link
                          to={{
                            pathname: `/product/${product.slug}`,
                          }}>
                          <img src={IMAGE_URL + product.image} alt={index} className="prdtImg" />
                        </Link>
                        {/* <div className="discountItem"></div> */}
                        <div className="wishlistItem">
                          <img src={wishlistImg} alt="" onClick={() => handleAddWishlist(product?.id)} />
                        </div>
                      </div>
                      <Link
                        to={{
                          pathname: `/product/${product.slug}`,
                        }}>
                        <div className="prdtCaption">
                          <p>{product.color && <span style={{background: product.color}}></span>}</p>
                          <h2>{product.title}</h2>
                          {parseInt(product?.is_public) === 1 ? (
                            <h3>
                              <span>
                                {product.currency}
                                {product.price}
                              </span>
                              {product.currency}
                              {product.sale_price} <img src={cartImg} alt="" />
                            </h3>
                          ) : (
                            <h3>
                              <img src={cartImg} alt="" />
                            </h3>
                          )}
                        </div>
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default FairTradeProducts;
