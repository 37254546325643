import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import $ from "jquery";
import {API_BASE_URL} from "../../utils/app_url";
import {useLanguage} from "../../context/LanguageContext";
import {useAuth} from "../../context/AuthContext";
import Sidebar from "../../component/Sidebar";

const VendorWebsiteInfo = () => {
  const navigate = useNavigate();
  const {user, setUser} = useAuth();
  const {langJson} = useLanguage();

  const [formData, setFormData] = useState({
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: "",
    snapchat: "",
    support: "",
    email: "",
    whatIsFFFText: "",
    howMuchCoseText: "",
  });
  const [signupStatus, setSignupStatus] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = event => {
    const {name, value} = event.target;

    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchUserDetails = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}stores/fetch-configs`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.authorisation.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setFormData({
          facebook: responseData.data.facebook,
          twitter: responseData.data.twitter,
          instagram: responseData.data.instagram,
          youtube: responseData.data.youtube,
          snapchat: responseData.data.snapchat,
          support: responseData.data.support,
          email: responseData.data.email,
          whatIsFFFText: responseData.data["register-about"],
          howMuchCoseText: responseData.data["register-cost"],
        });
      } else {
      }
    } catch (error) {}
  };

  const handleSubmit = async () => {
    if (formData.email && !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/.test(formData.email)) {
      setSignupStatus(langJson?.emailisnotvalid);
    } else {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_URL}stores/update-configs`, {
          method: "POST",
          body: JSON.stringify({
            facebook: formData.facebook,
            twitter: formData.twitter,
            instagram: formData.instagram,
            youtube: formData.youtube,
            snapchat: formData.snapchat,
            support: formData.support,
            email: formData.email,
            "register-about": formData.whatIsFFFText,
            "register-cost": formData.howMuchCoseText,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.authorisation.token}`,
          },
        });
        const responseData = await response.json();
        if (responseData.res === true) {
          setSignupStatus(responseData.msg);
          setLoading(false);
        } else {
          // Authentication failed, display an error message
          setSignupStatus(responseData.msg);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error during Sign UP:", error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  return (
    <div>
      <div className="dashHeader">
        <Header />
      </div>
      <div className="adminDash">
        <Sidebar />

        <div className="rightSide">
          <h2>{langJson?.websiteinformation}</h2>
          <ul className="addAddress">
            <li>
              <label>{langJson?.facebook}:</label>
              <input type="text" name="facebook" value={formData.facebook} onChange={handleChange} />
            </li>
            <li>
              <label>{langJson?.twitter}:</label>
              <input type="text" name="twitter" value={formData.twitter} onChange={handleChange} />
            </li>
            <li>
              <label>{langJson?.instagram}:</label>
              <input type="text" name="instagram" value={formData.instagram} onChange={handleChange} />
            </li>
            <li>
              <label>{langJson?.youtube}:</label>
              <input type="text" name="youtube" value={formData.youtube} onChange={handleChange} />
            </li>
            <li>
              <label>{langJson?.snapchat}:</label>
              <input type="text" name="snapchat" value={formData.snapchat} onChange={handleChange} />
            </li>
            <li>
              <label>{langJson?.support}:</label>
              <input type="text" name="support" value={formData.support} onChange={handleChange} />
            </li>
            <li>
              <label>{langJson?.email}: </label>
              <input type="text" name="email" value={formData.email} onChange={handleChange} />
            </li>
            <li>
              <label>{langJson?.whats} FFF: </label>
              <textarea name="whatIsFFFText" value={formData.whatIsFFFText} onChange={handleChange} />
            </li>
            <li>
              <label>{langJson?.howmuchdoesitcosttosell}: </label>
              <textarea name="howMuchCoseText" value={formData.howMuchCoseText} onChange={handleChange} />
            </li>
            <li className="fullWidth saveBtn">
              <button type="submit" disabled={loading} onClick={() => handleSubmit()}>
                {loading ? langJson?.pleasewait : langJson?.savechanges}
              </button>
            </li>
            <span className="lgnStatus">{signupStatus}</span>
          </ul>
        </div>
      </div>
      <div className="dashFooter">
        <h3>
          {langJson?.copyright} © Fair for Fair {new Date().getFullYear()}
        </h3>
      </div>
      <div className="footInner">
        <Footer />
      </div>
    </div>
  );
};

export default VendorWebsiteInfo;
