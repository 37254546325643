import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import $ from "jquery";
import {API_BASE_URL, IMAGE_URL} from "../../utils/app_url";
import {useLanguage} from "../../context/LanguageContext";
import {useAuth} from "../../context/AuthContext";
import {NotificationManager} from "react-notifications";

// const fairbanner = require("./../assets/img/fair-banner.png");
// const logo = require("./../assets/img/puma-logo.png");
// const fairImg = require("./../assets/img/fair-listing.png");
// const wishImg = require("./../assets/img/wishlist-icon2.svg").default;

const FairTradeEvents = () => {
  const {user} = useAuth();
  const {selectedLanguage, langJson} = useLanguage();
  const {storeId} = useParams();

  const [allDetails, setAllDetails] = useState();
  const [openModal, setOpenModal] = useState();
  const [eventList, setEventList] = useState();

  const fetchStoreDetails = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}frontend/store/${storeId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.authorisation?.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setAllDetails(responseData.data);
      } else {
        NotificationManager.warning(responseData?.msg);
      }
    } catch (error) {}
  };

  const fetchDetails = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}frontend/events`, {
        method: "POST",
        body: JSON.stringify({
          store_id: storeId,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.authorisation?.token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.res === true) {
        setEventList(responseData.data.data);
      } else {
        NotificationManager.warning(responseData?.msg);
      }
    } catch (error) {}
  };

  const handleAddStore = async () => {
    if (!user) {
      $(".loginLink").click(() => {
        $(".loginPopup").addClass("active");
      });
      setOpenModal(!openModal);
    } else {
      try {
        const response = await fetch(`${API_BASE_URL}frontend/liked-stores`, {
          method: "POST",
          body: JSON.stringify({
            store_id: storeId,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.authorisation.token}`,
          },
        });
        const responseData = await response.json();
        if (responseData.res === true) {
          // setResponseStatus(responseData?.msg);
          NotificationManager.success(responseData?.msg);
        } else {
          // setResponseStatus(responseData?.msg);
          NotificationManager.warning(responseData?.msg);
        }
      } catch (error) {}
    }
  };

  const handleRequestPrice = async () => {
    if (!user) {
      $(".loginLink").click(() => {
        $(".loginPopup").addClass("active");
      });
      setOpenModal(!openModal);
    } else {
      try {
        const response = await fetch(`${API_BASE_URL}frontend/request-price`, {
          method: "POST",
          body: JSON.stringify({
            store_id: storeId,
            lang: selectedLanguage,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.authorisation.token}`,
          },
        });
        const responseData = await response.json();
        if (responseData.res === true) {
          NotificationManager.success(responseData?.msg);
        } else {
          NotificationManager.warning(responseData?.msg);
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    fetchStoreDetails();
    fetchDetails();
  }, []);

  return (
    <div>
      <Header openModal={openModal} />
      <style>{`body { background: #F5F7FA; }`}</style>

      <div className="fairDiv">
        <div className="fairDtlsBanner">
          <img src={IMAGE_URL + allDetails?.store?.cover} alt="" />
        </div>
        <div className="mainDiv">
          <div className="fairTop">
            <div className="fairLft">
              <div className="logoLft">
                <img src={IMAGE_URL + allDetails?.store?.logo} alt="" />
              </div>
              <div className="logoRgt">
                <h2>{allDetails?.store?.name} </h2>
                <p>{allDetails?.store?.advertisement}</p>
                <a href="javascript:void(0);" onClick={() => handleAddStore()}>
                  {langJson?.followshop}
                </a>
              </div>
            </div>
            {/* <div>
              <Link to="/">
                Events
              </Link>
              <Link to="/">
                {langJson?.aboutus}
              </Link>
              <Link to="/">
                Videos
              </Link>
            </div> */}

            <div className="fairRgt">
              <Link to="/" className="cntBtn">
                {langJson?.contactus}
              </Link>
              <a href="javascript:void(0);" className="prceBtn" onClick={() => handleRequestPrice()}>
                {langJson?.requestforprice}
              </a>
            </div>
          </div>
        </div>

        {/* <div className="mainDiv">
          <ul className="fairDetails">
            {allDetails?.categories?.length > 0 ? (
              allDetails?.categories?.map((item, index) => (
                <li key={index}>
                  <Link to={`/store/${storeId}/products/${item?.id}`} className="viewBtn">
                    <img src={IMAGE_URL + item?.image} alt="" className="tradeImg" />
                    <div className="fairOverlay">
                      <div className="fairBtns">
                        <Link to={`/store/${storeId}/products/${item?.id}`} className="viewBtn">
                          {item?.title}
                        </Link>
                      </div>
                    </div>
                  </Link>
                </li>
              ))
            ) : (
              <span className="noOrderDiv">{langJson?.norecordsfound}</span>
            )}
          </ul>
        </div> */}

        <div className="mainDiv">
          <div className="featureDiv productListMain">
            <div className="mainDiv">
              <div className="hdnSec">
                <h2>{langJson?.events}</h2>
              </div>

              <ul className="eventList">
              {eventList?.length > 0 ? (
                eventList?.map((item, index) => (
                  <li key={index}>
                    <Link to={`/event/${item?.id}`}>
                      <img src={IMAGE_URL + item?.image} alt="" />
                      <h3>{item?.title}</h3>
                      <p>{item?.formatted_start_date_time}</p>
                    </Link>
                  </li>
                ))
              ):(
                <span className="noOrderDiv">{langJson?.norecordsfound}</span>
              )}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default FairTradeEvents;
