import React, {Component, useState, useRef, useEffect} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import Header from "./../component/Header";
import Footer from "./../component/Footer";
import $ from "jquery";
import {API_BASE_URL, IMAGE_URL} from "../utils/app_url";
import {useLanguage} from "../context/LanguageContext";
import {useAuth} from "../context/AuthContext";
import {NotificationManager} from "react-notifications";

const fairImg = require("./../assets/img/trade1.png");
const wishImg = require("./../assets/img/wishlist-icon2.svg").default;

const FairTrade = () => {
  const {user} = useAuth();
  const {selectedLanguage, langJson} = useLanguage();
  // const {CategoryName, SubcategoryName} = useParams();
  const {slugName} = useParams();
  const location = useLocation();

  const [allDetails, setAllDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [openModal, setOpenModal] = useState();

  const fetchStores = async () => {
    setLoading(true);
    try {
      let headers = {
        "Content-Type": "application/json",
      };
      if (user) {
        headers.Authorization = `Bearer ${user.authorisation.token}`;
      }
      const response = await fetch(`${API_BASE_URL}stores`, {
        method: "GET",
        // body: JSON.stringify({
        //   user_id: user?.id,
        // }),
        headers: headers,
        // headers: {
        //   "Content-Type": "application/json",
        //   Authorization: `Bearer ${user.authorisation.token}`,
        // },
      });
      const responseData = await response.json();

      if (responseData.res === true) {
        setAllDetails(responseData.data);
      } else {
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleAddStore = async id => {
    if (!user) {
      $(".loginLink").click(() => {
        $(".loginPopup").addClass("active");
      });
      setOpenModal(!openModal);
    } else {
      try {
        const response = await fetch(`${API_BASE_URL}frontend/liked-stores`, {
          method: "POST",
          body: JSON.stringify({
            store_id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.authorisation.token}`,
          },
        });
        const responseData = await response.json();
        if (responseData.res === true) {
          // setResponseStatus(responseData?.msg);
          NotificationManager.success(responseData?.msg);
        } else {
          // setResponseStatus(responseData?.msg);
          NotificationManager.warning(responseData?.msg);
        }
      } catch (error) {}
    }
  };

  const handleRequestPrice = async id => {
    if (!user) {
      $(".loginLink").click(() => {
        $(".loginPopup").addClass("active");
      });
      setOpenModal(!openModal);
    } else {
      try {
        const response = await fetch(`${API_BASE_URL}frontend/request-price`, {
          method: "POST",
          body: JSON.stringify({
            store_id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.authorisation.token}`,
          },
        });
        const responseData = await response.json();
        if (responseData.res === true) {
          // setResponseStatus(responseData?.msg);
          NotificationManager.success(responseData?.msg);
        } else {
          // setResponseStatus(responseData?.msg);
          NotificationManager.warning(responseData?.msg);
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    fetchStores();
  }, []);

  return (
    <div>
      <Header openModal={openModal} />
      <style>{`body { background: #F5F7FA; }`}</style>

      <div className="fairDiv">
        <div className="fairBanner">
          <h3>
            {langJson?.welcome} {langJson?.to}
          </h3>
          <h2>Fair for fair {langJson?.tradeexhibition}</h2>
        </div>
        <div className="mainDiv">
          <ul>
            {allDetails?.map((item, index) => (
              <li key={index}>
                <img src={IMAGE_URL + item?.logo} alt="" className="tradeImg" />
                <div className="fairOverlay">
                  <button onClick={() => handleAddStore(item?.id)}>
                    <img src={wishImg} alt="" />
                  </button>
                  <div className="fairBtns">
                    <Link className="viewBtn" to={`/store/${item?.id}`} state={{from: location.pathname}}>
                      {langJson?.viewdetails}
                    </Link>
                    <a href="javascript:void(0);" className="prcBtn" onClick={() => handleRequestPrice(item?.id)}>
                      {langJson?.requestforprice}
                    </a>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default FairTrade;
